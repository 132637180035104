import Helper_class from '../../../libs/helpers.js'

var instance = null

/**
 * Source for user agreement to legal disclaimer
 *
 * @author TheKeiron
 */
class LegalDataSource_class {
  constructor () {
    //singleton
    if (instance) {
      return instance
    }
    instance = this

    this.Helper = new Helper_class()
    this.hasAgreedInLocalSession = this.Helper.hasAgreedToTerms()
  }

  getHasUserAgreedToLegalDisclaimer () {
    return this.hasAgreedInLocalSession || this.Helper.hasAgreedToTerms()
  }

  setUserAgreedToLegalDisclaimer (agree, store) {
    if (store) {
      this.Helper.setAgreedToTerms(agree)
    }
    this.hasAgreedInLocalSession = agree
  }
}

export default LegalDataSource_class
