import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'

import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    borderRadius: 20
    // backgroundColor: 'transparent',
    // boxShadow: 'none',
  },
  cardHeader: {
    padding: '4px',
    margin: 0
  },
  avatar: {
    backgroundColor: theme.palette.primary.light
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },

  typography: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  outerGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  paintInfoBg: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  }
}))

export default function PaintButton (props) {
  const classes = useStyles()

  return (
    <Card
      {...props}
      className={clsx(classes.root, classes.outerGlow, classes.paintInfoBg)}
      elevation={10}
    >
      <CardActionArea onClick={props.onClick}>
        <CardHeader
          className={clsx(classes.cardHeader)}
          disableTypography
          avatar={
            <Avatar
              aria-label='recipe'
              className={clsx(classes.avatar, classes.small)}
              src={'images/paint_logo.png'}
            />
          }
          title={
            <Typography
              component='subtitle2'
              variant='subtitle2'
              noWrap
              align='right'
              className={clsx(classes.typography)}
              color='textPrimary'
              style={{
                paddingRight: '6px'
              }}
            >
              {`${props.paintBalance}`}
            </Typography>
          }
        />
      </CardActionArea>
    </Card>
  )
}
