import Helper_class from './../../libs/helpers.js'

class Edit_paste_class {
  constructor () {
    this.Helper = new Helper_class()
  }

  paste () {
    this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
      message: 'Use Ctrl+V keyboard shortcut to paste from Clipboard.',
      error: true
    })
  }
}

export default Edit_paste_class
