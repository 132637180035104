'use strict'

import React from 'react'
import _ from 'underscore'
import * as THREE from 'three'
import Helper_class from '../libs/helpers.js'
import LoadingDialog from './loading_dialog.js'
import config from '../config.js'

const fragmentShader = `
varying vec2 st;
uniform float iTime;
uniform float glitchScale;
uniform float glitchBoxSize;
uniform float strength;
uniform vec2 topLeftConstraintPercent;
uniform vec2 bottomRightConstraintPercent;
uniform float glitchSizePadding;
uniform sampler2D iChannel0;
uniform float glitchAmount; //0.0 - 1.0 glitch amount for picture
float time;

float rou(float val){
    return fract(val)>0.5?ceil(val):floor(val);
}
vec2 rou(vec2 val){
    vec2 result = vec2(rou(val.x),rou(val.y));
    return result;
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}

vec2 glitchCoord(vec2 p, vec2 gridSize) {
	vec2 coord = floor(p / gridSize) * gridSize;;
    coord += (gridSize / 2.);
    return coord;
}


struct GlitchSeed {
    vec2 seed;
    float prob;
};
    
float fBox2d(vec2 p, vec2 b) {
    vec2 d = abs(p) - b;
    return min(max(d.x, d.y), 0.0) + length(max(d, 0.0));
}

GlitchSeed glitchSeed(vec2 p, float speed) {
    float seedTime = floor(time * speed);
    vec2 seed = vec2(
        1. + mod(seedTime / 100., 100.),
        1. + mod(seedTime, 100.)
    ) / 100.;
    seed += p;
    vec2 st = p;
    
    float k = step(iTime,st.y);
    float alpha =texture2D(iChannel0,st).a;
    float prob = alpha*strength;
 
    return GlitchSeed(seed, prob);
}

float shouldApply(GlitchSeed seed) {
    return rou(mix(mix(rand(seed.seed), 1., seed.prob - .5),0.,(1. - seed.prob) * .5));
}

vec4 swapCoords(vec2 seed, vec2 groupSize, vec2 subGrid, vec2 blockSize) {
    vec2 rand2 = vec2(rand(seed), rand(seed+.1));
    vec2 range = subGrid - (blockSize - 1.);
    vec2 coord = floor(rand2 * range) / subGrid;
    vec2 bottomLeft = coord * groupSize;
    vec2 realBlockSize = (groupSize / subGrid) * blockSize;
    vec2 topRight = bottomLeft + realBlockSize;
    topRight -= groupSize / 2.;
    bottomLeft -= groupSize / 2.;
    return vec4(bottomLeft, topRight);
}

float isInBlock(vec2 pos, vec4 block) {
    vec2 a = sign(pos - block.xy);
    vec2 b = sign(block.zw - pos);
    return min(sign(a.x + a.y + b.x + b.y - 3.), 0.);
}

vec2 moveDiff(vec2 pos, vec4 swapA, vec4 swapB) {
    vec2 diff = swapB.xy - swapA.xy;
    return diff * isInBlock(pos, swapA);
}

void swapBlocks(inout vec2 xy, vec2 groupSize, vec2 subGrid, vec2 blockSize, vec2 seed, float apply) {
    
    vec2 groupOffset = glitchCoord(xy, groupSize);
    vec2 pos = xy - groupOffset;
    
    vec2 seedA = seed * groupOffset;
    vec2 seedB = seed * (groupOffset + .1);
    
    vec4 swapA = swapCoords(seedA, groupSize, subGrid, blockSize);
    vec4 swapB = swapCoords(seedB, groupSize, subGrid, blockSize);
    
    vec2 newPos = pos;
    newPos += moveDiff(pos, swapA, swapB) * apply;
    newPos += moveDiff(pos, swapB, swapA) * apply;
    pos = newPos;
    
    xy = pos + groupOffset;
}


// Static

void staticNoise(inout vec2 p, vec2 groupSize, float grainSize, float contrast) {
    GlitchSeed seedA = glitchSeed(glitchCoord(p, groupSize), 5.);
    seedA.prob *=0.9;
    if (shouldApply(seedA) == 1.) {
        GlitchSeed seedB = glitchSeed(glitchCoord(p, vec2(grainSize)), 5.);
        vec2 offset = vec2(rand(seedB.seed), rand(seedB.seed + .1));
        offset = rou(offset * 2. - 1.);
        offset *= contrast;
        p += offset;
    }
}


// Freeze time

void freezeTime(vec2 p, inout float time, vec2 groupSize, float speed) {
    GlitchSeed seed = glitchSeed(glitchCoord(p, groupSize), speed);
    //seed.prob *= .5;
    if (shouldApply(seed) == 1.) {
        float frozenTime = floor(time * speed) / speed;
        time = frozenTime;
    }
}


// --------------------------------------------------------
// Glitch compositions
// --------------------------------------------------------

void glitchSwap(inout vec2 p) {

    vec2 pp = p;
    
    float scale = glitchScale;
    float speed = 5.;
    
    vec2 groupSize;
    vec2 subGrid;
    vec2 blockSize;    
    GlitchSeed seed;
    float apply;
    
    groupSize = vec2(.6) * scale;
    subGrid = vec2(2);
    blockSize = vec2(1);

    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    apply = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, seed.seed, apply);
    
    groupSize = vec2(.8) * scale;
    subGrid = vec2(3);
    blockSize = vec2(1);
    
    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    apply = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, seed.seed, apply);

    groupSize = vec2(.2) * scale;
    subGrid = vec2(6);
    blockSize = vec2(1);
    
    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    float apply2 = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 1.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 2.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 3.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 4.), apply * apply2);
    swapBlocks(p, groupSize, subGrid, blockSize, (seed.seed + 5.), apply * apply2);
    
    groupSize = vec2(1.2, .2) * scale;
    subGrid = vec2(9,2);
    blockSize = vec2(3,1);
    
    seed = glitchSeed(glitchCoord(p, groupSize), speed);
    apply = shouldApply(seed);
    swapBlocks(p, groupSize, subGrid, blockSize, seed.seed, apply);
}

void glitchStatic(inout vec2 p) {

    // Static
    //staticNoise(p, vec2(.25, .25/2.) * glitchScale, .005, 5.);
    
    // 8-bit
    staticNoise(p, vec2(.5, .25/2.) * glitchScale, .2 * glitchScale, 2.);
}

void glitchTime(vec2 p, inout float time) {
   freezeTime(p, time, vec2(.5) * glitchScale, 2.);
}

void glitchColorCalc(vec2 p, inout vec3 color) {
    vec2 groupSize = vec2(.75,.125) * glitchScale;
    vec2 subGrid = vec2(0,6);
    float speed = 115.;
    glitchCoord(p, groupSize);
    
    vec2 co = mod(p, groupSize) / groupSize;
    co *= subGrid;
    float a = max(co.x, co.y);
    color.rgb *= vec3(
        min(floor(mod(a - 0., 3.)), 1.),
        min(floor(mod(a - 1., 3.)), 1.),
        min(floor(mod(a - 2., 3.)), 1.)
    );
        
    color *= min(rou(mod(a, 2.)), 1.) * 10.;
}

//2D (returns 0 - 1)
float random2d(vec2 n) { 
    return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
}

float randomRange (in vec2 seed, in float min, in float max) {
		return min + random2d(seed) * (max - min);
}

// return 1 if v inside 1d range
float insideRange(float v, float bottom, float top) {
   return step(bottom, v) - step(top, v);
}

void main() 
{
    time = iTime;
    // time = iTime*10.;
    // time /= 3.;
    // time = mod(time, 1.);
        
    vec2 p = st;
    vec2 uv = st;

    // c is the current colour at our coord
    vec4 c  = texture2D(iChannel0, uv);
    vec4 originalColour  = c;
    float leftX = topLeftConstraintPercent.x - glitchSizePadding;
    float bottomY = (1.0 - topLeftConstraintPercent.y) + glitchSizePadding;
    float rightX = bottomRightConstraintPercent.x + glitchSizePadding;
    float topY = (1.0 - bottomRightConstraintPercent.y) - glitchSizePadding;

    // outColour is the output colour (initialised as the texture colour), which is modified throughout this shader code
    vec4 outColour = c;

    if(p.x >= leftX && p.x <= rightX && p.y >= topY && p.y <= bottomY) {
        if(time > 0. && time < 1.) {
            glitchSwap(p);
            glitchTime(p, time);
            glitchStatic(p);

            float na = 0.;
            
            float nn = 1. - (smoothstep(uv.y,uv.y + glitchBoxSize,iTime) - smoothstep(uv.y-glitchBoxSize,uv.y,iTime));
            // uncomment for proper look
            c = mix(c, vec4(1.,0.,0.,0.), 1. - step(iTime, uv.y));

            // uncomment to debug
            //c = mix(c, vec4(1.,0.,0.,1.), 1. - step(iTime,uv.y));

            if(abs(p.x - uv.x) > 0.05){
                na = mix(p.x, 0., nn);
                //c = mix(c,vec4(p.x),1.-nn);
                vec3 glitchRgb = c.rgb;

                if(c.a == 0.) {
                    glitchRgb = vec3(0.5, 0.5, 0.5);
                }
        
                if(na == 0.) {
                    glitchRgb = originalColour.rgb;
                } else {
                    glitchColorCalc(p, glitchRgb);
                }
                outColour.rgb = glitchRgb;
                outColour.a = c.a + na;
            } else {    
                
                //randomly offset slices horizontally
                float maxOffset = glitchAmount/2.0;
                //float loopMax = 10.0 * AMT;
                
                // commented to reduce work in shader for performance
                // #pragma unroll_loop_start
                // for (float i = 0.0; i < 20.0; i += 1.0) {
                //     float sliceY = random2d(vec2(time , 2345.0 + float(i)));
                //     float sliceH = random2d(vec2(time , 9035.0 + float(i))) * 0.25;
                //     float hOffset = randomRange(vec2(time , 9625.0 + float(i)), -maxOffset, maxOffset);
                //     vec2 uvOff = uv;
                //     uvOff.x += hOffset;
                    
                //     outColour = texture2D(iChannel0, uvOff);
                // }
                // #pragma unroll_loop_end

                float hOffset = randomRange(vec2(time , 9625.0), -maxOffset, maxOffset);
                vec2 uvOff = uv;
                uvOff.x += hOffset;
                                
                outColour = texture2D(iChannel0, uvOff);

                //do slight offset on one entire channel
                float maxColOffset = glitchAmount/6.0;
                float rnd = random2d(vec2(time , 9545.0));
                vec2 colOffset = vec2(randomRange(vec2(time , 9545.0),-maxColOffset,maxColOffset), 
                                randomRange(vec2(time , 7205.0),-maxColOffset,maxColOffset));
                vec4 texAtPoint = texture2D(iChannel0, uv + colOffset);
                if (rnd < 0.33){
                    outColour.r = texAtPoint.r;
                    outColour.a = texAtPoint.a;
                }else if (rnd < 0.66){
                    outColour.g = texAtPoint.g;
                    outColour.a = texAtPoint.a;                
                } else{
                    outColour.b = texAtPoint.b;  
                    outColour.a = texAtPoint.a;
                }
                
                outColour = mix(outColour, vec4(1.,0.,0.,0.), 1. - step(iTime, uv.y));
            }
        } else if(time >= 1.){
            discard;
        } 
    } else {
        discard;
    } 

    gl_FragColor = outColour;
    gl_FragColor.a = outColour.a;
}
  `
const vertexShader = `
    varying vec2 st;
    void main()	{
        st = uv;
        gl_Position = vec4(position.xy, 0., 1.0);
    }
  `

const vertexShaderWithOffset = `
    uniform vec2 offset;
    varying vec2 st;
    void main()	{
        st = uv-vec2(offset.x,-offset.y);
        gl_Position = vec4(position.xy, 0., 1.0);
    }
  `

const ASPECT_RATIO_MULTIPLIER = 0.5
const ITIME_IMAGE_VISIBLE = 0.0
const ITIME_IMAGE_GONE = 1.0
const GLITCH_SCALE_MIN = 0.04
const GLITCH_SCALE_MAX = 0.1
const GLITCH_BOX_SCALE_MIN = 0.03
const GLITCH_BOX_SCALE_MAX = 0.04
const GLITCH_SIZE_PADDING = 0.01
const GLITCH_IMAGE_AMOUNT_MAX = 0.15
const GLITCH_IMAGE_AMOUNT_MIN = 0.01

class ImageViewerCanvas extends React.Component {
  constructor (props) {
    super(props)
    this.Helper = new Helper_class()
    this.renderer = null
    this.scene = null
    this.camera = null
    this.mount = null
    this.animate = null
    this.animationRequestId = null
    this.runningAnimations = 0
    this.texturesLeftToLoad = null
    this.onWindowResize = null
    this.images = new Map()
    this.state = {
      renderRequested: false,
      loading: false
    }
  }

  componentDidMount () {
    this.initialiseThreeJSCanvas()
  }

  componentWillUnmount () {
    this.cleanupThreeJSCanvasAndReferences()
  }

  async componentDidUpdate (prevProps) {
    if (prevProps.states !== this.props.states) {
      this.clearScene()
      await this.updateImages(this.props.states)
    }

    if (this.state.renderRequested) {
      this.requestAnimationIfNotRequested()
      this.setState({ renderRequested: false })
    }
  }

  requestAnimationIfNotRequested () {
    if (this.animationRequestId === null) {
      this.animationRequestId = requestAnimationFrame(this.animate)
    }
  }

  async initialiseThreeJSCanvas () {
    var _this = this
    this.scene = new THREE.Scene()

    //add this to show the background
    //this.scene.background = new THREE.Color(0xff0000);

    // an OrthographicCamera with these parameters and a 2 unit plane will fill the canvas
    this.camera = new THREE.OrthographicCamera(
      -1, // left
      1, // right
      1, // top
      -1, // bottom
      -1, // near,
      1 // far
    )

    // ensure we add it to the container
    const dimens = this.getSizesForRenderer(this.mount)

    this.renderer = new THREE.WebGLRenderer({
      format: THREE.RGBAFormat,
      antialias: false,
      alpha: true,
      depth: false,
      powerPreference: 'high-performance',
      precision: 'lowp'
    })
    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.autoClear = false
    this.renderer.sortObjects = false

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(dimens.width, dimens.height)

    // add canvas to the dom
    this.mount.appendChild(this.renderer.domElement)

    // window resize listener to make sure canvas fits the new window size
    this.addWindowResizeListenerForRenderer()

    // this is our render function to render the scene
    this.animate = function () {
      // only render if there are objects to render
      _this.renderer.render(_this.scene, _this.camera)

      if (_this.runningAnimations > 0) {
        _this.animationRequestId = requestAnimationFrame(_this.animate)
      } else {
        _this.animationRequestId = null
      }
    }

    if (!_.isEmpty(this.props.states)) {
      await this.updateImages(this.props.states)
    }
  }

  cleanupThreeJSCanvasAndReferences () {
    cancelAnimationFrame(this.animationRequestId)
    this.clearScene()
    window.removeEventListener('resize', this.onWindowResize, false)
    this.mount.removeChild(this.renderer.domElement)
  }

  addWindowResizeListenerForRenderer () {
    var _this = this
    // window resize listener to make sure canvas fits the new window size
    this.onWindowResize = function () {
      const dimens = _this.getSizesForRenderer(_this.mount)
      _this.renderer.setPixelRatio(window.devicePixelRatio)
      _this.renderer.setSize(dimens.width, dimens.height)
      _this.requestAnimationIfNotRequested()
    }
    window.addEventListener('resize', this.onWindowResize, false)
  }

  getSizesForRenderer (targetContainer) {
    const rect = targetContainer.getBoundingClientRect()
    const largestDimen = rect.width > rect.height ? rect.width : rect.height
    return {
      width: largestDimen,
      height: largestDimen * ASPECT_RATIO_MULTIPLIER
    }
  }

  async updateImages (states) {
    var _this = this
    this.setState({ loading: true })
    this.images.clear()

    const planeGroup = new THREE.Group()

    // texture uniform params
    const glitch_scale = 0.1
    const glitchStrength = 1
    const glitch_box_scale = 0.05

    const len = states.length
    const masterMaterial = new THREE.ShaderMaterial({
      uniforms: {},
      vertexShader: this.props.enableOffset
        ? vertexShaderWithOffset
        : vertexShader,
      fragmentShader: fragmentShader,
      side: THREE.FrontSide,
      depthTest: false,
      depthWrite: false,
      transparent: true
    })

    this.texturesLeftToLoad = len

    // As mentioned earlier the OrthographicCamera with the parameters we set and a 2 unit plane will fill the canvas
    const masterGeometry = new THREE.PlaneBufferGeometry(2, 2)
    const loader = new THREE.TextureLoader()
    loader.crossOrigin = '*'
    for (var key in states) {
      if (states.hasOwnProperty(key)) {
        const state = states[key]

        const resizedFullBase64PngString = await this.resizeImageToPreventEdgePixelRepeat(
          state.fullBase64PngString
        )
        const texture = loader.load(
          resizedFullBase64PngString, // onLoad callback
          function (texture) {
            _this.texturesLeftToLoad -= 1
            const loading =
              _this.texturesLeftToLoad != null && _this.texturesLeftToLoad > 0
            _this.setState({ loading: loading, renderRequested: !loading })
          },

          // onProgress callback currently not supported
          undefined,

          // onError callback
          function (err) {
            console.error('Error loading texture ' + key, err)
          }
        )
        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
        texture.minFilter = THREE.NearestFilter
        texture.magFilter = THREE.NearestFilter
        // account for the resized image
        texture.offset.set(-0.5, -0.5)
        texture.repeat.set(2, 2)

        const visible = _.isFunction(this.props.isVisibleOnInitialRender)
          ? this.props.isVisibleOnInitialRender(
              parseInt(state.tokenId),
              parseInt(this.props.currentSelectedState)
            )
          : true

        const iTimeValue = visible ? ITIME_IMAGE_VISIBLE : ITIME_IMAGE_GONE

        const positionInformation = state.positionInformation

        const topLeftConstraintPercent = new THREE.Vector2(
          (positionInformation.start.x * 1.0) / positionInformation.width,
          (positionInformation.start.y * 1.0) / positionInformation.height
        )
        const bottomRightConstraintPercent = new THREE.Vector2(
          (positionInformation.end.x * 1.0) / positionInformation.width,
          (positionInformation.end.y * 1.0) / positionInformation.height
        )

        const uniforms = {
          iChannel0: { value: texture },
          glitchScale: { value: glitch_scale },
          glitchBoxSize: { value: glitch_box_scale },
          strength: { value: glitchStrength },
          iTime: { type: 'f', value: iTimeValue },
          glitchSizePadding: { type: 'f', value: GLITCH_SIZE_PADDING },
          glitchAmount: { type: 'f', value: 0.0 },
          topLeftConstraintPercent: {
            type: 'v2',
            value: topLeftConstraintPercent
          },
          bottomRightConstraintPercent: {
            type: 'v2',
            value: bottomRightConstraintPercent
          },
          offset: { type: 'v2', value: new THREE.Vector2(0.0, 0.0) }
        }

        uniforms['iChannel0'].value.wrapS = uniforms['iChannel0'].value.wrapT =
          THREE.ClampToEdgeWrapping

        const material = masterMaterial.clone()
        material.uniforms = uniforms

        const plane = new THREE.Mesh(masterGeometry, material)
        plane.visible = visible
        plane.matrixAutoUpdate = false
        plane.renderOrder = parseInt(state.tokenId)
        if (!this.overrideAddingToScene) {
          planeGroup.add(plane)
        }

        this.images.set(state.tokenId, {
          plane: plane,
          positionInformation: state.positionInformation
        })
      }
    }

    if (!this.overrideAddingToScene) {
      this.scene.add(planeGroup)
    }

    this.setState({
      loading: this.texturesLeftToLoad != null && this.texturesLeftToLoad > 0
    })
  }

  /**
   * Adds a 1 pixel transparent border around the image to prevent the exdge pixel repeating to infinity due to texture clamping
   * If in future Three js introduces "clamp to border" as an option, this workaround can be removed */
  async resizeImageToPreventEdgePixelRepeat (dataUrl) {
    const img = await this.Helper.loadImage(dataUrl)
    const borderCanvas = this.Helper.createOffscreenCanvas(
      config.WIDTH + 2,
      config.HEIGHT + 2
    )
    const ctx = borderCanvas.getContext('2d')
    //draw 1 pixel in
    ctx.drawImage(img, 1, 1)

    return borderCanvas.toDataURL()
  }

  animateLayerIn (tokenId, timeline, callback = null) {
    var _this = this
    const state = this.images.get(tokenId)
    if (state == null) {
      return
    }
    const meshToAnimateIn = state.plane
    meshToAnimateIn.visible = true
    const positionInformation = state.positionInformation

    const endTime =
      1.0 - positionInformation.start.y / (positionInformation.height * 1.0)
    const startTime =
      1.0 - positionInformation.end.y / (positionInformation.height * 1.0)
    this.runningAnimations += 1
    timeline
      .fromTo(
        meshToAnimateIn.material.uniforms.iTime,
        { value: endTime },
        {
          value: startTime,
          duration: this.props.animationDuration,

          ease: this.props.animationEase,
          onUpdate: function () {
            _this.randomizeGlitchValues(meshToAnimateIn.material)
          },
          onComplete: function () {
            meshToAnimateIn.material.uniforms.iTime.value = ITIME_IMAGE_VISIBLE
            meshToAnimateIn.visible = true
            _this.runningAnimations -= 1
            if (callback != null) {
              callback()
            }
          }
        },
        0
      )
      .fromTo(
        meshToAnimateIn.material.uniforms.glitchAmount,
        { value: GLITCH_IMAGE_AMOUNT_MAX },
        {
          value: GLITCH_IMAGE_AMOUNT_MIN,
          duration: this.props.animationDuration,

          ease: this.props.animationEase,
          onUpdate: function () {},
          onComplete: function () {}
        },
        0
      )
    this.requestAnimationIfNotRequested()
  }

  animateLayerOut (tokenId, timeline, callback = null) {
    var _this = this
    const state = this.images.get(tokenId)
    if (state == null) return

    const meshToAnimateOut = state.plane
    meshToAnimateOut.visible = true
    const positionInformation = state.positionInformation

    const endTime =
      1.0 - positionInformation.start.y / (positionInformation.height * 1.0)
    const startTime =
      1.0 - positionInformation.end.y / (positionInformation.height * 1.0)
    this.runningAnimations += 1
    timeline
      .fromTo(
        meshToAnimateOut.material.uniforms.iTime,
        { value: startTime },
        {
          value: endTime,
          duration: this.props.animationDuration,
          ease: this.props.animationEase,
          onUpdate: function () {
            _this.randomizeGlitchValues(meshToAnimateOut.material)
          },
          onComplete: function () {
            meshToAnimateOut.material.uniforms.iTime.value = ITIME_IMAGE_GONE
            meshToAnimateOut.visible = false
            _this.runningAnimations -= 1
            if (callback != null) {
              callback()
            }
          }
        },
        0
      )
      .fromTo(
        meshToAnimateOut.material.uniforms.glitchAmount,
        { value: GLITCH_IMAGE_AMOUNT_MIN },
        {
          value: GLITCH_IMAGE_AMOUNT_MAX,
          duration: this.props.animationDuration,

          ease: this.props.animationEase,
          onUpdate: function () {},
          onComplete: function () {}
        },
        0
      )
    this.requestAnimationIfNotRequested()
  }

  randomizeGlitchValues (material) {
    material.uniforms.glitchScale.value = this.Helper.getRandomArbitrary(
      GLITCH_SCALE_MIN,
      GLITCH_SCALE_MAX
    )
    material.uniforms.glitchBoxSize.value = this.Helper.getRandomArbitrary(
      GLITCH_BOX_SCALE_MIN,
      GLITCH_BOX_SCALE_MAX
    )
    material.uniforms.glitchBoxSize.needsUpdate = true
    material.uniforms.glitchScale.needsUpdate = true
  }

  render () {
    return (
      <React.Fragment>
        <div {...this.props} ref={ref => (this.mount = ref)} />
        <LoadingDialog open={this.state.loading} />
      </React.Fragment>
    )
  }

  clearScene () {
    this.scene.remove.apply(this.scene, this.scene.children)
  }
}

export default ImageViewerCanvas
