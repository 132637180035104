import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'
import Helper_class from './../../../libs/helpers.js'
import _ from 'underscore'
const async = require('async')

class Get_nfts_for_user_account_usecase {
  constructor () {
    this.NftDataSource = new Blockchain_nft_data_source_class()
    this.Helper = new Helper_class()

    this.NftInformation = class {
      constructor (
        tokenId,
        croppedBase64PngString,
        fullBase64PngString,
        complete,
        name,
        number,
        seriesId,
        artist,
        positionInformation,
        hasAlpha,
        colorIndex,
        completionStatus
      ) {
        this.tokenId = tokenId
        this.croppedBase64PngString = croppedBase64PngString
        this.fullBase64PngString = fullBase64PngString
        this.complete = complete
        this.name = name
        this.number = number
        this.seriesId = seriesId
        this.artist = artist
        this.positionInformation = positionInformation
        this.hasAlpha = hasAlpha
        this.colorIndex = colorIndex
        this.completionStatus = completionStatus
      }
    }
  }

  async execute () {
    var _this = this
    const nftsInformation = await this.NftDataSource.fetchAllTokensForAccount()
    const myNfts = await async.map(nftsInformation, async function (
      nftInfo,
      callback
    ) {
      const returnNftInfo = _this.createNftInformation(nftInfo, null)

      callback(null, returnNftInfo)
    })

    return _.indexBy(myNfts, 'tokenId')
  }

  createNftInformation (nftInfo) {
    return new this.NftInformation(
      nftInfo.tokenId,
      nftInfo.croppedBase64PngString,
      nftInfo.fullBase64PngString,
      nftInfo.complete,
      nftInfo.name,
      nftInfo.number,
      nftInfo.seriesId,
      nftInfo.artist,
      nftInfo.positionInformation,
      nftInfo.hasAlpha,
      nftInfo.colorIndex,
      nftInfo.completionStatus
    )
  }
}

export default Get_nfts_for_user_account_usecase
