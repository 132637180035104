import TokenDistributionDataSource_class from '../datasource/token_distribution_data_source.js'

class ClaimPaintForClaimDataUsecase {
  constructor () {
    this.TokenDistributionDataSource = new TokenDistributionDataSource_class()

    this.claimDetails = class {
      constructor (index, amount, proof) {
        this.index = index
        this.amount = amount
        this.proof = proof
      }
    }
  }

  async execute (address, index, amount, proof) {
    var _this = this
    return new Promise(async function (resolve, reject) {
      _this.TokenDistributionDataSource.claimAmountAtIndexForAddressWithProof(
        index,
        address,
        amount,
        proof
      )
        .then(() => {
          resolve()
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  }
}
export default ClaimPaintForClaimDataUsecase
