import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import TradeInfoCard from './tradeInfoCard'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  buttonLarge: {
    fontSize: '63px'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const MarketButton = withStyles(theme => ({
  root: {
    fontSize: theme.spacing(4)
  }
}))(Button)

export default function TradeLandingPage (props) {
  const classes = useStyles()

  const constructMarketButton = (title, clickUrl, imagePath = null) => {
    return (
      <MarketButton
        size='large'
        color='secondary'
        onClick={() => {
          window.open(clickUrl, '_blank')
        }}
        variant={'contained'}
        startIcon={
          imagePath != null && (
            <Icon style={{ fontSize: 45, textAlign: 'center' }}>
              <img src={imagePath} alt={title} />
            </Icon>
          )
        }
        style={{ margin: '12px', marginTop: '24px' }}
      >
        {title}
      </MarketButton>
    )
  }

  return (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      <Grid
        container
        spacing={4}
        direction='row'
        justify='space-around'
        alignItems='flex-start'
      >
        <Grid key={0} item xs={12} sm={12} md={6} lg={6} xl={6} align='center'>
          <TradeInfoCard
            title={'Uniswap'}
            image={'/images/PAINT_ETH_trade.png'}
            availableMarketButtons={() => (
              <React.Fragment>
                {constructMarketButton(
                  'Uniswap',
                  'https://app.uniswap.org/#/swap?inputCurrency=0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
                  'images/uniswap_logo_white.svg'
                )}
                {constructMarketButton(
                  'MXC',
                  'https://www.mxc.com/trade/easy#PAINT_USDT',
                  'images/MXC.svg'
                )}
                {constructMarketButton(
                  'Hoo.com',
                  'https://hoo.com/spot/paint-usdt',
                  'images/Hoo.svg'
                )}
                {constructMarketButton(
                  'Bilaxy',
                  'https://bilaxy.com/trade/PAINT_ETH',
                  'images/Bilaxy.svg'
                )}
              </React.Fragment>
            )}
            cardContent={() => (
              <React.Fragment>
                <Typography
                  className={clsx(classes.typographyLight)}
                  style={{ marginTop: '24px' }}
                  variant='h4'
                  component='h4'
                  color='textPrimary'
                >
                  Trade PAINT/ETH
                </Typography>
                <Typography
                  className={clsx(classes.typographyLight)}
                  variant='h6'
                  component='h6'
                  color='textPrimary'
                >
                  Top up your PAINT balance or sell your stash
                </Typography>
              </React.Fragment>
            )}
          />
        </Grid>
        <Grid key={1} item xs={12} sm={12} md={6} lg={6} xl={6} align='center'>
          <TradeInfoCard
            title={'Opensea'}
            image={'/images/MurAll_NFT_ETH_trade.png'}
            availableMarketButtons={() => (
              <React.Fragment>
                {constructMarketButton(
                  'Opensea',
                  'https://opensea.io/assets/murall',
                  'images/opensea_logo_white.svg',
                  'Opensea'
                )}
              </React.Fragment>
            )}
            cardContent={() => (
              <React.Fragment>
                <Typography
                  className={clsx(classes.typographyLight)}
                  style={{ marginTop: '24px' }}
                  variant='h4'
                  component='h4'
                  color='textPrimary'
                >
                  Trade MURALL NFTs
                </Typography>
                <Typography
                  className={clsx(classes.typographyLight)}
                  variant='h6'
                  component='h6'
                  color='textPrimary'
                >
                  Sell your artwork or buy other artists work
                </Typography>
              </React.Fragment>
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}
