import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import _ from 'underscore'

import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import FastForwardIcon from '@material-ui/icons/FastForward'
import PauseIcon from '@material-ui/icons/Pause'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import InfoIcon from '@material-ui/icons/Info'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    left: '0px',
    bottom: '0px',
    right: '0px',
    width: '100%',
    zIndex: '99'
  },
  playControlsWrapper: {
    marginTop: '-40px',
    paddingTop: '20px',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  playControlsContainer: {
    padding: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 10px 4px ' + theme.palette.secondary.main + 'B3',
    width: '100%',
    zIndex: '99'
  },
  slider: {
    margin: 0,
    padding: 0,
    color: theme.palette.secondary.main,
    transform: 'translate(0%, -50%)'
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(270deg)'
  },
  controlsExpandMore: {
    marginBottom: '25px'
  },
  controlsExpandLess: {
    marginBottom: '8px'
  },
  gridRootContainer: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  },
  defaultSidePadding: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  gridColumnContainer: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  gridRowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  largeIcon: {
    height: 38,
    width: 38
  },
  controlsExpandIcon: {
    padding: '36px',
    alignSelf: 'center'
  },

  backgroundImage: {
    imageRendering: 'pixelated',
    backgroundSize: 'cover',
    backgroundClip: 'padding-box',
    backgroundPosition: 'center center',
    backgroundBlendMode: `normal, normal`
  }
}))

export default function HistoryNavigationControlsMobile (props) {
  const classes = useStyles()
  const [settingsExpanded, setSettingsExpanded] = React.useState(false)

  const handleSettingsExpandClick = event => {
    setSettingsExpanded(!settingsExpanded)
  }

  const constructPlayControls = () => {
    return props.singleTokenMode ? null : (
      <div
        className={clsx(classes.gridRowContainer, classes.defaultSidePadding)}
        style={{
          justifyContent: 'space-between',
          paddingBottom: '6px',
          paddingTop: '12px'
        }}
      >
        <IconButton aria-label='previous' onClick={props.onSkipPreviousClicked}>
          <SkipPreviousIcon />
        </IconButton>
        <IconButton aria-label='previous' onClick={props.onFastRewindClicked}>
          <FastRewindIcon />
        </IconButton>
        <IconButton aria-label='play/pause' onClick={props.onPlayPauseClicked}>
          {props.isPlaying ? (
            <PauseIcon className={clsx(classes.largeIcon)} />
          ) : (
            <PlayArrowIcon className={clsx(classes.largeIcon)} />
          )}
        </IconButton>
        <IconButton aria-label='next' onClick={props.onFastForwardClicked}>
          <FastForwardIcon />
        </IconButton>
        <IconButton aria-label='next' onClick={props.onSkipNextClicked}>
          <SkipNextIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: settingsExpanded
          })}
          onClick={handleSettingsExpandClick}
          aria-expanded={settingsExpanded}
          aria-label='settings'
        >
          <SettingsIcon />
        </IconButton>
      </div>
    )
  }

  const constructInfoView = () => {
    return (
      <Grid item xs={12}>
        {_.isUndefined(props.currentSelection) ? null : (
          <div
            className={clsx(
              classes.gridRowContainer,
              classes.defaultSidePadding
            )}
            style={{ flex: 1, paddingBottom: '24px', paddingTop: '24px' }}
          >
            <React.Fragment>
              <div
                className={clsx(classes.gridColumnContainer)}
                style={{ alignSelf: 'flex-start' }}
              >
                <React.Fragment>
                  <Typography
                    component='h4'
                    variant='h4'
                    noWrap
                    className={clsx(classes.typography)}
                    color='textPrimary'
                  >
                    {props.currentSelection.name}
                  </Typography>

                  <Typography
                    component='span'
                    variant='h6'
                    className={clsx(classes.inline, classes.typography)}
                    color='textPrimary'
                  >
                    {props.currentSelection.number} of Series{' '}
                    {props.currentSelection.seriesId}
                  </Typography>

                  <Typography
                    component='span'
                    variant='subtitle2'
                    noWrap
                    className={clsx(classes.inline, classes.typography)}
                    color='textSecondary'
                  >
                    {props.currentSelection.artist}
                  </Typography>
                </React.Fragment>
              </div>
              <div
                className={clsx(classes.gridRowContainer)}
                style={{
                  flex: 1,
                  marginRight: '16px',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  right: 0
                }}
              >
                <IconButton
                  aria-label='next'
                  onClick={props.onInfoButtonClicked}
                >
                  <InfoIcon />
                </IconButton>

                <IconButton
                  aria-label='next'
                  onClick={props.onLocateButtonClicked}
                >
                  <MyLocationIcon />
                </IconButton>
              </div>
            </React.Fragment>
          </div>
        )}
      </Grid>
    )
  }
  const constructSettings = () => {
    return (
      <div
        className={clsx(classes.gridRowContainer, classes.defaultSidePadding)}
        style={{ justifyContent: 'space-evenly', marginBottom: '12px' }}
      >
        <TextField
          style={{ flex: 1, marginRight: '24px' }}
          onChange={props.onFadeDurationChanged}
          id='standard-helperText'
          label='Transition duration'
          value={props.fadeDurationPlaying}
          helperText={'in milliseconds'}
          inputProps={{
            step: 50,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
        <TextField
          style={{ flex: 1 }}
          onChange={props.onDurationBetweenStatesChanged}
          id='standard-helperText'
          label='Delay between states'
          value={props.durationBetweenStates}
          helperText={'in milliseconds'}
          inputProps={{
            step: 50,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </div>
    )
  }

  const bgImageForCurrentSelection = () => {
    return _.isUndefined(props.currentSelection)
      ? null
      : `radial-gradient(circle at top right,
                                #26262B80,
                                #26262B 80%),
                                url(${props.currentSelection.croppedBase64PngString})`
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label='show/hide play controls'
        onClick={props.onExpandNavigationControlsIconClicked}
        className={clsx(
          classes.controlsExpandIcon,
          classes.largeIcon,
          {
            [classes.controlsExpandMore]: props.open
          },
          {
            [classes.controlsExpandLess]: !props.open
          }
        )}
      >
        {props.open ? (
          <ExpandMore className={clsx(classes.largeIcon)} />
        ) : (
          <ExpandLess className={clsx(classes.largeIcon)} />
        )}
      </IconButton>
      <Collapse in={props.open} mountOnEnter unmountOnExit>
        <div className={clsx(classes.playControlsWrapper)}>
          <Paper
            className={clsx(classes.playControlsContainer, {
              [classes.backgroundImage]: !_.isUndefined(props.currentSelection)
            })}
            style={{ backgroundImage: bgImageForCurrentSelection() }}
          >
            <Grid
              className={classes.gridRootContainer}
              container
              spacing={0}
              direction='row'
              justify='space-between'
              alignItems='stretch'
            >
              <Grid item xs={12}>
                <div className={classes.gridColumnContainer}>
                  {!props.singleTokenMode && (
                    <Slider
                      className={classes.slider}
                      defaultValue={0}
                      value={props.currentSelectedState}
                      onChange={props.onSliderChange}
                      step={1}
                      min={props.rangeStart}
                      max={props.rangeEnd}
                    />
                  )}
                  {constructPlayControls()}
                  <Collapse
                    in={settingsExpanded}
                    timeout='auto'
                    unmountOnExit
                    style={{ width: '100%' }}
                  >
                    {constructSettings()}
                  </Collapse>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginLeft: '24px', marginRight: '24px' }} />
              </Grid>
              {constructInfoView()}
            </Grid>
          </Paper>
        </div>
      </Collapse>
    </div>
  )
}
