'use strict'

//css
import './../../../css/reset.css'
import './../../../css/layout.css'
import './../../../css/menu.css'
import './../../../css/print.css'
import './../../../css/slider.css'

//js
import React from 'react'
import config from '../../config.js'
import Base_gui_class from '../../core/base-gui.js'
import Base_layers_class from '../../core/base-layers.js'
import Base_tools_class from '../../core/base-tools.js'
import { withStyles } from '@material-ui/core/styles'
import Blockchain_read_data from '../../modules/blockchain/read_blockchain_data.js'
import SetPixelsOnMurallAndNotifyUsecase from '../../modules/blockchain/usecase/set_pixels_on_murall_and_notify_usecase.js'
import Get_wallet_connected_state_usecase from '../../modules/blockchain/usecase/get_wallet_connected_state_usecase.js'
import Get_is_erc20_transfer_allowed_for_amount_from_address_for_user_account_usecase from '../../modules/blockchain/usecase/get_is_erc20_transfer_allowed_for_amount_from_address_for_user_account_usecase.js'
import Allow_erc20_transfer_from_address_for_user_account_usecase from '../../modules/blockchain/usecase/allow_erc20_transfer_from_address_for_user_account_usecase.js'
import GetHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/get_has_agreed_to_legal_disclaimer_usecase.js'
import SetUserHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/set_user_agreed_to_legal_disclaimer_usecase.js'
import LegalDisclaimerDialog from '../legal/legal_disclaimer_dialog.js'
import Base_state_class from '../../core/base-state.js'
import DrawingTransactionDialog from './drawingTransactionDialog.js'
import BraveBugWarningDialog from './braveBugWarningDialog.js'
import StyledDialog from '../../uicomponents/styled_dialog.js'
import LoadingDialog from '../../uicomponents/loading_dialog.js'
import Typography from '@material-ui/core/Typography'
import _ from 'underscore'
import Helper_class from '../../libs/helpers.js'

const useStyles = theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
})

class DrawingClient extends React.Component {
  constructor (props) {
    super(props)
    this.Helper = new Helper_class()
    this.readMurAll = new Blockchain_read_data()
    this.setPixelsOnMurallAndNotifyUsecase = new SetPixelsOnMurallAndNotifyUsecase()
    this.getWalletConnectedStateUsecase = new Get_wallet_connected_state_usecase()
    this.GetIsErc20TransferAllowedForAmountFromAddressForUserAccountUsecase = new Get_is_erc20_transfer_allowed_for_amount_from_address_for_user_account_usecase()
    this.AllowErc20TransferAllowedFromAddressForUserAccountUsecase = new Allow_erc20_transfer_from_address_for_user_account_usecase()
    this.GetHasAgreedToLegalDisclaimerUsecase = new GetHasAgreedToLegalDisclaimerUsecase()
    this.SetUserHasAgreedToLegalDisclaimerUsecase = new SetUserHasAgreedToLegalDisclaimerUsecase()

    this.state = {
      loaded: false,
      showLoading: false,
      agreedToDisclaimer: this.GetHasAgreedToLegalDisclaimerUsecase.execute(),
      drawingTransactionRequested: false,
      requestToSpendPAINTRequired: false,
      drawingTransactionDetail: null,
      name: '',
      number: 0,
      seriesId: 0,
      isBraveBrowser: false
    }
  }

  componentDidMount () {
    const drawingContainer = document.querySelector('#drawingContainer')
    this.setVisibility(drawingContainer, true)
    drawingContainer.addEventListener(
      'writeTransaction',
      this.onDrawEventReceived.bind(this),
      false
    )
    drawingContainer.addEventListener(
      'changeLoadingVisibility',
      this.onChangeLoadingVisibilityEventReceived.bind(this),
      false
    )
    // forcing state update to ensure componentDidUpdate fires
    this.setState({ loaded: true })
    this.warnBraveBrowserUserIfNecessary()
    if (!(_.isNull(window.Layers) || _.isUndefined(window.Layers))) {
      this.readMurAll.read_pixel_data_from_blockchain_to_canvas()
    }
  }

  componentWillUnmount () {
    this.setState({ loaded: false })
    const drawingContainer = document.querySelector('#drawingContainer')
    this.setVisibility(drawingContainer, false)
    drawingContainer.removeEventListener(
      'writeTransaction',
      this.onDrawEventReceived,
      false
    )
    drawingContainer.removeEventListener(
      'changeLoadingVisibility',
      this.onChangeLoadingVisibilityEventReceived,
      false
    )
  }

  componentDidUpdate () {
    // dom is loaded and ready at this time so safe to initiate as sizes will be correct
    if (_.isNull(window.Layers) || _.isUndefined(window.Layers)) {
      //initiate app
      var Layers = new Base_layers_class()
      var Base_tools = new Base_tools_class(true)
      var GUI = new Base_gui_class()
      var Base_state = new Base_state_class()

      //register as global for quick or external access
      window.Layers = Layers
      window.State = Base_state // window.State.save();

      //render all
      GUI.load_modules()
      GUI.load_default_values()
      GUI.render_main_gui()

      Layers.init()
    }
  }
  agreedToTerms (rememberChoice) {
    this.SetUserHasAgreedToLegalDisclaimerUsecase.execute(true, rememberChoice)
    this.setState({ agreedToDisclaimer: true })
  }
  render () {
    const { classes } = this.props
    return (
      <div>
        {this.state.drawingTransactionRequested &&
        !this.state.requestToSpendPAINTRequired &&
        !this.state.showErrorDialog ? (
          <DrawingTransactionDialog
            open={this.state.drawingTransactionRequested}
            numberOfPixels={this.state.drawingTransactionDetail.numberOfPixels}
            blockchainPixelDataLength={
              this.state.drawingTransactionDetail.blockchainPixelData.length
            }
            blockchainPixelGroupDataLength={
              this.state.drawingTransactionDetail.blockchainPixelGroupData
                .length
            }
            blockchainPixelGroupIndexDataLength={
              this.state.drawingTransactionDetail.blockchainPixelGroupIndexData
                .length
            }
            blockchainTransparentPixelGroupDataLength={
              this.state.drawingTransactionDetail
                .blockchainTransparentPixelGroupData.length
            }
            blockchainTransparentPixelGroupIndexDataLength={
              this.state.drawingTransactionDetail
                .blockchainTransparentPixelGroupIndexData.length
            }
            blockchainColourIndexDataLength={
              this.state.drawingTransactionDetail.colourIndexData.length
            }
            alphaEnabled={this.state.drawingTransactionDetail.alphaEnabled}
            alphaChannel={this.state.drawingTransactionDetail.alphaChannel}
            costInPaint={config.web3.utils.fromWei(
              this.state.drawingTransactionDetail.costInPaint,
              'ether'
            )}
            croppedBase64PngString={
              this.state.drawingTransactionDetail.croppedBase64PngString
            }
            onCancelClicked={this.onCloseDialogClicked.bind(this)}
            onDrawClicked={this.onDrawClicked.bind(this)}
            onNameValueChanged={this.handleNameChanged.bind(this)}
            onNumberValueChanged={this.handleNumberChanged.bind(this)}
            onSeriesIdValueChanged={this.handleSeriesIdChanged.bind(this)}
            numberOfColors={this.state.drawingTransactionDetail.numberOfColors}
          />
        ) : null}
        {this.state.requestToSpendPAINTRequired ? (
          <StyledDialog
            open={this.state.requestToSpendPAINTRequired}
            onClose={this.onClosePAINTSpendDialogClicked.bind(this)}
            dialogTitle={'Allow MurAll to spend your PAINT?'}
            dialogContent={() => (
              <Typography
                className={classes.typography}
                variant='body2'
                component='p'
              >
                In order to draw on MurAll, you must allow MurAll to spend your
                PAINT tokens.
              </Typography>
            )}
            positiveButtonTitle={'Allow'}
            onPositiveButtonClick={this.onRequestSpendingPAINT.bind(this)}
            negativeButtonTitle={'Cancel'}
            onNegativeButtonClick={this.onClosePAINTSpendDialogClicked.bind(
              this
            )}
          />
        ) : null}
        {this.state.showErrorDialog && (
          <StyledDialog
            open={this.state.showErrorDialog}
            onClose={this.onCloseErrorDialogClicked.bind(this)}
            dialogTitle={'Error: too many colors in image'}
            dialogContent={() => (
              <Typography
                className={classes.typography}
                variant='body2'
                component='p'
              >
                Your drawing must have no more than 256 colors
                <br />
                <br />
                Please go to 'Image {'>'} Convert to 256 color depth' or select
                the 256 colour palette tool on the left menu to convert your
                image to 256 colors
              </Typography>
            )}
            positiveButtonTitle={'Ok'}
            onPositiveButtonClick={this.onCloseErrorDialogClicked.bind(this)}
          />
        )}
        <LoadingDialog open={this.state.showLoading} />
        {!this.state.agreedToDisclaimer && (
          <LegalDisclaimerDialog
            onAgreeClicked={this.agreedToTerms.bind(this)}
          />
        )}
        {this.state.agreedToDisclaimer && this.state.isBraveBrowser && (
          <BraveBugWarningDialog
            open={this.state.isBraveBrowser}
            onClose={() => {
              this.setState({ isBraveBrowser: false })
            }}
            onPositiveButtonClick={() => {
              this.setState({ isBraveBrowser: false })
            }}
          />
        )}
      </div>
    )
  }

  async warnBraveBrowserUserIfNecessary () {
    const isBrave =
      (navigator.brave && (await navigator.brave.isBrave())) || false
    this.setState({ isBraveBrowser: isBrave })
  }

  setVisibility (target, visible) {
    if (visible) {
      target.style.display = 'block'
    } else {
      target.style.display = 'none'
    }
  }

  handleNameChanged (event) {
    this.setState({ name: event.target.value })
  }

  handleNumberChanged (event) {
    this.setState({ number: event.target.value })
  }

  handleSeriesIdChanged (event) {
    this.setState({ seriesId: event.target.value })
  }

  async onDrawClicked () {
    this.setState({ drawingTransactionRequested: false })
    const detail = this.state.drawingTransactionDetail
    this.setPixelsOnMurallAndNotifyUsecase.execute(
      detail.colourIndexData,
      detail.blockchainPixelData,
      detail.blockchainPixelGroupData,
      detail.blockchainPixelGroupIndexData,
      detail.blockchainTransparentPixelGroupData,
      detail.blockchainTransparentPixelGroupIndexData,
      this.state.name,
      this.state.number,
      this.state.seriesId,
      detail.alphaEnabled
    )
  }

  async onRequestSpendingPAINT () {
    this.setState({
      showLoading: true
    })
    try {
      await this.AllowErc20TransferAllowedFromAddressForUserAccountUsecase.execute(
        config.paintTokenAddress,
        config.murallContractAddress
      )

      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message: 'You can now draw on MurAll!',
        success: true
      })
    } catch (error) {
      console.error(error)
      if (error.message.indexOf('User denied') != -1) {
        this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'You rejected the transaction!',
          error: true
        })
      } else {
        this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'Sorry, the transaction failed: ' + error.name,
          error: true
        })
      }
    }
    this.setState({
      requestToSpendPAINTRequired: false,
      drawingTransactionRequested: false,
      showLoading: false
    })
  }

  onCloseDialogClicked () {
    this.setState({
      requestToSpendPAINTRequired: false,
      drawingTransactionRequested: false
    })
  }

  onClosePAINTSpendDialogClicked () {
    this.setState({
      requestToSpendPAINTRequired: false,
      drawingTransactionRequested: false
    })
  }

  onCloseErrorDialogClicked () {
    this.setState({
      showErrorDialog: false,
      requestToSpendPAINTRequired: false,
      drawingTransactionRequested: false
    })
  }

  async onDrawEventReceived (e) {
    const connected = this.getWalletConnectedStateUsecase.execute()
    if (!connected) {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message: 'Wallet not connected - please connect wallet!',
        error: true
      })
      return
    }

    const detail = e.detail

    const allowed = await this.GetIsErc20TransferAllowedForAmountFromAddressForUserAccountUsecase.execute(
      config.paintTokenAddress,
      config.murallContractAddress,
      detail.costInPaint
    )

    this.setState({
      drawingTransactionRequested: true,
      drawingTransactionDetail: detail,
      requestToSpendPAINTRequired: !allowed,
      showErrorDialog: detail.numberOfColors > 256
    })
  }

  onChangeLoadingVisibilityEventReceived (e) {
    this.setState({ showLoading: e.detail.showLoading })
  }
}

export default withStyles(useStyles, { withTheme: true })(DrawingClient)
