import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'

class Get_MurAll_state_changes_for_token_ids_usecase {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataSource = new Blockchain_nft_data_source_class()
  }

  async execute (tokenIds) {
    return this.NftDataSource.fetchAllTokenInfoForTokenIndexes(tokenIds)
  }
}
export default Get_MurAll_state_changes_for_token_ids_usecase
