import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar, SnackbarContent } from 'notistack'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  bgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    imageRendering: 'pixelated',
    backgroundBlendMode: `multiply, normal, normal`
  },
  card: {
    backgroundColor: '#fddc6c',
    width: '100%'
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  actionRoot: {
    padding: '8px 8px 8px 16px'
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  closeButton: {
    padding: '8px 8px'
  }
}))

const PaintedEventSnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = () => {
    closeSnackbar(props.id)
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card
        className={clsx(classes.bgImage, classes.card)}
        style={{
          backgroundImage: `radial-gradient(circle at top right,
                                           #cccccc,
                                           #4a4a4a,
                                           #212121),
                                           url(${props.artwork.croppedBase64PngString}),
                                           linear-gradient(to right, #26262B, #26262B)`
        }}
      >
        <CardHeader
          action={
            <IconButton className={classes.closeButton} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          }
          title='New drawing'
        />
        <CardContent></CardContent>
        <CardActions classes={{ root: classes.actionRoot }}>
          <div className={classes.icons}>
            <Typography
              component='h5'
              variant='h5'
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
            >
              {props.artwork.name}
            </Typography>

            <Typography
              component='span'
              variant='subtitle1'
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color='textPrimary'
            >
              {props.artwork.number} of Series {props.artwork.seriesId}
            </Typography>
            <Typography
              component='span'
              variant='subtitle2'
              noWrap
              className={clsx(classes.inline, classes.typography)}
              color={'textSecondary'}
            >
              {props.artwork.artist}
            </Typography>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  )
})

export default PaintedEventSnackMessage
