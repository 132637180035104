import Blockchain_MurAll_data_source_class from '../datasource/murall_data_source.js'
import _ from 'underscore'

class GetTotalMurAllArtistsCountUsecase {
  constructor () {
    this.MurAllDataSource = new Blockchain_MurAll_data_source_class()
  }

  async execute () {
    return this.MurAllDataSource.getTotalNumberOfArtists()
  }
}
export default GetTotalMurAllArtistsCountUsecase
