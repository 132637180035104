import config from './../../../config.js'
import Helper_class from './../../../libs/helpers.js'
import Blockchain_Nft_data_mapper_class from '../nft_data_mapper.js'
import _ from 'underscore'

class Notify_painted_event_usecase {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
  }

  async execute (event) {
    const artist = event.returnValues.artist
    const tokenId = event.returnValues.tokenId
    const colorIndexData = event.returnValues.colorIndex
    const pixelData = event.returnValues.pixelData
    const pixelGroups = event.returnValues.pixelGroups
    const pixelGroupIndexes = event.returnValues.pixelGroupIndexes
    const transparentPixelGroups = event.returnValues.transparentPixelGroups
    const transparentPixelGroupIndexes =
      event.returnValues.transparentPixelGroupIndexes
    const nameMetadata = event.returnValues.metadata[0]
    const otherMetadata = event.returnValues.metadata[1]

    const nftInfo = await this.NftDataMapper.processNftData(
      tokenId,
      artist,
      colorIndexData,
      pixelData,
      pixelGroups,
      pixelGroupIndexes,
      transparentPixelGroups,
      transparentPixelGroupIndexes,
      nameMetadata,
      otherMetadata,
      false
    )
    var _this = this
    setTimeout(() => {
      _this.Helper.dispatchCustomEvent('top_buttons_container', 'painted', {
        artwork: nftInfo
      })
    }, 2000)
  }
}
export default Notify_painted_event_usecase
