import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
const useStyles = makeStyles(theme => ({
  root: {},
  contentRoot: {
    position: 'relative',
    minHeight: '100%',
    minWidth: '100%',
    margin: '0 auto'
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogContent: {
    backgroundColor: theme.palette.secondary.dark,
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  dialogTitleFlex: {
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '8px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center'
  }
}))

export default function StyledImageViewerDialog (props) {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth={'lg'}
      PaperProps={{
        style: { borderRadius: 10 }
      }}
    >
      {props.withCloseButton ? (
        <DialogTitle
          disableTypography={true}
          className={clsx(classes.dialogTitleFlex)}
        >
          <CardHeader
            avatar={
              <Avatar
                aria-label='tokenId'
                src='/images/murall_nft_logo.png'
                className={classes.avatar}
              />
            }
            title={
              <Typography
                className={classes.typography}
                noWrap
                variant='h5'
                component='h4'
              >
                {props.dialogTitle}
              </Typography>
            }
            titleTypographyProps={{
              component: 'h5',
              variant: 'h5',
              align: 'left'
            }}
            subheaderTypographyProps={{ align: 'left' }}
          />

          <IconButton onClick={props.onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <DialogTitle disableTypography={true} className={classes.dialogTitle}>
          <Typography
            component='h5'
            variant='h5'
            noWrap
            className={clsx(classes.typography)}
            color='textPrimary'
          >
            {props.dialogTitle}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent className={classes.dialogContent} scroll={'paper'}>
        <div className={clsx(classes.contentRoot)}>
          {props.dialogImageContent()}
        </div>
      </DialogContent>

      <DialogActions className={clsx(classes.dialogActions)}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'stretch',
            justifyContent: 'stretch'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignSelf: 'stretch',
              justifyContent: 'stretch',
              alignContent: 'flex-start',
              flex: 1,
              flexDirection: props.dialogInformationColumn ? 'column' : 'row',
              paddingTop: '12px',
              paddingLeft: '16px',
              paddingRight: '16px'
            }}
          >
            {props.dialogInformation()}
          </div>
          {!props.hideButtons && (
            <div
              style={{
                display: 'flex',
                alignContent: 'flex-end',
                justifyContent: 'flex-end',
                width: '100%',
                flexDirection: 'row',
                paddingTop: '16px',
                marginBottom: 0
              }}
            >
              {props.dialogActions()}
            </div>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
