'use strict'

import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import AccountButton from './accountButton.js'
import ConnectWalletButton from './connectWalletButton.js'
import PaintButton from './paintButton.js'
import { Link } from 'react-router-dom'
import StyledTooltip from '../../uicomponents/styled_tooltip.js'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'underscore'

const TOOLTIP_ENTER_DELAY_MILLIS = 400
const Z_INDEX_ALWAYS_ON_TOP = 999 // still below material UI stuf, which starts at z index 1000

const useStyles = makeStyles(theme => ({
  topNavButtons: {
    color: theme.palette.secondary.main,
    flex: '1 0 auto',
    alignSelf: 'center'
  },
  murAllLogo: {
    height: '100%'
  },
  murAllLogoLink: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '30px',
    paddingBottom: '30px',
    alignSelf: 'center',
    height: '100%',
    zIndex: Z_INDEX_ALWAYS_ON_TOP
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    alignItems: 'stretch'
  }
}))

export default function NavButtonsDesktop (props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Link to='/home' className={classes.murAllLogoLink}>
        <img
          src='images/murall_top_logo_mobile.svg'
          alt='MurAll logo and title'
          className={classes.murAllLogo}
        />
      </Link>

      <ButtonGroup
        className={classes.topNavButtons}
        id='navButtons'
        style={{
          alignSelf: 'center',
          justifyContent: 'stretch',
          alignItems: 'center',
          alignContent: 'center'
        }}
        size='large'
        color='primary'
        variant='text'
        aria-label='large outlined primary button group'
      >
        <StyledTooltip
          disableFocusListener
          title='Watch MurAll Live'
          arrow
          enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
        >
          <Button
            className={classes.topNavButtons}
            component={Link}
            to={'/live'}
          >
            Live
          </Button>
        </StyledTooltip>

        <StyledTooltip
          disableFocusListener
          title='View MurAlls history'
          arrow
          enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
        >
          <Button
            className={classes.topNavButtons}
            component={Link}
            to={'/history'}
          >
            History
          </Button>
        </StyledTooltip>
        <StyledTooltip
          disableFocusListener
          title='Draw on MurAll'
          arrow
          enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
        >
          <Button
            className={classes.topNavButtons}
            component={Link}
            to={'/draw'}
          >
            Draw
          </Button>
        </StyledTooltip>

        <StyledTooltip
          disableFocusListener
          title='Trade Nfts and PAINT'
          arrow
          enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
        >
          <Button
            className={classes.topNavButtons}
            component={Link}
            to={'/trade'}
          >
            Trade
          </Button>
        </StyledTooltip>
        <StyledTooltip
          disableFocusListener
          title='Statistics on MurAll'
          arrow
          enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
        >
          <Button
            className={classes.topNavButtons}
            component={Link}
            to={'/stats'}
          >
            stats
          </Button>
        </StyledTooltip>
      </ButtonGroup>
      <div
        className={classes.flexColumn}
        style={{
          padding: '12px',
          height: '100%'
        }}
      >
        {_.isNull(props.account) || _.isUndefined(props.account) ? (
          <ConnectWalletButton
            onConnectWalletClicked={props.onConnectWalletClicked}
            style={{
              flex: 1
            }}
          />
        ) : (
          <AccountButton
            onClick={props.onAccountButtonClicked}
            address={props.account}
          />
        )}
        {!(_.isNull(props.account) || _.isUndefined(props.account)) && (
          <PaintButton
            onClick={props.onPaintTokenButtonClicked}
            paintBalance={props.paintBalance}
          />
        )}
      </div>
    </React.Fragment>
  )
}
