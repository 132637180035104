import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import StyledDialog from '../../uicomponents/styled_dialog.js'
import Helper_class from '../../libs/helpers.js'
import Link from '@material-ui/core/Link'
import yellow from '@material-ui/core/colors/yellow'
import red from '@material-ui/core/colors/red'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection.js'

const useStyles = makeStyles(theme => ({
  dialogInformationRoot: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  body: {
    fontSize: 16
  },

  dialogMedia: {
    display: 'block',
    backgroundColor: theme.palette.primary.dark,
    imageRendering: 'pixelated'
  },
  dialogMediaCropped: {
    margin: 'auto',
    overflow: 'auto',
    width: '100%',
    height: 'auto'
  },
  footer: {
    position: 'absolute',
    width: '100%',
    minHeight: '1px',
    bottom: '0',
    left: '0',
    overflow: 'hidden'
  },

  dialogContent: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitleFlex: {
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto'
    // fontWeight: 100,
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'space-between'
  },
  infoRowWrapper: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningRed: {
    color: red[700]
  },
  verticalDivider: {
    borderColor: theme.palette.divider,
    borderTop: 0,
    borderLeft: 'thin',
    borderStyle: 'solid',
    borderBottom: 0,
    borderRight: 0,
    alignSelf: 'stretch',
    height: 'auto',
    marginTop: '16px'
  },

  warningBannerRoot: {
    width: '100%',
    borderRadius: 10,
    background: yellow[800],
    padding: '16px'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  warningIcon: {
    height: '48px',
    width: '48px',
    marginRight: '16px'
  },
  fullFlex: {
    flex: 1
  }
}))

export default function BraveBugWarningDialog (props) {
  const classes = useStyles()

  const Helper = new Helper_class()
  const smallScreen = useSmallScreenDetection()

  const constructWarningBanner = () => {
    return (
      <div className={clsx(classes.warningBannerRoot, classes.flexRow)}>
        <img
          src='images/warning_icon.svg'
          alt='MurAll warning icon'
          className={clsx(classes.warningIcon)}
        />

        <div className={clsx(classes.flexColumn)}>
          <Typography
            className={clsx(classes.typography, classes.fullFlex)}
            variant='h6'
            component='p'
          >
            Turn off Brave browser shields when drawing on MurAll!
          </Typography>
          <Typography
            className={clsx(classes.typography, classes.fullFlex)}
            variant='body1'
            component='p'
            color='textSecondary'
            align='justify'
          >
            <p>If you leave the Brave browser Fingerprinting blocking shield on, artefacts (random black pixels) are introduced to
            the image during minting preparations, increasing it's size and affecting how it is displayed
            once minted.</p>
            <p>Go to <b>Settings</b> -> <b>Shields</b> -> <b>Fingerprinting blocking</b> and choose <b>"Disabled"</b></p>
          </Typography>
        </div>
      </div>
    )
  }

  const constructWarningBannerMobile = () => {
    return (
      <div className={clsx(classes.warningBannerRoot, classes.flexColumn)}>
        <div className={clsx(classes.flexRow)} style={{ marginBottom: '8px' }}>
          <img
            src='images/warning_icon.svg'
            alt='MurAll warning icon'
            className={clsx(classes.warningIcon)}
          />
          <Typography
            className={clsx(classes.typography, classes.fullFlex)}
            variant='body1'
            component='p'
            gutterBottom
          >
            Do not use Brave browser for minting on MurAll!
          </Typography>
        </div>
        <Typography
          className={clsx(classes.typography, classes.fullFlex)}
          variant='body2'
          component='p'
          color='textSecondary'
          align='justify'
        >
          Our image editor has some issues with Brave Browser - when preparing
          the image to mint, artefacts (random black pixels) are introduced to
          the image, increasing it's size and affecting how it is displayed once
          minted.
          <br></br>
          <br></br>
          We recommend using Chrome or Firefox to mint your images onto MurAll
          while we investigate the issue.
        </Typography>
      </div>
    )
  }

  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      dialogTitle={'WARNING!!!'}
      dialogContent={() => (
        <React.Fragment>
          {smallScreen
            ? constructWarningBannerMobile()
            : constructWarningBanner()}
        </React.Fragment>
      )}
      positiveButtonTitle={'Ok'}
      onPositiveButtonClick={props.onPositiveButtonClick}
    />
  )
}
