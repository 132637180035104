import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'

import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import InputIcon from '@material-ui/icons/Input'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

export default function ConnectWalletButton (props) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={10}>
      <CardActionArea onClick={props.onConnectWalletClicked}>
        <CardHeader
          avatar={
            <Avatar
              aria-label='recipe'
              className={clsx(classes.avatar, classes.small)}
            >
              <InputIcon iconStyle={classes.small} color='action' />
            </Avatar>
          }
          disableTypography
          title={
            <Typography
              component='h6'
              variant='h6'
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
            >
              Connect Wallet
            </Typography>
          }
        />
      </CardActionArea>
    </Card>
  )
}
