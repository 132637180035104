import config from './../../../config.js'
import Helper_class from './../../../libs/helpers.js'
import Blockchain_Nft_data_mapper_class from './../nft_data_mapper.js'

/**
 * Source for MurAll data
 *
 * @author TheKeiron
 */
class Blockchain_MurAll_data_source_class {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
    this.MurAllStateDisplayInformation = class {
      constructor (tokenId, fullBase64PngString, positionInfo) {
        this.tokenId = tokenId
        this.fullBase64PngString = fullBase64PngString
        this.positionInformation = positionInfo
      }
    }
  }

  setPixels (
    colourIndexData,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData,
    metadata
  ) {
    var _this = this
    return new Promise(function (resolve, reject) {
      config.smartContract.methods
        .setPixels(
          colourIndexData,
          blockchainPixelData,
          blockchainPixelGroupData,
          blockchainPixelGroupIndexData,
          blockchainTransparentPixelGroupData,
          blockchainTransparentPixelGroupIndexData,
          metadata
        )
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (_this.Helper.isTransactionSuccess(receipt)) {
            resolve(receipt.events.Painted)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          console.error(error)
          reject(error)
        })
    })
  }

  getPaintTokenCost () {
    return config.smartContract.methods.getCostPerPixel().call()
  }

  getTotalNumberOfArtists () {
    return config.smartContract.methods.totalArtists().call()
  }

  async calculatePaintTokenCostForPixels (pixels) {
    const costPerPixel = await this.getPaintTokenCost()
    return config.web3.utils
      .toBN(pixels)
      .mul(config.web3.utils.toBN(costPerPixel))
  }
}

export default Blockchain_MurAll_data_source_class
