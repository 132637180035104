import Helper_class from './../../libs/helpers.js'
import config from './../../config.js'
import _ from 'underscore'

import Worker from '../../workers/blockchain/parseNftDataToCanvas.worker.js'

const emptyHex4 = '0000'

/**
 * Mapper for Nft data
 *
 * @author TheKeiron
 */

class Blockchain_Nft_data_mapper_class {
  constructor () {
    this.Helper = new Helper_class()

    this.NftInformation = class {
      constructor (
        tokenId,
        colorIndex,
        pixelData,
        pixelGroups,
        pixelGroupIndexes,
        transparentPixelGroups,
        transparentPixelGroupIndexes,
        croppedBase64PngString,
        fullBase64PngString,
        complete,
        positionInfo,
        artist,
        name,
        number,
        seriesId,
        hasAlpha,
        completionStatus
      ) {
        this.tokenId = tokenId
        this.colorIndex = colorIndex
        this.pixelData = pixelData
        this.pixelGroups = pixelGroups
        this.pixelGroupIndexes = pixelGroupIndexes
        this.transparentPixelGroups = transparentPixelGroups
        this.transparentPixelGroupIndexes = transparentPixelGroupIndexes
        this.croppedBase64PngString = croppedBase64PngString
        this.fullBase64PngString = fullBase64PngString
        this.complete = complete
        this.positionInformation = positionInfo
        this.artist = artist
        this.name = name
        this.number = number
        this.seriesId = seriesId
        this.hasAlpha = hasAlpha
        this.completionStatus = completionStatus
      }
    }
  }

  processNftData (
    tokenId,
    artist,
    colorIndexData,
    pixelData,
    pixelGroups,
    pixelGroupIndexes,
    transparentPixelGroupData,
    transparentPixelGroupIndexData,
    nameMetadata,
    otherMetadata,
    complete,
    completionStatus = ''
  ) {
    var _this = this

    return new Promise(async function (resolve, reject) {
      const offScreenCanvas = _this.Helper.createOffscreenCanvas(
        config.WIDTH,
        config.HEIGHT
      )
      const ctx = offScreenCanvas.getContext('2d')
      _this.disable_canvas_smooth(ctx)
      const name = _this.processNameMetadata(nameMetadata)
      const metadata = _this.processOtherMetadata(otherMetadata)
      const colorIndex = _this.processColorIndexData(colorIndexData)
      const imageData = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)
      const buf = await _this.drawNftDataToCanvasBuffer(
        imageData.data.buffer,
        colorIndexData,
        pixelData,
        pixelGroups,
        pixelGroupIndexes,
        transparentPixelGroupData,
        transparentPixelGroupIndexData,
        metadata.hasAlpha
      )

      const arr = new Uint8ClampedArray(buf)
      let processedImageData
      try {
        processedImageData = new ImageData(
          arr,
          imageData.width,
          imageData.height
        )
      } catch (e) {
        processedImageData = ctx.createImageData(
          imageData.width,
          imageData.height
        )
        processedImageData.data.set(arr)
      }

      ctx.putImageData(processedImageData, 0, 0)
      const base64PngString = offScreenCanvas.toDataURL()

      const positionInfo = _this.Helper.cropImageFromCanvasContext(ctx)
      const croppedBase64PngString = offScreenCanvas.toDataURL()
      resolve(
        new _this.NftInformation(
          tokenId,
          colorIndex,
          pixelData,
          pixelGroups,
          pixelGroupIndexes,
          transparentPixelGroupData,
          transparentPixelGroupIndexData,
          croppedBase64PngString,
          base64PngString,
          complete,
          positionInfo,
          artist,
          name,
          metadata.number,
          metadata.seriesId,
          metadata.hasAlpha,
          completionStatus
        )
      )
    })
  }

  drawNftDataToCanvasBuffer (
    canvasBuffer,
    colorIndexData,
    pixelData,
    pixelGroups,
    pixelGroupIndexes,
    transparentPixelGroupData,
    transparentPixelGroupIndexData,
    hasAlpha,
    colorIndexPreprocessed = false
  ) {
    const encoder = new TextEncoder()
    const isLittleEndian = this.Helper.isLittleEndian()
    return new Promise(function (resolve, reject) {
      const encodedColorIndex = encoder.encode(JSON.stringify(colorIndexData))
      const encodedIndividualPixels = encoder.encode(JSON.stringify(pixelData))
      const encodedPixelGroups = encoder.encode(JSON.stringify(pixelGroups))
      const encodedGroupIndexes = encoder.encode(
        JSON.stringify(pixelGroupIndexes)
      )
      const encodedTransparentPixelGroups = encoder.encode(
        JSON.stringify(transparentPixelGroupData)
      )
      const encodedTransparentGroupIndexes = encoder.encode(
        JSON.stringify(transparentPixelGroupIndexData)
      )

      const worker = new Worker()

      worker.onmessage = function (msg) {
        worker.terminate()
        resolve(msg.data.canvasBuffer)
      }

      const buffers = [
        canvasBuffer,
        encodedColorIndex.buffer,
        encodedIndividualPixels.buffer,
        encodedPixelGroups.buffer,
        encodedGroupIndexes.buffer,
        encodedTransparentPixelGroups.buffer,
        encodedTransparentGroupIndexes.buffer
      ]
      const message = {
        canvasBuffer,
        encodedColorIndex,
        encodedIndividualPixels,
        encodedPixelGroups,
        encodedGroupIndexes,
        encodedTransparentPixelGroups,
        encodedTransparentGroupIndexes,
        isLittleEndian,
        buffers,
        hasAlpha,
        colorIndexPreprocessed
      }

      worker.postMessage(message, buffers)
    })
  }

  processNameMetadata (nameMetadata) {
    return config.web3.utils.hexToAscii(
      config.web3.utils.numberToHex(nameMetadata)
    )
  }

  processOtherMetadata (otherMetadata) {
    const otherMetadataHex = config.web3.utils
      .padLeft(config.web3.utils.numberToHex(otherMetadata), 64)
      .slice(2)

    // first 3 bytes are number
    const number = parseInt('0x' + otherMetadataHex.slice(0, 6))
    // next 3 bytes are seriesId
    const seriesId = parseInt('0x' + otherMetadataHex.slice(6, 12))
    // last bit is alpha channel flag
    const hasAlpha = otherMetadataHex.slice(otherMetadataHex.length - 1) !== '0'

    return {
      number,
      seriesId,
      hasAlpha
    }
  }

  processColorIndexData (colorIndexes, rgbaFormat = false) {
    let currentColourGroup
    const colourList = []

    for (var i = 0, n = colorIndexes.length; i < n; i++) {
      currentColourGroup = config.web3.utils
        .padLeft(config.web3.utils.numberToHex(colorIndexes[i]), 64)
        .slice(2)

      if (i === colorIndexes.length - 1) {
        // we're at the last index, find any empty slots and remove them too
        while (currentColourGroup.slice(0, 4) == emptyHex4) {
          currentColourGroup = currentColourGroup.slice(4)
        }
      }
      for (var j = 0, k = currentColourGroup.length / 4; j < k; j++) {
        const colour = currentColourGroup.slice(4 * j, 4 * (j + 1))
        if (rgbaFormat) {
          colour = this.Helper.rgb565HexToRgba('0x'.concat(colour))
        }
        colourList.push(colour)
      }
    }

    return colourList
  }

  /**
   * removes smoothing, because it look ugly during zoom
   *
   * @param {ctx} ctx
   */
  disable_canvas_smooth (ctx) {
    ctx.webkitImageSmoothingEnabled = false
    ctx.oImageSmoothingEnabled = false
    ctx.msImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false
  }
}

export default Blockchain_Nft_data_mapper_class
