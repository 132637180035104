import React from 'react'
import Helper_class from '../../libs/helpers.js'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import StyledCardButton from '../../uicomponents/styled_card_button.js'
import NftInformationDialog from '../../uicomponents/nft_information_dialog.js'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%', // square
    imageRendering: 'pixelated'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  avatar: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogMedia: {
    backgroundColor: theme.palette.primary.dark,
    imageRendering: 'pixelated'
  },
  dialogMediaInPlace: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%',
    overflow: 'auto',
    display: 'none'
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    height: '100%' /* Full height */,
    margin: 'auto',
    overflow: 'auto'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    left: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px'
  },
  overlayBackground: {
    background: `radial-gradient(circle at top right,
            #21212100,
            #21212199,
            #212121B3)`
  }
}))

const BorderLinearProgress = withStyles(theme => ({
  root: {
    transform: 'translate(0%, 50%)',
    margin: 0,
    padding: 0
  },
  colorPrimary: {
    backgroundColor: '#71297A'
  },
  bar: {
    margin: 0,
    padding: 0,
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3',
    background:
      'radial-gradient(circle at top right, ' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  }
}))(LinearProgress)

export default function NftCard (props) {
  const classes = useStyles()
  const Helper = new Helper_class()
  const [open, setOpen] = React.useState(false)
  const [viewCropped, setViewCropped] = React.useState(true)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleViewCropped = () => {
    setViewCropped(true)
  }
  const handleViewFull = () => {
    setViewCropped(false)
  }

  const constructNftInfoDialog = () => {
    return (
      <NftInformationDialog
        open={open}
        onClose={handleClose}
        showCompletionStatus
        nftInformation={props.nftInformation}
        viewCropped={viewCropped}
        dialogActions={() => (
          <React.Fragment>
            <Button
              size='small'
              color='secondary'
              onClick={handleViewCropped}
              style={{ marginRight: '16px' }}
            >
              View Cropped
            </Button>
            <Button size='small' color='secondary' onClick={handleViewFull}>
              View In Position
            </Button>
          </React.Fragment>
        )}
      />
    )
  }

  const tokenId = props.nftInformation.tokenId
  const croppedBase64Png = props.nftInformation.croppedBase64PngString
  const filled = props.nftInformation.complete
  const saleInformation = props.nftInformation.saleInformation
  const name = props.nftInformation.name
  const number = props.nftInformation.number
  const seriesId = props.nftInformation.seriesId
  const artist = props.nftInformation.artist
  const completionStatus = props.nftInformation.completionStatus

  return (
    <Card className={classes.root} elevation={10}>
      <CardActionArea onClick={handleOpen}>
        <CardHeader
          avatar={
            <Avatar
              aria-label='tokenId'
              src='/images/murall_nft_logo.png'
              className={clsx(classes.avatar)}
            />
          }
          title={
            <Typography
              className={classes.typography}
              noWrap
              variant='h5'
              component='h4'
            >
              #{tokenId}
            </Typography>
          }
          titleTypographyProps={{ align: 'left' }}
          subheaderTypographyProps={{ align: 'left' }}
          style={{ padding: '12px' }}
        />

        <CardMedia
          className={clsx(classes.media)}
          image={croppedBase64Png}
          title={'#' + tokenId + ': ' + name}
          alt={'#' + tokenId + 'has not been filled!'}
        >
          <div className={clsx(classes.overlay, classes.overlayBackground)}>
            <Typography
              className={clsx(classes.typography)}
              gutterBottom
              variant='h5'
              component='p'
              color='textPrimary'
              style={{ paddingTop: '12px', paddingLeft: '12px' }}
            >
              {name}
            </Typography>
            <Typography
              className={clsx(classes.typography)}
              variant='h6'
              component='p'
              color='textSecondary'
              style={{ paddingLeft: '12px' }}
            >
              {Helper.truncate(artist, 10, 10)}
            </Typography>
            <Typography
              className={clsx(classes.typography)}
              variant='subtitle2'
              component='p'
              color='textSecondary'
              gutterBottom
              style={{ paddingLeft: '12px' }}
            >
              #{number} from Series {seriesId}
            </Typography>
            <BorderLinearProgress
              variant='determinate'
              value={completionStatus}
            />
          </div>
        </CardMedia>
      </CardActionArea>
      <CardActions className={clsx(classes.cardActions)} disableSpacing>
        <StyledCardButton buttonTitle='View' onClick={handleOpen} />
        {constructNftInfoDialog()}

        {saleInformation != null ? (
          <StyledCardButton
            buttonTitle='Withdraw sale'
            onClick={() => {
              const saleItemId = saleInformation.saleItemId
              props.onWithdrawButtonClick(saleItemId)
            }}
          />
        ) : (
          <StyledCardButton
            buttonTitle='Sell'
            onClick={() => {
              props.onSellButtonClick({ tokenId })
            }}
          />
        )}

        {!filled && saleInformation == null ? (
          <StyledCardButton
            buttonTitle='Fill'
            value={tokenId}
            onClick={() => {
              props.onFillClick({ tokenId })
            }}
          />
        ) : null}
      </CardActions>
    </Card>
  )
}
