import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import Helper_class from './../../libs/helpers.js'
import Dialog_class from './../../libs/popup.js'
import Get_is_current_network_supported_usecase from './usecase/get_is_current_network_supported_usecase.js'
import Get_MurAll_current_state_usecase from './usecase/get_murall_current_state_usecase.js'

const TRANSPARENT_IMAGE_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
/**
 * manages blockchain read data
 *
 * @author TheKeiron
 */
class Blockchain_read_data {
  constructor () {
    this.Base_layers = new Base_layers_class()
    this.Helper = new Helper_class()
    this.POP = new Dialog_class()
    this.GetIsCurrentNetworkSupportedUsecase = new Get_is_current_network_supported_usecase()
    this.GetMurAllCurrentStateUsecase = new Get_MurAll_current_state_usecase()

    this.set_events()
  }

  set_events () {
    var _this = this

    document.addEventListener(
      'keydown',
      function (event) {
        const code = event.keyCode
        if (
          event.target.type == 'text' ||
          event.target.tagName == 'INPUT' ||
          event.target.type == 'textarea'
        )
          return

        if (code == 123) {
          //save
          _this.read_pixel_data_from_blockchain_to_canvas()
          event.preventDefault()
        }
      },
      false
    )
  }

  async read_pixel_data_from_blockchain_to_canvas () {
    var _this = this

    const supportedResult = await this.GetIsCurrentNetworkSupportedUsecase.execute()

    if (!supportedResult.isSupported) {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message: supportedResult.error,
        error: true
      })
      return
    }

    this.setLoadingVisible(true)

    this.GetMurAllCurrentStateUsecase.execute()
      .then(async function (offScreenCanvas) {
        const canvas = await _this.getMurAllStateCanvas()
        const ctx = canvas.getContext('2d')
        const imageData = offScreenCanvas
          .getContext('2d')
          .getImageData(0, 0, config.WIDTH, config.HEIGHT)
        const buf = imageData.data.buffer
        const arr = new Uint8ClampedArray(buf)
        let processedImageData
        try {
          processedImageData = new ImageData(
            arr,
            imageData.width,
            imageData.height
          )
        } catch (e) {
          processedImageData = ctx.createImageData(
            imageData.width,
            imageData.height
          )
          processedImageData.data.set(arr)
        }

        ctx.putImageData(processedImageData, 0, 0)
        _this.Base_layers.update_layer_image(
          canvas,
          config.blockchainDataLayer.id
        )
        console.timeEnd('readblockchain')

        _this.setLoadingVisible(false)

        _this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'Successfully updated data from MurAll',
          success: true
        })
      })
      .catch(async function (error) {
        _this.setLoadingVisible(false)
        console.error(error)
        _this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'Error fetching live stats of MurAll',
          error: true
        })
      })
  }

  setLoadingVisible (visible) {
    this.Helper.dispatchCustomEvent(
      'drawingContainer',
      'changeLoadingVisibility',
      {
        showLoading: visible
      }
    )
  }

  /**
   * removes smoothing, because it look ugly during zoom
   *
   * @param {ctx} ctx
   */
  disable_canvas_smooth (ctx) {
    ctx.webkitImageSmoothingEnabled = false
    ctx.oImageSmoothingEnabled = false
    ctx.msImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false
  }

  async getMurAllStateCanvas () {
    var canvas = null
    if (
      config.blockchainDataLayer != null &&
      this.Base_layers.get_layer(config.blockchainDataLayer.id) != null
    ) {
      canvas = this.Base_layers.convert_layer_to_canvas(
        config.blockchainDataLayer.id,
        true
      )
    } else {
      // create params for the new layer with transparent
      var params = {
        type: 'image',
        name: 'MurAll blockchain data',
        data: TRANSPARENT_IMAGE_DATA_URL,
        x: 0,
        y: 0,
        width: config.WIDTH,
        height: config.HEIGHT,
        opacity: 100
      }

      //create the new layer from these params
      await this.Base_layers.insert(params, false)

      //grab the newly created layer as a canvas to populate with our blockchain
      canvas = this.Base_layers.convert_layer_to_canvas(null, true)
      config.blockchainDataLayer = config.layer
    }

    return canvas
  }
}

export default Blockchain_read_data
