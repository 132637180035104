import Helper_class from '../../libs/helpers.js'
import TransparentDialog from '../../uicomponents/transparent_dialog.js'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import TermAndConditions from './terms_content.js'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection.js'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
const useStyles = makeStyles(theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  murAllLogo: {
    paddingBottom: '30px',
    alignSelf: 'center',
    height: '80px'
  },
  rememberChoiceOption: {
    position: 'absolute',
    bottom: 0,
    left: 0
  }
}))

export default function LegalDisclaimerDialog (props) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const history = useHistory()
  const smallScreen = useSmallScreenDetection()
  const handleAgreeClick = () => {
    setOpen(false)
    props.onAgreeClicked(rememberChoice)
  }
  const handleDisagreeClick = () => {
    history.push('/faq')
  }

  const [rememberChoice, setRememberChoice] = React.useState(false)

  const handleChange = event => {
    setRememberChoice(event.target.checked)
  }

  return (
    <TransparentDialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      open={open}
      onClose={props.onClose}
      dialogTitle={() => (
        <React.Fragment>
          <img
            src='images/murall_top_logo.svg'
            alt='MurAll logo and title'
            className={classes.murAllLogo}
          />
          <Typography
            className={classes.typography}
            variant={smallScreen ? 'h4' : 'h3'}
            component='p'
          >
            Legal Disclaimer
          </Typography>
        </React.Fragment>
      )}
      dialogFooter={() => (
        <FormControlLabel
          style={{ flex: 1 }}
          control={
            <Checkbox
              checked={rememberChoice}
              onChange={handleChange}
              name='rememberChoice'
            />
          }
          label='Remember my choice'
        />
      )}
      dialogContent={() => <TermAndConditions />}
      positiveButtonTitle={'I agree to the above'}
      onPositiveButtonClick={handleAgreeClick}
      negativeButtonTitle={'Get me out of here'}
      onNegativeButtonClick={handleDisagreeClick}
    />
  )
}
