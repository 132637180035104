import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import Helper_class from './../../libs/helpers.js'
import Dialog_class from './../../libs/popup.js'
import Blockchain_Nft_data_mapper_class from './nft_data_mapper.js'
import Get_is_erc20_transfer_allowed_for_amount_from_address_for_user_account_usecase from './usecase/get_is_erc20_transfer_allowed_for_amount_from_address_for_user_account_usecase.js'
import Get_is_current_network_supported_usecase from './usecase/get_is_current_network_supported_usecase.js'
import Allow_erc20_transfer_from_address_for_user_account_usecase from './usecase/allow_erc20_transfer_from_address_for_user_account_usecase.js'
import Get_wallet_connected_state_usecase from './usecase/get_wallet_connected_state_usecase.js'
import Set_pixels_on_murall_usecase from './usecase/set_pixels_on_murall_usecase.js'
import Get_paint_token_cost_for_pixels_usecase from './usecase/get_paint_token_cost_for_pixels_usecase.js'
import Worker from './../../workers/blockchain/parsePixelDataToBlockchainTransactionData.worker.js'

/**
 * manages blockchain write transactions
 *
 * @author TheKeiron
 */
class Blockchain_write_transaction {
  constructor () {
    this.Base_layers = new Base_layers_class()
    this.Helper = new Helper_class()
    this.POP = new Dialog_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
    this.GetIsErc20TransferAllowedForAmountFromAddressForUserAccountUsecase = new Get_is_erc20_transfer_allowed_for_amount_from_address_for_user_account_usecase()
    this.GetWalletConnectedStateUsecase = new Get_wallet_connected_state_usecase()
    this.GetIsCurrentNetworkSupportedUsecase = new Get_is_current_network_supported_usecase()
    this.AllowErc20TransferAllowedFromAddressForUserAccountUsecase = new Allow_erc20_transfer_from_address_for_user_account_usecase()
    this.SetPixelsOnMurAllUsecase = new Set_pixels_on_murall_usecase()
    this.GetPaintCostForPixelsOnMurAllUsecase = new Get_paint_token_cost_for_pixels_usecase()

    this.NftInformation = class {
      constructor (tokenId, pixelData, pixelGroups, pixelGroupIndexes) {
        this.tokenId = tokenId
        this.pixelData = pixelData
        this.pixelGroups = pixelGroups
        this.pixelGroupIndexes = pixelGroupIndexes
      }
    }

    var _this = this
    this.Worker = new Worker()

    this.Worker.onmessage = async function (msg) {
      const blockchainPixelData = msg.data.blockchainPixelData
      const blockchainPixelGroupData = msg.data.blockchainPixelGroupData
      const blockchainPixelGroupIndexData =
        msg.data.blockchainPixelGroupIndexData
      const blockchainTransparentPixelGroupData =
        msg.data.blockchainTransparentPixelGroupData
      const blockchainTransparentPixelGroupIndexData =
        msg.data.blockchainTransparentPixelGroupIndexData
      const numberOfColors = msg.data.numberOfColors
      const colourIndexData = msg.data.colourIndexData
      const alphaEnabled = msg.data.alphaEnabled
      const alphaChannel = msg.data.alphaChannel
      const numberOfPixels = msg.data.numberOfPixels
      const costInPaint = await _this.GetPaintCostForPixelsOnMurAllUsecase.execute(
        numberOfPixels
      )

      const offScreenCanvas = _this.Helper.createOffscreenCanvas(
        config.WIDTH,
        config.HEIGHT
      )
      const ctx = offScreenCanvas.getContext('2d')
      const img = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)
      const canvasBuffer = await _this.NftDataMapper.drawNftDataToCanvasBuffer(
        img.data.buffer,
        colourIndexData,
        blockchainPixelData,
        blockchainPixelGroupData,
        blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData,
        alphaEnabled
      )

      const arr = new Uint8ClampedArray(canvasBuffer)
      let processedImageData
      try {
        processedImageData = new ImageData(arr, config.WIDTH, config.HEIGHT)
      } catch (e) {
        processedImageData = ctx.createImageData(config.WIDTH, config.HEIGHT)
        processedImageData.data.set(arr)
      }

      ctx.putImageData(processedImageData, 0, 0)
      const fullBase64PngString = offScreenCanvas.toDataURL()
      const positionInfo = _this.Helper.cropImageFromCanvasContext(ctx)
      const croppedBase64PngString = offScreenCanvas.toDataURL()

      _this.Helper.dispatchCustomEvent('drawingContainer', 'writeTransaction', {
        colourIndexData: colourIndexData,
        blockchainPixelData: blockchainPixelData,
        blockchainPixelGroupData: blockchainPixelGroupData,
        blockchainPixelGroupIndexData: blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData: blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData: blockchainTransparentPixelGroupIndexData,
        alphaEnabled: alphaEnabled,
        alphaChannel: alphaChannel,
        numberOfPixels: numberOfPixels,
        costInPaint: costInPaint,
        fullBase64PngString: fullBase64PngString,
        croppedBase64PngString: croppedBase64PngString,
        numberOfColors: numberOfColors
      })
    }

    this.set_events()
  }

  set_events () {
    var _this = this

    document.addEventListener(
      'keydown',
      function (event) {
        const code = event.keyCode
        if (
          event.target.type == 'text' ||
          event.target.tagName == 'INPUT' ||
          event.target.type == 'textarea'
        )
          return

        if (code == 122) {
          //save
          _this.get_pixel_data_from_canvas()
          event.preventDefault()
        }
      },
      false
    )
  }

  async get_pixel_data_from_canvas () {
    const supportedResult = await this.GetIsCurrentNetworkSupportedUsecase.execute()

    if (!supportedResult.isSupported) {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message: supportedResult.error,
        error: true
      })
      return
    }

    const connected = this.GetWalletConnectedStateUsecase.execute()
    if (!connected) {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message: 'Wallet not connected - Please connect wallet!',
        error: true
      })
      return
    }
    console.time('writeblockchain')
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = config.WIDTH
    canvas.height = config.HEIGHT
    this.disable_canvas_smooth(ctx)

    this.Base_layers.convert_layers_to_canvas(ctx, config.layer.id)

    // Get the Canvas image data from the given coordinates and dimensions
    const imageData = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)

    // create array buffers referencing existing buffers from the canvas we just created
    const imageDataBuffer = new Uint32Array(imageData.data.buffer)

    // check endian-ness to ensure we deal with the colour hex correctly
    const isLittleEndian = this.Helper.isLittleEndian()

    // map pixel data to blockchain transaction data

    const buffers = [imageData.data.buffer]
    const message = {
      canvasBuffer: imageData.data.buffer,
      isLittleEndian: isLittleEndian,
      buffers: buffers,
      alphaEnabled: config.layer.alphaEnabled
    }

    this.Worker.postMessage(message, buffers)
  }

  /**
   * removes smoothing, because it look ugly during zoom
   *
   * @param {ctx} ctx
   */
  disable_canvas_smooth (ctx) {
    ctx.webkitImageSmoothingEnabled = false
    ctx.oImageSmoothingEnabled = false
    ctx.msImageSmoothingEnabled = false
    ctx.imageSmoothingEnabled = false
  }
}

export default Blockchain_write_transaction
