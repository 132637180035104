import config from '../../../config.js'
import _ from 'underscore'
import TokenDistributionDataSource_class from '../datasource/token_distribution_data_source.js'

class GetTokenClaimForAddressUsecase {
  constructor () {
    this.TokenDistributionDataSource = new TokenDistributionDataSource_class()
    this.claimDetails = class {
      constructor (address, index, amount, proof, displayAmount) {
        this.address = address
        this.index = index
        this.amount = amount
        this.proof = proof
        this.displayAmount = displayAmount
      }
    }
  }

  async execute (address) {
    var _this = this
    return new Promise(function (resolve, reject) {
      if (address.slice(0, 2) !== '0x') {
        reject(`Address must begin with '0x'`)
        return
      }
      if (address.length < 42) {
        reject('Invalid address: too short')
        return
      }
      if (address.length > 42) {
        reject('Invalid address: too long')
        return
      }

      try {
        const checkSumAddress = config.web3.utils.toChecksumAddress(address)
        if (!config.web3.utils.isAddress(checkSumAddress)) {
          reject('Not a valid ethereum address: ' + address)
          return
        }

        _this.TokenDistributionDataSource.getClaimDetailsForAddress(
          checkSumAddress
        )
          .then(async data => {
            const claimed = await _this.TokenDistributionDataSource.isClaimAtIndexClaimed(
              data.index
            )
            if (!claimed) {
              const claimDetails = new _this.claimDetails(
                checkSumAddress,
                data.index,
                config.web3.utils.toBN(data.amount),
                data.proof,
                config.web3.utils.fromWei(data.amount, 'ether')
              )

              resolve(claimDetails)
            } else {
              reject('Already claimed tokens for ' + checkSumAddress)
            }
          })
          .catch(err => {
            console.error(err)
            reject('No claim available for ' + checkSumAddress)
          })
      } catch (error) {
        reject('Not a valid ethereum address: ' + address)
        return
      }
    })
  }
}
export default GetTokenClaimForAddressUsecase
