import React from 'react'
import LoadingSpinner from './loading_spinner.js'
import Dialog from '@material-ui/core/Dialog'

export default function LoadingDialog (props) {
  return (
    <Dialog
      fullScreen={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={props.open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <LoadingSpinner />
    </Dialog>
  )
}
