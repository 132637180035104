import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'

class Fill_minted_nft_usecase {
  constructor () {
    this.nftDataSource = new Blockchain_nft_data_source_class()
  }

  async execute (
    id,
    colorIndex,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData
  ) {
    return this.nftDataSource.fillExistingTokenWithData(
      id,
      colorIndex,
      blockchainPixelData,
      blockchainPixelGroupData,
      blockchainPixelGroupIndexData,
      blockchainTransparentPixelGroupData,
      blockchainTransparentPixelGroupIndexData
    )
  }
}

export default Fill_minted_nft_usecase
