import config from './../../../config.js'
import IERC20 from './../../../../contracts/IERC20.json'
import Helper_class from './../../../libs/helpers.js'

const async = require('async')

const MAX_UINT = '9999999999999999'
/**
 * Source for ERC 20 token data
 *
 * @author TheKeiron
 */
class Erc20_data_source_class {
  constructor () {
    this.Helper = new Helper_class()
  }

  getBalanceForUserAccount (erc20TokenContractAddress) {
    return this.getBalanceForAddress(erc20TokenContractAddress, config.account)
  }

  getBalanceForAddress (erc20TokenContractAddress, address) {
    return this.getErc20ContractInstance(erc20TokenContractAddress)
      .methods.balanceOf(address)
      .call()
  }

  getTotalSupply (erc20TokenContractAddress) {
    return this.getErc20ContractInstance(erc20TokenContractAddress)
      .methods.totalSupply()
      .call()
  }

  transferAmountToAddress (
    erc20TokenContractAddress,
    recipientAddress,
    amount
  ) {
    return this.getErc20ContractInstance(erc20TokenContractAddress)
      .transfer(recipientAddress, config.web3.utils.toBN(amount))
      .send({ from: config.account })
  }

  async isTokenTransferApproved (erc20TokenContractAddress, spenderAddress) {
    const erc20Instance = this.getErc20ContractInstance(
      erc20TokenContractAddress
    )
    var allowance = await erc20Instance.methods.allowance(config.account, spenderAddress).call()
    return !config.web3.utils.toBN(allowance).eq(config.web3.utils.toBN(0))
  }

  isMaxTokenTransferApproved (erc20TokenContractAddress, spenderAddress) {
    return this.isTokenTransferApprovedForAmount(
      erc20TokenContractAddress,
      spenderAddress,
      this.getMaxAllowance()
    )
  }

  async isTokenTransferApprovedForAmount (
    erc20TokenContractAddress,
    spenderAddress,
    amount
  ) {
    const erc20Instance = this.getErc20ContractInstance(
      erc20TokenContractAddress
    )
    var allowance = await erc20Instance.methods
      .allowance(config.account, spenderAddress)
      .call()

    return config.web3.utils.toBN(allowance).gte(config.web3.utils.toBN(amount))
  }

  approveTokenTransferMax (erc20TokenContractAddress, spenderAddress) {
    const _this = this
    const erc20Instance = this.getErc20ContractInstance(
      erc20TokenContractAddress
    )
    return new Promise(function (resolve, reject) {
      erc20Instance.methods
        .approve(spenderAddress, _this.getMaxAllowance())
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (_this.Helper.isTransactionSuccess(receipt)) {
            resolve(true)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          console.error(error)

          reject(error)
        })
    })
  }

  getErc20ContractInstance (erc20TokenContractAddress) {
    return new config.web3.eth.Contract(IERC20.abi, erc20TokenContractAddress)
  }

  getMaxAllowance () {
    return config.web3.utils.toWei(config.web3.utils.toBN(MAX_UINT), 'ether')
  }
}

export default Erc20_data_source_class
