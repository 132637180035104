import config from './../../../config.js'

const INITIAL_SUPPLY = '22020096000000000000000000000'

class GetPaintTokenInitialSupplyUsecase {
  execute () {
    return config.web3.utils.toBN(INITIAL_SUPPLY)
  }
}

export default GetPaintTokenInitialSupplyUsecase
