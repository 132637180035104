import React from 'react'
import _ from 'underscore'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  dialogContent: {
    // backgroundColor: theme.palette.secondary.dark,
  },
  dialogTitle: {
    // backgroundColor: theme.palette.secondary.dark,
  },
  dialogActions: {
    // backgroundColor: theme.palette.secondary.dark,
  },
  dialogTitleFlex: {
    // backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

export default function TransparentDialog (props) {
  const classes = useStyles()

  return (
    <Dialog
      {...props}
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={'md'}
      PaperProps={{
        style: {
          borderRadius: 10,
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      {props.withCloseButton ? (
        <DialogTitle
          disableTypography={true}
          className={clsx(classes.transparent, classes.dialogTitleFlex)}
        >
          <h2 className={classes.typography}>{props.dialogTitle}</h2>
          <IconButton onClick={props.onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <DialogTitle
          disableTypography={true}
          className={clsx(classes.transparent, classes.dialogTitle)}
        >
          {_.isFunction(props.dialogTitle) ? (
            props.dialogTitle()
          ) : (
            <Typography
              component='h5'
              variant='h5'
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
            >
              {props.dialogTitle}
            </Typography>
          )}
        </DialogTitle>
      )}

      <DialogContent
        className={clsx(classes.transparent, classes.dialogContent)}
      >
        {props.dialogContent()}
      </DialogContent>
      {props.hideButtons ? null : (
        <DialogActions
          className={clsx(classes.transparent, classes.dialogActions)}
        >
          {props.dialogFooter ? props.dialogFooter() : null}
          <Button
            size='large'
            color='primary'
            onClick={props.onPositiveButtonClick}
            variant='contained'
          >
            {props.positiveButtonTitle}
          </Button>
          {props.onNegativeButtonClick === undefined ? null : (
            <Button
              size='large'
              color='primary'
              onClick={props.onNegativeButtonClick}
              variant='outlined'
            >
              {props.negativeButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
