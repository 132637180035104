import { makeStyles } from '@material-ui/core/styles'
import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import LoadingSpinner from './../../uicomponents/loading_spinner.js'
import KenBurnsImage from './bg/Ken_burns_image.js'
import AboutDialog from './about_dialog.js'
import clsx from 'clsx'
import _ from 'underscore'
import Get_MurAll_current_state_usecase from './../../modules/blockchain/usecase/get_murall_current_state_usecase.js'
import GetHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/get_has_agreed_to_legal_disclaimer_usecase'
import SetUserHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/set_user_agreed_to_legal_disclaimer_usecase'
import LegalDisclaimerDialog from '../legal/legal_disclaimer_dialog'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '24px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  outerGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },

  murAllLogo: {
    width: '100%',
    height: '100%'
  },

  buttonRow: {
    position: 'absolute',
    right: 48,
    bottom: '15%',
    left: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row'
  },

  buttonColumn: {
    position: 'absolute',
    right: 72,
    bottom: 72,
    left: 72,
    alignItems: 'stretch',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    display: 'flex',
    flexDirection: 'column'
  },

  buttonStyle: {
    borderRadius: 20,
    flex: '1 0 auto'
  },
  buttonMobile: {
    background:
      'linear-gradient(to right, ' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`,
    '&:hover': {
      background:
        'linear-gradient(to right, ' +
        theme.palette.secondary.main +
        ' 30%, ' +
        theme.palette.secondary.main +
        ')'
    }
  },
  buttonDesktop: {
    background:
      'linear-gradient(to top, ' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`,
    '&:hover': {
      background:
        'linear-gradient(to right, ' +
        theme.palette.secondary.main +
        ' 30%, ' +
        theme.palette.secondary.main +
        ')'
    }
  },

  emptyViewContainer: {
    display: 'inline-block',
    overflow: 'hidden'
  }
}))

const StyledMenu = withStyles({
  paper: {
    // boxShadow: '0px 0px 10px 3px #E91BFAB3'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    {...props}
  />
))

export default function WelcomeLandingPage (props) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [liveStateDataUrl, setLiveStateDataUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const getHasAgreedToLegalDisclaimerUsecase = new GetHasAgreedToLegalDisclaimerUsecase()
  const setUserHasAgreedToLegalDisclaimerUsecase = new SetUserHasAgreedToLegalDisclaimerUsecase()
  const getMurAllCurrentStateUsecase = new Get_MurAll_current_state_usecase()
  const smallScreen = useSmallScreenDetection()

  const [agreedToDisclaimer, setAgreedToDisclaimer] = useState(
    getHasAgreedToLegalDisclaimerUsecase.execute()
  )

  useEffect(() => {
    async function loadCurrentState () {
      if (agreedToDisclaimer) {
        loadCurrentMurAllState()
      }
    }
    loadCurrentState()
    return () => {}
  }, []) // Empty array ensures that effect is only run on mount

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSocialMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSocialMenuClose = () => {
    setAnchorEl(null)
  }
  const agreedToTerms = rememberChoice => {
    setUserHasAgreedToLegalDisclaimerUsecase.execute(true, rememberChoice)
    setAgreedToDisclaimer(true)

    loadCurrentMurAllState()
  }

  const loadCurrentMurAllState = async () => {
    setLoading(true)
    try {
      const offScreenCanvas = await getMurAllCurrentStateUsecase.execute()
      const dataUrl = offScreenCanvas.toDataURL()
      setLiveStateDataUrl(dataUrl)
    } catch (error) {
      setLiveStateDataUrl('images/murall_top_logo_mobile.png')
    }
    setLoading(false)
  }

  return !agreedToDisclaimer ? (
    <LegalDisclaimerDialog onAgreeClicked={agreedToTerms} />
  ) : (
    <div className={clsx(classes.emptyViewContainer, classes.absoluteFill)}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <KenBurnsImage
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            filter: 'saturate(0.5)'
          }}
          image={liveStateDataUrl}
        />
      )}
      <div
        className={clsx(classes.absoluteFill)}
        style={{
          backgroundImage: 'linear-gradient(to left, #00000000 30%, #000000FF)',
          mixBlendMode: 'normal'
        }}
      />
      <img
        style={{
          top: '15%',
          width: '40%',
          height: '50%',
          objectFit: 'contain',
          position: 'absolute'
        }}
        src={'images/EvRev.png'}
      />

      <div
        className={clsx(
          { [classes.buttonRow]: !smallScreen },
          { [classes.buttonColumn]: smallScreen }
        )}
      >
        <ButtonGroup
          className={clsx(
            classes.buttonStyle,
            classes.outerGlow,
            { [classes.buttonDesktop]: !smallScreen },
            { [classes.buttonMobile]: smallScreen }
          )}
          id='navButtons'
          orientation={smallScreen ? 'vertical' : 'horizontal'}
          style={{
            flex: 1,
            justifyContent: smallScreen ? 'stretch' : 'space-evenly',
            alignItems: smallScreen ? 'stretch' : 'center',
            alignContent: smallScreen ? 'stretch' : 'center'
          }}
          size={smallScreen ? 'medium' : 'large'}
          color='secondary'
          variant='contained'
          aria-label='large outlined primary button group'
        >
          <Button
            className={clsx(
              classes.buttonStyle,
              { [classes.buttonDesktop]: !smallScreen },
              { [classes.buttonMobile]: smallScreen }
            )}
            onClick={handleOpen}
          >
            about
          </Button>
          <Button
            className={clsx(
              classes.buttonStyle,
              { [classes.buttonDesktop]: !smallScreen },
              { [classes.buttonMobile]: smallScreen }
            )}
            component={'a'}
            target='_blank'
            href={'docs/LitePaper.pdf'}
          >
            LitePaper
          </Button>

          <Button
            className={clsx(
              classes.buttonStyle,
              { [classes.buttonDesktop]: !smallScreen },
              { [classes.buttonMobile]: smallScreen }
            )}
            onClick={handleSocialMenuClick}
          >
            social
          </Button>

          <Button
            className={clsx(
              classes.buttonStyle,
              { [classes.buttonDesktop]: !smallScreen },
              { [classes.buttonMobile]: smallScreen }
            )}
            component={Link}
            to={'/faq'}
          >
            FAQ
          </Button>
        </ButtonGroup>
        <AboutDialog open={open} onClose={handleClose} />

        <StyledMenu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleSocialMenuClose}
        >
          <MenuItem
            component={'a'}
            target='_blank'
            href={'https://twitter.com/MurAll_art'}
          >
            <ListItemIcon>
              <Icon>
                <img src='images/logo_twitter.svg' alt='Twitter' />
              </Icon>
            </ListItemIcon>
            <ListItemText primary='Twitter' />
          </MenuItem>
          <MenuItem
            component={'a'}
            target='_blank'
            href={'https://discord.gg/vtRGyzeFhe'}
          >
            <ListItemIcon>
              <Icon>
                <img alt='discord' src='images/logo_discord.svg' />
              </Icon>
            </ListItemIcon>
            <ListItemText primary='Discord' />
          </MenuItem>
          <MenuItem
            component={'a'}
            target='_blank'
            href={'https://murall.medium.com'}
          >
            <ListItemIcon>
              <Icon>
                <img alt='medium' src='images/logo_medium.svg' />
              </Icon>
            </ListItemIcon>
            <ListItemText primary='Medium' />
          </MenuItem>
          <MenuItem
            component={'a'}
            target='_blank'
            href={'https://t.me/MurALLart'}
          >
            <ListItemIcon>
              <Icon>
                <img alt='telegram' src='images/logo_tg.svg' />
              </Icon>
            </ListItemIcon>
            <ListItemText primary='Telegram' />
          </MenuItem>
          <MenuItem
            component={'a'}
            target='_blank'
            href={'https://www.instagram.com/murall.art/'}
          >
            <ListItemIcon>
              <Icon>
                <img alt='telegram' src='images/logo_instagram.svg' />
              </Icon>
            </ListItemIcon>
            <ListItemText primary='Instagram' />
          </MenuItem>
          <MenuItem
            component={'a'}
            target='_blank'
            href={'https://www.youtube.com/channel/UCrEFkjpHY5YojV4px7kGcZQ'}
          >
            <ListItemIcon>
              <Icon>
                <img alt='telegram' src='images/logo_youtube.svg' />
              </Icon>
            </ListItemIcon>
            <ListItemText primary='YouTube' />
          </MenuItem>
        </StyledMenu>
      </div>
    </div>
  )
}
