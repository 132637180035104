import { makeStyles } from '@material-ui/core/styles'
import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import Typography from '@material-ui/core/Typography'
import StyledDialog from '../../uicomponents/styled_dialog.js'
import clsx from 'clsx'
import _ from 'underscore'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '24px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  outerGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  paintLogo: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  paintInfoBg: {
    background:
      'radial-gradient(circle at top right, ' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  },
  colouredLineBorder: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 10
  }
}))

export default function PaintSummaryDialogMobile (props) {
  const classes = useStyles()

  return (
    <StyledDialog
      {...props}
      withCloseButton={true}
      dialogTitle={'Your PAINT summary'}
      dialogContent={() => (
        <div className={clsx(classes.flexColumn)}>
          <div
            className={clsx(
              classes.flexColumn,
              classes.outerGlow,
              classes.paintInfoBg
            )}
            style={{
              marginBottom: '48px',
              marginTop: '12px',
              padding: '24px',
              borderRadius: 10
            }}
          >
            <img
              src='images/paint_logo.png'
              alt='Paint logo'
              className={classes.paintLogo}
              style={{
                marginBottom: '12px'
              }}
            />
            <div
              className={clsx(classes.flexColumn)}
              style={{
                marginBottom: '12px'
              }}
            >
              <Typography
                component='h6'
                variant='h6'
                noWrap
                className={clsx(classes.typographyLight)}
                color='textPrimary'
              >
                Your balance:
              </Typography>
              <Typography
                component='h5'
                variant='h5'
                noWrap
                className={clsx(classes.typographyLight)}
                color='textPrimary'
              >
                {`${props.paintBalance} PAINT`}
              </Typography>
            </div>
            <div className={clsx(classes.flexColumn)}>
              <Typography
                component='h6'
                variant='h6'
                noWrap
                className={clsx(classes.typographyLight)}
                color='textPrimary'
              >
                Remaining global supply:
              </Typography>
              <Typography
                component='h5'
                variant='h5'
                noWrap
                className={clsx(classes.typographyLight)}
                color='textPrimary'
              >
                {`${props.paintTotalSupply} PAINT (${props.paintTotalSupplyPercentage}%)`}
              </Typography>
            </div>
          </div>
          <div
            className={clsx(classes.flexColumn, classes.colouredLineBorder)}
            style={{
              marginBottom: '24px',
              padding: '24px'
            }}
          >
            <div className={clsx(classes.flexColumn)}>
              <Typography
                component='h5'
                variant='h5'
                noWrap
                align='center'
                className={clsx(classes.typographyLight)}
                color='textPrimary'
                style={{
                  marginBottom: '12px'
                }}
              >
                Claim your PAINT tokens
              </Typography>
              <Typography
                component='body1'
                variant='body1'
                align='center'
                className={clsx(classes.typography)}
                color='textPrimary'
                style={{
                  marginBottom: '12px'
                }}
              >
                Check to see if your address has any PAINT claim available
              </Typography>
            </div>
            <Button
              size='large'
              color='secondary'
              onClick={props.onCheckClicked}
              variant='contained'
            >
              Check
            </Button>
          </div>
        </div>
      )}
      hideButtons
    />
  )
}
