'use strict'

import React from 'react'
import config from '../../config'
import ViewNfts from '../nfts/view_nfts'
import ViewHistory from '../history/view_murall_history'
import WelcomeLandingPage from '../welcome/welcome_landing_page'
import TermAndConditionsView from '../legal/terms_and_conditions_view'
import FaqPage from '../welcome/faq_page'
import LiveView from '../live/live_view'
import NftDisplay from '../gallery/nft_display'
import PaintSummaryDialogDesktop from '../paint/paint_summary_dialog_desktop'
import PaintSummaryDialogMobile from '../paint/paint_summary_dialog_mobile'
import ClaimTokensDialog from '../paint/claim_tokens_dialog'
import DrawingClient from '../draw/drawingClient'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { Route, Switch, Redirect } from 'react-router-dom'
import NavButtonsDesktop from './top_nav_buttons_desktop'
import NavButtonsMobile from './top_nav_buttons_mobile'
import clsx from 'clsx'
import Get_erc20_balance_for_user_account_usecase from '../../modules/blockchain/usecase/get_erc20_balance_for_user_account_usecase'
import ClaimPaintForClaimDataUsecase from '../../modules/blockchain/usecase/claim_PAINT_for_claim_data_usecase'
import GetPaintTokenRemainingSupplyUsecase from '../../modules/blockchain/usecase/get_paint_token_remaining_supply_usecase'
import Layer_raster_class from '../../modules/layer/raster'
import Connect_wallet_usecase from '../../modules/blockchain/usecase/connect_wallet_usecase'
import Get_wallet_connected_state_usecase from '../../modules/blockchain/usecase/get_wallet_connected_state_usecase'
import TradeLandingPage from '../trade/tradeLandingPage.js'
import StatsLandingPage from '../stats/statsLandingPage.js'
import PaintedEventSnackMessage from '../../uicomponents/painted_event_notification'
import IndeterminateLoadingSnackMessage from '../../uicomponents/indeterminate_loading_notification'
import LoadingDialog from '../../uicomponents/loading_dialog'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'

const TOP_NAV_HEIGHT = 132
const BOTTOM_BORDER_HEIGHT = 10

const Z_INDEX_ALWAYS_ON_TOP = 999 // still below material UI stuf, which starts at z index 1000

const useStyles = makeStyles(theme => ({
  root: {
    height: TOP_NAV_HEIGHT,
    width: '100%',
    position: 'fixed',
    zIndex: Z_INDEX_ALWAYS_ON_TOP
  },
  topNavContainer: {
    height: TOP_NAV_HEIGHT - BOTTOM_BORDER_HEIGHT,
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center'
  },
  topNavButtons: {
    color: theme.palette.secondary.main,
    flex: '1 0 auto',
    alignSelf: 'center'
  },
  bottomContentContainer: {
    position: 'fixed',
    top: TOP_NAV_HEIGHT,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'auto'
  }
}))

const TopNavBottomBorder = styled.div`
  height: 10px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(126, 10, 229, 1) 35%,
    rgba(233, 27, 250, 1) 50%,
    rgba(126, 10, 229, 1) 65%
  );
  background-size: 400% 400%;
  position: absolute;
  bottom: 0;
  z-index: 999;
  /* box-shadow: 0px 0px 10px 3px #7e0ae5; */
  /* in order: x offset, y offset, blur size, spread size, color */
  /* blur size and spread size are optional (they default to 0) */
  animation: gradient 10s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const StyledMenu = withStyles({
  paper: {
    boxShadow: '0px 0px 10px 3px #E91BFAB3'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

export default function NavButtons (props) {
  const getErc20BalanceForUserAccountUsecase = new Get_erc20_balance_for_user_account_usecase()
  const claimPaintForClaimDataUsecase = new ClaimPaintForClaimDataUsecase()
  const connectWalletUseCase = new Connect_wallet_usecase()
  const getWalletConnectedStateUseCase = new Get_wallet_connected_state_usecase()
  const getPaintTokenRemainingSupplyUsecase = new GetPaintTokenRemainingSupplyUsecase()
  const rasterizeLayer = new Layer_raster_class()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()

  const [anchorEl, setAnchorEl] = useState(null)
  const [paintBalance, setPaintBalance] = useState(0)
  const [paintTotalSupply, setPaintTotalSupply] = useState(0)

  const [paintTotalSupplyPercentage, setPaintTotalSupplyPercentage] = useState(
    0
  )

  const [paintDialogOpen, setPaintDialogOpen] = useState(false)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [claimDialogOpen, setClaimDialogOpen] = useState(false)
  const [account, setAccount] = useState(null)
  const [persistentLoadingKey, setPersistentLoadingKey] = useState(null)
  const [claimAddress, setClaimAddress] = useState(null)

  const handleAccountMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleAccountMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    function onAccountsChanged (e) {
      setAccount(e.detail.account)
      setClaimAddress(e.detail.account)
      updatePaintBalance()
      updatePaintTotalSupply()
    }
    checkWalletConnectedStatusAndUpdateBalance()
    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('accountsChanged', onAccountsChanged.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'accountsChanged',
          onAccountsChanged.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    function onNetworkChanged () {
      checkWalletConnectedStatusAndUpdateBalance()
    }

    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('networkChanged', onNetworkChanged.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'networkChanged',
          onNetworkChanged.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount
  useEffect(() => {
    function onUpdatePaintBalanceEventReceived () {
      checkWalletConnectedStatusAndUpdateBalance()
    }

    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener(
        'updatePaintBalance',
        onUpdatePaintBalanceEventReceived.bind(this),
        false
      )

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'updatePaintBalance',
          onUpdatePaintBalanceEventReceived.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    function onPaintedEventReceived (e) {
      const artwork = e.detail.artwork
      updatePaintTotalSupply()

      enqueueSnackbar(null, {
        content: key => <PaintedEventSnackMessage id={key} artwork={artwork} />
      })
    }

    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('painted', onPaintedEventReceived.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'painted',
          onPaintedEventReceived.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  const dismissLoadingNotification = async () => {
    const currentLoadingId = await getCurrentHookValue(setPersistentLoadingKey)
    if (currentLoadingId != null) {
      closeSnackbar(currentLoadingId)
      setPersistentLoadingKey(null)
    }
  }

  useEffect(() => {
    function onNotifyEventReceived (e) {
      var message = e.detail.message
      if (e.detail.dismissLoading) {
        dismissLoadingNotification()
      } else if (e.detail.loading) {
        dismissLoadingNotification()
        enqueueSnackbar(null, {
          persist: true,
          content: key => {
            setPersistentLoadingKey(key)
            return (
              <IndeterminateLoadingSnackMessage
                id={key}
                error={e.detail.error}
                success={e.detail.success}
                info={e.detail.info}
                message={e.detail.message}
                showClose={e.detail.showClose}
              />
            )
          }
        })
      } else if (e.detail.success) {
        showSuccessSnackbar(message)
      } else if (e.detail.error) {
        showErrorSnackbar(message, e.detail.showRasterizeOption)
      } else if (e.detail.warning) {
        showWarningSnackbar(message)
      } else {
        showInfoSnackbar(message)
      }
    }

    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('notify', onNotifyEventReceived.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener('notify', onNotifyEventReceived.bind(this), false)
  }, []) // Empty array ensures that effect is only run on mount

  const checkWalletConnectedStatusAndUpdateBalance = () => {
    const connected = getWalletConnectedStateUseCase.execute()
    if (connected) {
      setAccount(config.account)
      setClaimAddress(config.account)
      updatePaintBalance()
      updatePaintTotalSupply()
    }
  }

  const updatePaintBalance = async () => {
    var balance = await getErc20BalanceForUserAccountUsecase.execute(
      config.paintTokenAddress
    )
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    setPaintBalance(
      formatter.format(config.web3.utils.fromWei(balance, 'ether'))
    )
  }

  const updatePaintTotalSupply = async () => {
    const remainingSupplyResult = await getPaintTokenRemainingSupplyUsecase.execute()

    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    setPaintTotalSupply(
      formatter.format(
        config.web3.utils.fromWei(
          remainingSupplyResult.remainingSupply,
          'ether'
        )
      )
    )

    setPaintTotalSupplyPercentage(remainingSupplyResult.percentage)
  }

  const onClaimClicked = claimData => {
    setLoadingDialogOpen(true)
    claimPaintForClaimDataUsecase
      .execute(
        claimData.address,
        claimData.index,
        claimData.amount,
        claimData.proof
      )
      .then(() => {
        updatePaintBalance()
        setLoadingDialogOpen(false)
        enqueueSnackbar(
          'Claimed ' +
            claimData.displayAmount +
            ' PAINT for ' +
            claimData.address,
          {
            variant: 'success'
          }
        )
      })
      .catch(err => {
        console.error(err)
        setLoadingDialogOpen(false)
        enqueueSnackbar('Claim failed - see console for details', {
          variant: 'error'
        })
      })
  }

  const onConnectWalletClicked = () => {
    connectWallet()
  }
  const onPaintTokenButtonClicked = () => {
    setPaintDialogOpen(true)
  }

  const connectWallet = async () => {
    const accountResponse = await connectWalletUseCase.execute()
    if (accountResponse.error == null) {
      enqueueSnackbar('Connected to ' + accountResponse.account, {
        variant: 'success'
      })

      setAccount(accountResponse.account)

      updatePaintBalance()
    } else {
      enqueueSnackbar('Failed to connect wallet', {
        variant: 'error'
      })
      console.error(accountResponse.error)
    }
  }

  const showSuccessSnackbar = message => {
    enqueueSnackbar(message, {
      variant: 'success'
    })
  }

  const showInfoSnackbar = message => {
    enqueueSnackbar(message, {
      variant: 'info'
    })
  }

  const showWarningSnackbar = message => {
    enqueueSnackbar(message, {
      variant: 'warning'
    })
  }

  const showErrorSnackbar = (message, showRasterizeOption = false) => {
    if (showRasterizeOption) {
      const action = key => (
        <React.Fragment>
          <Button
            onClick={() => {
              rasterizeLayer.raster()
              closeSnackbar(key)
            }}
          >
            Rasterize
          </Button>
          <IconButton
            onClick={() => {
              closeSnackbar(key)
            }}
            aria-label='dismiss'
          >
            <ClearIcon />
          </IconButton>
        </React.Fragment>
      )

      enqueueSnackbar(message, {
        variant: 'error',
        action
      })
    } else {
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    const action = key => (
      <React.Fragment>
        <Button
          onClick={() => {
            rasterizeLayer.raster()
          }}
        >
          Rasterize
        </Button>
        <IconButton
          onClick={() => {
            closeSnackbar(key)
          }}
          aria-label='dismiss'
        >
          <ClearIcon />
        </IconButton>
      </React.Fragment>
    )

    enqueueSnackbar(message, {
      variant: 'error',
      action
    })
  }

  /* Workaround for functional component not being able to get current hook value in interval */
  async function getCurrentHookValue (setHookFunction) {
    return new Promise(resolve => {
      setHookFunction(prev => {
        resolve(prev)
        return prev
      })
    })
  }

  return (
    <div>
      <div className={clsx(classes.root)}>
        <div id='container' className={classes.topNavContainer}>
          {smallScreen ? (
            <NavButtonsMobile
              account={account}
              paintBalance={paintBalance}
              onPaintTokenButtonClicked={onPaintTokenButtonClicked}
              onConnectWalletClicked={onConnectWalletClicked}
              onAccountButtonClicked={handleAccountMenuClick}
            />
          ) : (
            <NavButtonsDesktop
              account={account}
              paintBalance={paintBalance}
              onPaintTokenButtonClicked={onPaintTokenButtonClicked}
              onConnectWalletClicked={onConnectWalletClicked}
              onAccountButtonClicked={handleAccountMenuClick}
            />
          )}
        </div>
        <TopNavBottomBorder />
      </div>
      <div className={clsx(classes.bottomContentContainer)}>
        <Switch
          style={{
            zIndex: 1
          }}
        >
          <Route path='/draw' component={DrawingClient} exact />
          <Route path='/trade' exact>
            <TradeLandingPage
              title={'Coming soon!'}
              description={'Watch this space'}
            />
          </Route>
          <Route path='/nfts' component={ViewNfts} exact />
          <Route path='/history' component={ViewHistory} exact />
          <Route path='/gallery' component={NftDisplay} exact />
          <Route path='/live' component={LiveView} exact />
          <Route path='/faq' component={FaqPage} exact />
          <Route path='/terms' component={TermAndConditionsView} exact />
          <Route path='/stats' component={StatsLandingPage} exact />
          <Route path='/home' component={WelcomeLandingPage} exact />
          <Redirect to='/home' />
        </Switch>
      </div>
      {smallScreen ? (
        <PaintSummaryDialogMobile
          open={paintDialogOpen}
          onClose={() => setPaintDialogOpen(false)}
          paintBalance={paintBalance}
          paintTotalSupply={paintTotalSupply}
          paintTotalSupplyPercentage={paintTotalSupplyPercentage}
          onCheckClicked={() => {
            setPaintDialogOpen(false)
            setClaimAddress(config.account)
            setClaimDialogOpen(true)
          }}
        />
      ) : (
        <PaintSummaryDialogDesktop
          open={paintDialogOpen}
          onClose={() => setPaintDialogOpen(false)}
          paintBalance={paintBalance}
          paintTotalSupply={paintTotalSupply}
          paintTotalSupplyPercentage={paintTotalSupplyPercentage}
          onCheckClicked={() => {
            setPaintDialogOpen(false)
            setClaimAddress(config.account)
            setClaimDialogOpen(true)
          }}
        />
      )}

      <ClaimTokensDialog
        open={claimDialogOpen}
        onClose={() => setClaimDialogOpen(false)}
        onAddressChanged={address => setClaimAddress(address)}
        claimAddress={claimAddress}
        paintBalance={paintBalance}
        paintTotalSupply={paintTotalSupply}
        onClaimClicked={onClaimClicked}
      />
      <StyledMenu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAccountMenuClose}
      >
        <MenuItem component={Link} to={'/nfts'}>
          <ListItemText primary='My Account' />
        </MenuItem>
        <MenuItem component={Link} to={'/gallery'}>
          <ListItemText primary='My Display Gallery' />
        </MenuItem>
      </StyledMenu>
      <LoadingDialog open={loadingDialogOpen} />
    </div>
  )
}
