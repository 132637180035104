import config from '../../../config.js'
import Helper_class from '../../../libs/helpers.js'
import Blockchain_Nft_data_mapper_class from '../nft_data_mapper.js'

const async = require('async')
/**
 * Source for MurAll state data
 *
 * @author TheKeiron
 */
class Blockchain_MurAll_state_data_source_class {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
  }

  async getTotalStateChanges () {
    return config.nftContract.methods.totalSupply().call()
  }

  async getCurrentMurAllStateData () {
    const totalStateChanges = await this.getTotalStateChanges()
    return this.getMurAllStateDataAtId(totalStateChanges - 1)
  }

  async getMurAllStateDataAtId (tokenId) {
    var _this = this
    const offScreenCanvas = _this.Helper.createOffscreenCanvas(
      config.WIDTH,
      config.HEIGHT
    )
    if (tokenId != -1) {
      const numberOfTokensNeeded = tokenId + 1 // tokens are zero indexed e.g. for token id 2, we need 3 states (0,1,2)
      const tokenIds = [...Array(parseInt(numberOfTokensNeeded)).keys()] // if token id 2, the keys for an array of size 3 would be [0,1,2]
      const ctx = offScreenCanvas.getContext('2d')

      const bnTokenIds = tokenIds.map(id => config.web3.utils.toBN(id))

      const events = await config.smartContract.getPastEvents('Painted', {
        filter: { tokenId: bnTokenIds },
        fromBlock: 'earliest'
      })

      if (events.length > 0) {
        await async.eachSeries(events, async function (event, callback) {
          const img = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)
          const tokenInfo = event.returnValues
          const metadata = _this.NftDataMapper.processOtherMetadata(
            tokenInfo.metadata[1]
          )
          const canvasBuffer = await _this.NftDataMapper.drawNftDataToCanvasBuffer(
            img.data.buffer,
            tokenInfo.colorIndex,
            tokenInfo.pixelData,
            tokenInfo.pixelGroups,
            tokenInfo.pixelGroupIndexes,
            tokenInfo.transparentPixelGroups,
            tokenInfo.transparentPixelGroupIndexes,
            metadata.hasAlpha
          )

          const arr = new Uint8ClampedArray(canvasBuffer)
          let processedImageData
          try {
            processedImageData = new ImageData(arr, config.WIDTH, config.HEIGHT)
          } catch (e) {
            processedImageData = ctx.createImageData(
              config.WIDTH,
              config.HEIGHT
            )
            processedImageData.data.set(arr)
          }

          ctx.putImageData(processedImageData, 0, 0)
          callback()
        })
      }
    }

    return offScreenCanvas
  }
}

export default Blockchain_MurAll_state_data_source_class
