import Helper_class from '../../libs/helpers.js'
import TransparentDialog from '../../uicomponents/transparent_dialog.js'
import StyledDialog from '../../uicomponents/styled_dialog.js'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'

const useStyles = makeStyles(theme => ({
  typography: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 300
  }
}))

export default function AboutDialog (props) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()
  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      withCloseButton={true}
      hideButtons={true}
      dialogTitle={''}
      dialogContent={() => (
        <Grid
          container
          spacing={2}
          direction='column'
          justify='space-between'
          alignItems='stretch'
        >
          <Grid item xs={12}>
            <img
              style={{
                width: '100%',
                objectFit: 'contain'
              }}
              src={'images/MurAll_about_header.png'}
            />
            <Typography
              className={classes.typography}
              variant='h5'
              component='p'
              gutterBottom
            >
              What is MurAll?
            </Typography>
            <Typography
              className={classes.typography}
              variant='body1'
              align='justify'
              component='p'
              gutterBottom
            >
              MurAll is a collaborative canvas allowing artists worldwide to
              paint onto an ever evolving piece of art. This is a first of its
              kind project made possible by the use of blockchain technology.
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={smallScreen ? 'column' : 'row'}
            xs={12}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <img
                style={{
                  width: '100%',
                  objectFit: 'contain'
                }}
                src={'images/paint_icon_triangle.png'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography
                className={classes.typography}
                variant='h5'
                component='p'
                gutterBottom
              >
                Introducing PAINT
              </Typography>
              <Typography
                className={classes.typography}
                variant='body1'
                align='justify'
                component='p'
              >
                Powered by the Ethereum network, MurAll requires a digital token
                called PAINT to enable artists to draw onto the canvas. Each
                PAINT token can draw 2 pixels and just like real life paint, it
                can only be used to draw once. If is has been used it can’t be
                taken off of the wall and reused! With only a finite amount of
                PAINT available globally (there is only enough PAINT to cover
                the canvas 21,000 times), it is a commodity which encourages
                artists to produce work they are passionate about as the more
                PAINT that is used, the less is available.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={smallScreen ? 'column' : 'row'}
            xs={12}
          >
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography
                className={classes.typography}
                variant='h5'
                component='p'
                gutterBottom
              >
                Your NFT
              </Typography>
              <Typography
                className={classes.typography}
                variant='body1'
                align='justify'
                component='p'
              >
                Once an artist has created their piece and “minted” it on the
                MurAll canvas, it is immortalised forever on the blockchain. The
                artist receives an NFT (Non Fungible Token) of their drawing; in
                contrast to traditional tokens, NFTs are one of a kind, ensuring
                that the artwork is a UNIQUE ORIGINAL which has value and can be
                kept or sold whenever they wish - just like real life paintings.
                Unlike most existing technologies, the artwork itself is
                contained within the token and isnt just a certificate.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <img
                style={{
                  width: '100%',
                  objectFit: 'contain'
                }}
                src={'images/MurAll_about_nft.png'}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  )
}
