/*
 * miniPaint - https://github.com/viliusle/miniPaint
 * author: Vilius L.
 */

import config from './../../config.js'
import Helper_class from './../../libs/helpers.js'
import '../../../../node_modules/spectrum-colorpicker/spectrum.css'
import spectrum from '../../../../node_modules/spectrum-colorpicker/spectrum.js'

var Helper = new Helper_class()

var template = ``

/**
 * GUI class responsible for rendering colors block on right sidebar
 */
class GUI_colors_class {
  render_main_colors () {
    document.getElementById('toggle_colors').innerHTML = template
    this.render_colors()
    this.set_events()
  }

  set_events () {
    var _this = this

    document
      .getElementById('main_color')
      .addEventListener('change', function (e) {
        _this.set_color(this)
        var value = this.value
        _this.change_color(value)
        _this.render_colors()
      })
  }

  /**
   * renders current color to all color fields
   */
  render_colors () {
    document.getElementById('main_color').value = config.COLOR
  }

  set_color (object) {
    if (object.id == 'main_color') this.change_color(object.value)
    else this.change_color(Helper.rgb2hex_all(object.style.backgroundColor))

    document.getElementById('main_color').value = config.COLOR

    //also set alpha to max
    if (config.ALPHA == 0) {
      this.change_alpha(255)
    }

    this.render_colors()
  }

  set_color_manual (event) {
    var object = event.target
    if (object.value.length == 6 && object.value[0] != '#') {
      this.change_color('#' + object.value)
      this.render_colors()
    }
    if (object.value.length == 7) {
      this.change_color(object.value)
      this.render_colors()
    } else if (object.value.length > 7) {
      object.value = config.COLOR
    }
  }

  /**
   * change global color value
   *
   * @param {type} hex can be null, but r/g/b/ must be provided then. Can be #ff0000 or ff0000
   * @param {type} r optional
   * @param {type} g optional
   * @param {type} b optional
   * @returns {undefined}
   */
  change_color (hex, r, g, b) {
    if (hex != '' && hex != null) {
      if (hex[0] != '#') {
        hex = '#' + hex
      }
      config.COLOR = hex
    } else if (r != undefined && g != undefined && b != undefined) {
      config.COLOR = Helper.rgbToHex(r, g, b)
    } else {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message: 'Error: wrong color.',
        error: true
      })
      return
    }

    $('#main_color').spectrum('set', config.COLOR)
  }

  /**
   * change global alpha value
   *
   * @param {int} value
   */
  change_alpha (value) {
    // value = Math.ceil(value);
    // config.ALPHA = parseInt(value);
    // document.getElementById('rgb_a').value = config.ALPHA;
  }
}

export default GUI_colors_class
