'use strict'

import _ from 'underscore'
import * as THREE from 'three'
import gsap from 'gsap'
import ImageViewerCanvas from '../../uicomponents/image_viewer_canvas.js'

class NftViewerCanvas extends ImageViewerCanvas {
  constructor (props) {
    super(props)
    this.isDragging = false
    this.mousePrevPos = new THREE.Vector2()
    this.mouseNextPos = new THREE.Vector2()
    this.overrideAddingToScene = true
  }

  async initialiseThreeJSCanvas () {
    await super.initialiseThreeJSCanvas()

    // mouse interaction listener
    this.listenToMouseEvents()
    await this.updateOrder()
  }

  componentDidUpdate (prevProps) {
    super.componentDidUpdate(prevProps)

    if (prevProps.tokenOrder !== this.props.tokenOrder) {
      super.clearScene()
      this.updateOrder()
    }

    if (
      this.props.currentSelectedState != prevProps.currentSelectedState &&
      !_.isNull(this.props.currentSelectedState)
    ) {
      const timeline = gsap.timeline()
      this.animateLayerIn(this.props.currentSelectedState.toString(), timeline)
    }
  }

  async updateOrder () {
    this.setState({ loading: true })
    const planeGroup = new THREE.Group()

    for (var i = 0; i < this.props.tokenOrder.length; i++) {
      const plane = this.images.get(this.props.tokenOrder[i].toString()).plane
      plane.material.uniforms.iTime.value = super.ITIME_IMAGE_VISIBLE
      planeGroup.add(plane)
    }
    this.scene.add(planeGroup)

    this.setState({ loading: false })
    this.requestAnimationIfNotRequested()
  }

  listenToMouseEvents () {
    var _this = this

    //collect mouse info
    this.renderer.domElement.addEventListener('mousedown', function (event) {
      event.preventDefault()
      _this.dragStart(event)
    })
    this.renderer.domElement.addEventListener('mousemove', function (event) {
      event.preventDefault()
      _this.dragMove(event)
    })
    this.renderer.domElement.addEventListener('mouseup', function (event) {
      event.preventDefault()
      _this.dragEnd(event)
    })

    // collect touch info
    this.renderer.domElement.addEventListener('touchstart', function (event) {
      event.preventDefault()
      _this.dragStart(event)
    })
    this.renderer.domElement.addEventListener(
      'touchmove',
      function (event) {
        event.preventDefault()
        _this.dragMove(event)
      },
      { passive: false }
    )
    this.renderer.domElement.addEventListener('touchend', function (event) {
      event.preventDefault()
      _this.dragEnd(event)
    })
  }

  dragStart (event) {
    if (!this.isDragging) {
      this.isDragging = true

      const dimens = this.getSizesForRenderer(this.mount)
      this.mousePrevPos.x = event.clientX / dimens.width
      this.mousePrevPos.y = event.clientY / dimens.height

      this.runningAnimations += 1
      this.requestAnimationIfNotRequested()
    }
  }

  dragMove (event) {
    if (this.isDragging) {
      const dimens = this.getSizesForRenderer(this.mount)
      this.mouseNextPos.x = event.clientX / dimens.width
      this.mouseNextPos.y = event.clientY / dimens.height

      const xDiff = this.mouseNextPos.x - this.mousePrevPos.x
      const yDiff = this.mouseNextPos.y - this.mousePrevPos.y
      if (!_.isNull(this.props.currentSelectedState)) {
        const state = this.images.get(
          this.props.currentSelectedState.toString()
        )
        const mesh = state.plane
        const offset = new THREE.Vector2(
          mesh.material.uniforms.offset.value.x + xDiff,
          mesh.material.uniforms.offset.value.y + yDiff
        )
        mesh.material.uniforms.offset.value = offset
      }

      this.mousePrevPos.x = this.mouseNextPos.x
      this.mousePrevPos.y = this.mouseNextPos.y
    }
  }

  dragEnd (event) {
    if (this.isDragging) {
      this.isDragging = false
      this.runningAnimations -= 1
    }
  }
}

export default NftViewerCanvas
