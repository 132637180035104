import React from 'react'
import ErrorMessageView from './error_message_view.js'

export default function NetworkErrorMessageView () {
  return (
    <ErrorMessageView
      title={'Network unsupported'}
      description={'Please select the Ethereum Mainnet network in your wallet'}
    />
  )
}
