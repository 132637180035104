import Helper_class from '../../libs/helpers.js'
import TransparentDialog from '../../uicomponents/transparent_dialog.js'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import TermAndConditions from './terms_content.js'

const useStyles = makeStyles(theme => ({
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  murAllLogo: {
    paddingBottom: '30px',
    alignSelf: 'center',
    height: '80px'
  },
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '24px'
  }
}))

export default function TermAndConditionsView (props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TermAndConditions />
    </div>
  )
}
