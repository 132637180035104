import Blockchain_MurAll_data_source_class from '../datasource/murall_data_source.js'

class Get_paint_token_cost_for_pixels_usecase {
  constructor () {
    this.MurAllDataSource = new Blockchain_MurAll_data_source_class()
  }

  async execute (pixelCount) {
    return this.MurAllDataSource.calculatePaintTokenCostForPixels(pixelCount)
  }
}

export default Get_paint_token_cost_for_pixels_usecase
