import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import Helper_class from '../../libs/helpers.js'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textDecoration: 'none !important'
  },
  cardHeader: {
    padding: '4px',
    margin: 0
  },
  border: {
    borderRadius: 20,
    border: 'solid',
    borderColor: theme.palette.primary.light,
    borderWidth: '1px'
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  avatar: {
    backgroundColor: theme.palette.primary.light
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    textDecoration: 'none !important'
  }
}))

export default function AccountButtonMobile (props) {
  const classes = useStyles()
  const Helper = new Helper_class()
  return (
    <Card
      {...props}
      className={clsx(classes.root, classes.border)}
      elevation={10}
    >
      <CardActionArea>
        <CardHeader
          className={clsx(classes.cardHeader)}
          avatar={
            <Avatar
              aria-label='recipe'
              className={clsx(classes.avatar, classes.small)}
            />
          }
          disableTypography
          title={
            <Typography
              component='h6'
              variant='body1'
              noWrap
              className={clsx(classes.typography)}
              color='textPrimary'
              style={{
                paddingRight: '6px',
                textDecoration: 'none'
              }}
            >
              {Helper.truncate(props.address, 4, 3)}
            </Typography>
          }
        />
      </CardActionArea>
    </Card>
  )
}
