import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import Dialog_class from './../../libs/popup.js'
import Helper_class from './../../libs/helpers.js'

class Tools_restoreAlpha_class {
  constructor () {
    this.POP = new Dialog_class()
    this.Base_layers = new Base_layers_class()
    this.Helper = new Helper_class()
  }

  restore_alpha () {
    var _this = this

    if (config.layer.type != 'image') {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message:
          'Layer must be image, convert it to raster to apply this tool.',
        error: true,
        showRasterizeOption: true
      })
      return
    }

    var settings = {
      title: 'Restore alpha',
      preview: true,
      on_change: function (params, canvas_preview, w, h) {
        var img = canvas_preview.getImageData(0, 0, w, h)
        var data = _this.recover_alpha(img, params.level)
        canvas_preview.putImageData(data, 0, 0)
      },
      params: [
        { name: 'level', title: 'Level:', value: '128', range: [0, 255] }
      ],
      on_finish: function (params) {
        window.State.save()
        _this.save_alpha(params.level)
      }
    }
    this.POP.show(settings)
  }

  save_alpha (level) {
    //get canvas from layer
    var canvas = this.Base_layers.convert_layer_to_canvas(null, true)
    var ctx = canvas.getContext('2d')

    //change data
    var img = ctx.getImageData(0, 0, canvas.width, canvas.height)
    var data = this.recover_alpha(img, level)
    ctx.putImageData(data, 0, 0)

    //save
    this.Base_layers.update_layer_image(canvas)
  }

  recover_alpha (data, level) {
    var imgData = data.data
    var tmp
    level = parseInt(level)
    for (var i = 0; i < imgData.length; i += 4) {
      tmp = imgData[i + 3] + level
      if (tmp > 255) {
        tmp = 255
      }
      imgData[i + 3] = tmp
    }
    return data
  }
}

export default Tools_restoreAlpha_class
