import config from './../../../config.js'
import Helper_class from './../../../libs/helpers.js'
import _ from 'underscore'

class Connect_wallet_usecase {
  constructor () {
    this.Helper = new Helper_class()
  }

  async execute () {
    try {
      const accounts = window.ethereum
        ? await window.ethereum.request({ method: 'eth_requestAccounts' })
        : await config.web3.eth.getAccounts()

      config.account = accounts[0]

      if (_.isNull(config.account) || _.isUndefined(config.account)) {
        return { account: null, error: 'No account available' }
      } else {
        this.Helper.dispatchCustomEvent(
          'top_buttons_container',
          'accountsChanged',
          {
            account: config.account
          }
        )

        return { account: config.account, error: null }
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'Please connect your wallet',
          error: true
        })
      } else {
        console.error(error)
      }

      return { account: null, error: error }
    }
  }
}
export default Connect_wallet_usecase
