import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import _ from 'underscore'
import Helper_class from '../../libs/helpers.js'
import Ripple from '../../uicomponents/ripple.jsx'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import ListItemIcon from '@material-ui/core/ListItemIcon'

const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  inline: {
    display: 'inline'
  },
  listItem: {
    padding: 0,
    margin: 0
  },
  listItemTypography: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: 0,
    margin: 0
  },

  listBgImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    imageRendering: 'pixelated'
  },
  unselected: {
    backgroundBlendMode: `multiply, normal, normal`
  },
  selected: {
    backgroundBlendMode: `hard-light, normal, normal`
  },
  selectedGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },
  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  hoverOverlay: {
    transition: 'all .3s',
    backgroundColor: 'transparent',
    display: 'inline-block',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    '&:hover': {
      backgroundColor: fade('#fff', theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  },
  secondaryTypographyContainer: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
}))

export default function NftDrawerListItem (props) {
  const classes = useStyles()
  const Helper = new Helper_class()
  const backgroundForState = (nftInformation, selected) => {
    return selected
      ? `radial-gradient(circle at top right,
                                           #E91BFAcc 30%,
                                           #7E0AE5),
                                           url(${nftInformation.croppedBase64PngString}),
                                           linear-gradient(to right, #26262B, #26262B)`
      : `radial-gradient(circle at top right,
                                           #cccccc,
                                           #4a4a4a,
                                           #212121),
                                           url(${nftInformation.croppedBase64PngString}),
                                           linear-gradient(to right, #26262B, #26262B)`
  }
  return (
    <Draggable
      key={props.nftInformation.tokenId}
      draggableId={props.nftInformation.tokenId}
      index={props.index}
    >
      {(provided, snapshot) => (
        <ListItem
          className={clsx(
            classes.listItem,
            {
              [classes.onTop]: props.selected
            },
            {
              [classes.selectedGlow]: props.selected
            }
          )}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          alignItems='flex-start'
          disableGutters
          dense
          divider
          key={props.nftInformation.tokenId}
          selected={props.selected}
          onClick={event =>
            props.onListItemClick(event, props.nftInformation.tokenId)
          }
        >
          <div
            style={{
              backgroundImage: backgroundForState(
                props.nftInformation,
                props.selected
              )
            }}
            className={clsx(
              classes.listBgImage,
              classes.absoluteFill,

              {
                [classes.selected]: props.selected
              },

              {
                [classes.unselected]: !props.selected
              }
            )}
          />
          <ListItemText
            disableTypography
            className={clsx(
              classes.typography,
              classes.onTop,
              classes.listItemTypography
            )}
            primary={
              <Typography
                component='h5'
                variant='h5'
                noWrap
                className={clsx(classes.typography)}
                color='textPrimary'
              >
                {props.nftInformation.name}
              </Typography>
            }
            secondary={
              <div className={clsx(classes.secondaryTypographyContainer)}>
                <Typography
                  component='span'
                  variant='subtitle1'
                  noWrap
                  className={clsx(classes.inline, classes.typography)}
                  color='textPrimary'
                >
                  {Helper.truncate(props.nftInformation.artist, 10, 10)}
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle2'
                  noWrap
                  className={clsx(classes.inline, classes.typography)}
                  color={props.selected ? 'textPrimary' : 'textSecondary'}
                >
                  #{props.nftInformation.number} of Series{' '}
                  {props.nftInformation.seriesId}
                </Typography>
              </div>
            }
          ></ListItemText>

          <span className={clsx(classes.hoverOverlay, classes.onTop)}>
            {!snapshot.isDragging ? <Ripple /> : null}
          </span>

          <ListItemIcon
            style={{
              alignSelf: 'center'
            }}
          >
            <IconButton
              className={clsx(classes.icon, classes.onTop)}
              edge='end'
              aria-label='info'
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                props.onNftInfoClicked(props.nftInformation)
              }}
            >
              <InfoIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemSecondaryAction className={clsx(classes.onTop)} />
        </ListItem>
      )}
    </Draggable>
  )
}
