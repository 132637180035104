import Blockchain_MurAll_data_source_class from '../datasource/murall_data_source.js'
import config from './../../../config.js'
import Helper_class from './../../../libs/helpers.js'

const emptyHex =
  '0x0000000000000000000000000000000000000000000000000000000000000000'
const hexStartIndex = 2

class Set_pixels_on_murall_usecase {
  constructor () {
    this.MurAllDataSource = new Blockchain_MurAll_data_source_class()
    this.Helper = new Helper_class()
  }

  async execute (
    colourIndexData,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData,
    name,
    number,
    seriesId,
    hasAlpha
  ) {
    // strings need to be padded with zeroes on the right
    const hexName = config.web3.utils.padRight(
      config.web3.utils.asciiToHex(name),
      64
    )
    const hexOtherMetadata = this.constructOtherMetadata(
      number,
      seriesId,
      hasAlpha
    )

    return this.MurAllDataSource.setPixels(
      colourIndexData,
      blockchainPixelData,
      blockchainPixelGroupData,
      blockchainPixelGroupIndexData,
      blockchainTransparentPixelGroupData,
      blockchainTransparentPixelGroupIndexData,
      [hexName, hexOtherMetadata]
    )
  }

  constructOtherMetadata (number, seriesId, hasAlpha) {
    // base empty hex
    var hexOtherMetadata = emptyHex

    // numbers need to be padded with zeroes on the left
    const hexNumber = config.web3.utils.padLeft(
      config.web3.utils.numberToHex(number),
      6
    )
    const hexSeriesId = config.web3.utils.padLeft(
      config.web3.utils.numberToHex(seriesId),
      6
    )

    // Insert number slicing off '0x' from start
    hexOtherMetadata = this.Helper.overwriteStringAtIndex(
      hexOtherMetadata,
      hexNumber.slice(2),
      hexStartIndex
    )

    // Insert seriesId slicing off '0x' from start
    hexOtherMetadata = this.Helper.overwriteStringAtIndex(
      hexOtherMetadata,
      hexSeriesId.slice(2),
      hexStartIndex + 6
    )

    if (hasAlpha) {
      // change last bit to 1 signifying presence of alpha channel
      hexOtherMetadata = this.Helper.overwriteStringAtIndex(
        hexOtherMetadata,
        '1',
        hexOtherMetadata.length - 1
      )
    }
    return hexOtherMetadata
  }
}

export default Set_pixels_on_murall_usecase
