import config from './../../../config.js'

class Get_wallet_connected_state_usecase {
  constructor () {}

  execute () {
    return config.account != null
  }
}
export default Get_wallet_connected_state_usecase
