import Blockchain_MurAll_state_data_source_class from '../datasource/murall_state_s3_data_source.js'
import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'
import config from '../../../config.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'

class Get_MurAll_current_state_usecase {
  constructor () {
    this.Helper = new Helper_class()
    this.MurAllStateDataSource = new Blockchain_MurAll_state_data_source_class()
    this.NftDataSource = new Blockchain_nft_data_source_class()

    this.MurAllStateDisplayInformation = class {
      constructor (
        tokenId,
        fullBase64PngString,
        croppedBase64PngString,
        positionInfo,
        name,
        number,
        seriesId,
        hasAlpha,
        colorIndex,
        artist
      ) {
        this.tokenId = tokenId
        this.fullBase64PngString = fullBase64PngString
        this.croppedBase64PngString = croppedBase64PngString
        this.positionInformation = positionInfo
        this.name = name
        this.number = number
        this.seriesId = seriesId
        this.hasAlpha = hasAlpha
        this.colorIndex = colorIndex
        this.artist = artist
      }
    }
  }

  async execute (withMetadata = false) {
    const offScreenCanvas = await this.MurAllStateDataSource.getCurrentMurAllStateData()
    if (!withMetadata) {
      return offScreenCanvas
    }
    const totalStateChanges = await this.MurAllStateDataSource.getTotalStateChanges()
    const stateInfo = await this.NftDataSource.getRemoteTokenTransactionDataForId(
      totalStateChanges - 1,
      true
    )
    const fullBase64PngString = offScreenCanvas.toDataURL()

    // this state is full screen
    stateInfo.positionInformation.start.x = 0
    stateInfo.positionInformation.start.y = 0
    stateInfo.positionInformation.end.x = config.WIDTH
    stateInfo.positionInformation.end.y = config.HEIGHT

    return new this.MurAllStateDisplayInformation(
      stateInfo.tokenId,
      fullBase64PngString,
      stateInfo.croppedBase64PngString,
      stateInfo.positionInformation,
      stateInfo.name,
      stateInfo.number,
      stateInfo.seriesId,
      stateInfo.hasAlpha,
      stateInfo.colorIndex,
      stateInfo.artist
    )
  }
}
export default Get_MurAll_current_state_usecase
