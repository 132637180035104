import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'

class Get_nft_transaction_data_usecase {
  constructor () {
    this.NftDataSource = new Blockchain_nft_data_source_class()
  }

  async execute (tokenId) {
    return this.NftDataSource.getRemoteTokenTransactionDataForId(tokenId)
  }
}

export default Get_nft_transaction_data_usecase
