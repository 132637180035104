//main config file
const { AWS_API_URL } = process.env

var config = {}

config.TRANSPARENCY = true
config.TRANSPARENCY_TYPE = 'squares' //squares, green, grey
config.LANG = 'en'
config.WIDTH = 2048
config.HEIGHT = 1024
config.visible_width = null
config.visible_height = null
config.COLOR = '#E91BFA'
config.ALPHA = 255
config.alphaEnabled = true
config.ZOOM = 1
config.pixabay_key = '3ca2cd8af3fde33af218bea02-9021417'
config.layers = []
config.layer = null
config.need_render = false
config.mouse = {}
config.web3 = null
config.forcedNetwork = null
config.account = null
config.smartContract = null
config.murallContractAddress = null
config.paintTokenAddress = null
config.nftContract = null
config.nftContractAddress = null
config.tokenDistributionContract = null
config.tokenDistributionContractAddress = null
config.marketplaceContract = null
config.marketplaceContractAddress = null
config.blockListContract = null
config.nftMetadataContract = null
config.blockchainDataLayer = null
config.supportedNetworkIds = [1, 3, 4]

config.aws = {
  s3: {
    presignedApi: `${AWS_API_URL}/state`
  },
  paint: {
    statistics: {
      circulatingSupplyApi: `${AWS_API_URL}/statistics/paint/circSupply`,
      totalSupplyApi: `${AWS_API_URL}/statistics/paint/totalSupply`,
      maxSupplyApi: `${AWS_API_URL}/statistics/paint/maxSupply`,
    }
  }
}

//requires styles in reset.css
config.themes = ['dark']

config.TOOLS = [
  {
    name: 'select',
    title: 'Select object tool',
    attributes: {
      auto_select: true
    }
  },
  {
    name: 'selection',
    title: 'Selection',
    attributes: {},
    on_leave: 'on_leave'
  },
  {
    name: 'pick_color',
    title: 'Pick Color',
    attributes: {
      global: false
    }
  },
  {
    name: 'text',
    title: 'Text',
    attributes: {}
  },

  {
    name: 'pencil',
    title: 'Pencil',
    on_update: 'on_params_update',
    attributes: {
      antialiasing: true,
      size: 2
    }
  },
  {
    name: 'brush',
    title: 'Brush',
    attributes: {
      size: 4,
      smart_brush: true
    }
  },
  {
    name: 'erase',
    title: 'Erase',
    on_update: 'on_params_update',
    attributes: {
      size: 30,
      circle: true,
      strict: true
    }
  },
  {
    name: 'magic_wand',
    title: 'Smart erase',
    attributes: {
      power: 15,
      anti_aliasing: true,
      contiguous: false
    }
  },
  {
    name: 'fill',
    title: 'Fill',
    attributes: {
      power: 5,
      anti_aliasing: false,
      contiguous: false
    }
  },
  {
    name: 'clone',
    title: 'Clone tool',
    attributes: {
      size: 30,
      anti_aliasing: true,
      source_layer: {
        value: 'Current',
        values: ['Current', 'Previous']
      }
    }
  },
  {
    name: 'palette256',
    title: 'Reduce Palette to 256',
    on_activate: 'on_activate',
    attributes: {}
  },
  {
    name: 'rasterize',
    title: 'Rasterize',
    on_activate: 'on_activate',
    attributes: {}
  },
  {
    name: 'line',
    title: 'Line',
    attributes: {
      size: 1,
      type: {
        value: 'Simple',
        values: ['Simple', 'Arrow']
      }
    }
  },
  {
    name: 'rectangle',
    title: 'Rectangle',
    attributes: {
      size: 1,
      radius: 0,
      fill: true,
      square: false
    }
  },
  {
    name: 'circle',
    title: 'Circle',
    attributes: {
      size: 1,
      fill: true,
      circle: false
    }
  }
]

//link to active tool
config.TOOL = config.TOOLS[2]

export default config
