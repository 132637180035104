import Erc20_data_source_class from '../datasource/erc20_data_source.js'

class Get_erc20_balance_for_user_account_usecase {
  constructor () {
    this.Erc20DataSource = new Erc20_data_source_class()
  }

  async execute (erc20TokenContractAddress) {
    return this.Erc20DataSource.getBalanceForUserAccount(
      erc20TokenContractAddress
    )
  }
}

export default Get_erc20_balance_for_user_account_usecase
