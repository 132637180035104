import config from '../../../config.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'
import Blockchain_Nft_data_mapper_class from '../nft_data_mapper.js'

const async = require('async')

/**
 * Source for NFT data
 *
 * @author TheKeiron
 */
class Blockchain_nft_data_source_class {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
  }

  fillExistingTokenWithData (
    id,
    colorIndex,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData
  ) {
    var _this = this
    return new Promise(async function (resolve, reject) {
      config.nftContract.methods
        .fillData(
          id,
          colorIndex,
          blockchainPixelData,
          blockchainPixelGroupData,
          blockchainPixelGroupIndexData,
          blockchainTransparentPixelGroupData,
          blockchainTransparentPixelGroupIndexData
        )
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (_this.Helper.isTransactionSuccess(receipt)) {
            const event = receipt.events.ArtworkFilled
            const isFilled = event.returnValues.finished

            resolve(isFilled)
          } else {
            reject('Transaction Failed')
          }
        })
        .on('error', function (error) {
          reject(error)
        })
    })
  }

  getNftBalance () {
    return config.nftContract.methods.balanceOf(config.account).call()
  }

  async fetchNftDataForAccountForOwnedTokenAtIndex (index) {
    const tokenId = await config.nftContract.methods
      .tokenOfOwnerByIndex(config.account, index)
      .call()
    return this.getRemoteTokenTransactionDataForId(tokenId, true)
  }

  async fetchNftDataForAccountForOwnedTokenInIndexRange (startIndex, endIndex) {
    const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
      startIndex,
      endIndex + 1
    )
    const tokenIds = await async.map(indexes, async function (index, callback) {
      const tokenId = await config.nftContract.methods
        .tokenOfOwnerByIndex(config.account, index)
        .call()
      callback(null, tokenId)
    })

    return this.getRemoteTokenTransactionDataForIdList(tokenIds, true)
  }

  async fetchAllTokensForAccount () {
    var _this = this

    return this.getNftBalance()
      .then(function (tokensOwned) {
        return tokensOwned > 0
          ? _this.fetchTokenIndexesForOwnedTokens(tokensOwned)
          : []
      })
      .then(function (tokenIndexes) {
        return tokenIndexes.length > 0
          ? _this.fetchAllTokenInfoForTokenIndexes(tokenIndexes)
          : []
      })
      .catch(function (err) {
        console.error('error fetching token info: ', err)
        return []
      })
  }

  async fetchTokenIndexesForOwnedTokens (tokensOwned) {
    return async.times(tokensOwned, function (n, next) {
      config.nftContract.methods
        .tokenOfOwnerByIndex(config.account, n)
        .call()
        .then(function (tokenIndex) {
          next(null, tokenIndex)
        })
    })
  }

  async fetchAllTokenInfoForTokenIndexes (tokenIndexes) {
    return this.getRemoteTokenTransactionDataForIdList(tokenIndexes, true)
  }

  async fetchTokenInfoForTokenId (tokenId) {
    var _this = this

    return new Promise(function (resolve, reject) {
      config.nftContract.methods
        .getFullDataForId(tokenId)
        .call()
        .then(async function (artwork) {
          const nftInfo = await _this.NftDataMapper.processNftData(
            tokenId,
            artwork.artist,
            artwork.colorIndex,
            artwork.individualPixels,
            artwork.pixelGroups,
            artwork.pixelGroupIndexes,
            artwork.transparentPixelGroups,
            artwork.transparentPixelGroupIndexes,
            artwork.metadata[0],
            artwork.metadata[1],
            true
          )
          resolve(nftInfo)
        })
        .catch(async function (err) {
          try {
            const tokenInfo = await _this.getRemoteTokenTransactionDataForId(tokenId)

            const nftInfo = await _this.NftDataMapper.processNftData(
              tokenId,
              tokenInfo.artist,
              tokenInfo.colorIndex,
              tokenInfo.pixelData,
              tokenInfo.pixelGroups,
              tokenInfo.pixelGroupIndexes,
              tokenInfo.transparentPixelGroups,
              tokenInfo.transparentPixelGroupIndexes,
              tokenInfo.metadata[0],
              tokenInfo.metadata[1],
              false
            )
            resolve(nftInfo)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
    })
  }

  getRemoteTokenTransactionDataForId (tokenId, asNftInfo = false) {
    var _this = this
    return new Promise(function (resolve, reject) {
      config.smartContract
        .getPastEvents('Painted', {
          filter: { tokenId: config.web3.utils.toBN(tokenId) },
          fromBlock: 'earliest',
          toBlock: 'latest'
        })
        .then(async function (events) {
          if (events.length == 0) {
            reject('No events found')
          } else {
            if (asNftInfo) {
              const tokenInfo = events[0].returnValues
              const status = await config.nftContract.methods
                .getArtworkFillCompletionStatus(tokenInfo.tokenId)
                .call()
              const fillAmount = config.web3.utils
                .toBN(status.colorIndexLength)
                .add(config.web3.utils.toBN(status.individualPixelsLength))
                .add(config.web3.utils.toBN(status.pixelGroupsLength))
                .add(config.web3.utils.toBN(status.pixelGroupIndexesLength))
                .add(
                  config.web3.utils.toBN(status.transparentPixelGroupsLength)
                )
                .add(
                  config.web3.utils.toBN(
                    status.transparentPixelGroupIndexesLength
                  )
                )
                .toNumber()

              const totalFillAmount =
                tokenInfo.colorIndex.length +
                tokenInfo.pixelData.length +
                tokenInfo.pixelGroups.length +
                tokenInfo.pixelGroupIndexes.length +
                tokenInfo.transparentPixelGroups.length +
                tokenInfo.transparentPixelGroupIndexes.length
              const nftInfo = await _this.NftDataMapper.processNftData(
                tokenId,
                tokenInfo.artist,
                tokenInfo.colorIndex,
                tokenInfo.pixelData,
                tokenInfo.pixelGroups,
                tokenInfo.pixelGroupIndexes,
                tokenInfo.transparentPixelGroups,
                tokenInfo.transparentPixelGroupIndexes,
                tokenInfo.metadata[0],
                tokenInfo.metadata[1],
                fillAmount == totalFillAmount,
                Math.round((fillAmount / totalFillAmount) * 100)
              )
              resolve(nftInfo)
            } else {
              resolve(events[0].returnValues)
            }
          }
        })
    })
  }

  getRemoteTokenTransactionDataForIdList (tokenIds, asNftInfo = false) {
    const bnTokenIds = tokenIds.map(id => config.web3.utils.toBN(id))
    var _this = this
    return new Promise(function (resolve, reject) {
      config.smartContract
        .getPastEvents('Painted', {
          filter: { tokenId: bnTokenIds },
          fromBlock: 'earliest',
          toBlock: 'latest'
        })
        .then(async function (events) {
          if (events.length == 0) {
            reject('No events found')
          } else {
            const returnValues = await async.map(events, async function (
              event,
              callback
            ) {
              const tokenInfo = event.returnValues
              if (asNftInfo) {
                const status = await config.nftContract.methods
                  .getArtworkFillCompletionStatus(tokenInfo.tokenId)
                  .call()
                const fillAmount = config.web3.utils
                  .toBN(status.colorIndexLength)
                  .add(config.web3.utils.toBN(status.individualPixelsLength))
                  .add(config.web3.utils.toBN(status.pixelGroupsLength))
                  .add(config.web3.utils.toBN(status.pixelGroupIndexesLength))
                  .add(
                    config.web3.utils.toBN(status.transparentPixelGroupsLength)
                  )
                  .add(
                    config.web3.utils.toBN(
                      status.transparentPixelGroupIndexesLength
                    )
                  )
                  .toNumber()

                const totalFillAmount =
                  tokenInfo.colorIndex.length +
                  tokenInfo.pixelData.length +
                  tokenInfo.pixelGroups.length +
                  tokenInfo.pixelGroupIndexes.length +
                  tokenInfo.transparentPixelGroups.length +
                  tokenInfo.transparentPixelGroupIndexes.length
                const nftInfo = await _this.NftDataMapper.processNftData(
                  tokenInfo.tokenId,
                  tokenInfo.artist,
                  tokenInfo.colorIndex,
                  tokenInfo.pixelData,
                  tokenInfo.pixelGroups,
                  tokenInfo.pixelGroupIndexes,
                  tokenInfo.transparentPixelGroups,
                  tokenInfo.transparentPixelGroupIndexes,
                  tokenInfo.metadata[0],
                  tokenInfo.metadata[1],
                  fillAmount == totalFillAmount,
                  Math.round((fillAmount / totalFillAmount) * 100)
                )
                callback(null, nftInfo)
              } else {
                callback(null, tokenInfo)
              }
            })

            resolve(returnValues)
          }
        })
    })
  }

  fetchTokenMetadataForTokenId (tokenId) {
    return config.nftMetadataContract.methods.getNftMetadata(tokenId).call()
  }
}

export default Blockchain_nft_data_source_class
