import Helper_class from '../libs/helpers.js'
import _ from 'underscore'
import StyledImageViewerDialog from './styled_image_viewer_dialog.js'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import React, { useRef, useCallback, useState, useEffect } from 'react'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom'
import Grid from '@material-ui/core/Grid'
import useSmallScreenDetection from './useSmallScreenDetection.js'
const useStyles = makeStyles(theme => ({
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },

  dialogMedia: {
    backgroundColor: theme.palette.primary.dark,
    imageRendering: 'pixelated'
  },
  nftInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  dialogMediaInPlace: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%',
    overflow: 'auto'
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    height: '100%' /* Full height */,
    margin: 'auto',
    overflow: 'auto'
  }
}))

export default function NftInformationDialog (props) {
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()
  const Helper = new Helper_class()

  const imgCroppedRef = useRef()
  const imgFullRef = useRef()
  const onImageFullUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgFullRef

    if (img) {
      const value = make3dTransformValue({ x, y, scale })

      img.style.setProperty('transform', value)
    }
  }, [])
  const onImageCroppedUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgCroppedRef

    if (img) {
      const value = make3dTransformValue({ x, y, scale })

      img.style.setProperty('transform', value)
    }
  }, [])

  const constructColourIndex = rgb565Colour => {
    const rgbColour = '#' + Helper.rgb565HexToRgbHex('0x' + rgb565Colour)
    return (
      <Grid
        key={rgb565Colour}
        item
        align='center'
        className={clsx(classes.noPadding)}
      >
        <Typography
          className={clsx(classes.typography, classes.noPadding)}
          variant='body2'
          component='p'
          color='textSecondary'
          style={{
            color: rgbColour
          }}
        >
          &#11044;
        </Typography>
      </Grid>
    )
  }
  const nftInformation = props.nftInformation

  return nftInformation != null ? (
    <StyledImageViewerDialog
      {...props}
      withCloseButton={true}
      dialogTitle={'#' + nftInformation.tokenId}
      dialogImageContent={() =>
        props.viewCropped ? (
          <QuickPinchZoom onUpdate={onImageCroppedUpdate}>
            <img
              ref={imgCroppedRef}
              className={clsx(classes.dialogMedia, classes.dialogMediaCropped)}
              id='nftImageCropped'
              src={nftInformation.croppedBase64PngString}
              alt={'Token ' + nftInformation.tokenId + 'has not been filled!'}
            />
          </QuickPinchZoom>
        ) : (
          <QuickPinchZoom onUpdate={onImageFullUpdate}>
            <img
              ref={imgFullRef}
              className={clsx(classes.dialogMedia, classes.dialogMediaInPlace)}
              id='nftImage'
              src={nftInformation.fullBase64PngString}
              alt={'Token ' + nftInformation.tokenId + 'has not been filled!'}
            />
          </QuickPinchZoom>
        )
      }
      dialogInformationColumn={smallScreen}
      dialogInformation={() => (
        <React.Fragment>
          <div
            className={clsx(classes.nftInfo)}
            style={{
              marginBottom: props.hideButtons ? '16px' : '0px'
            }}
          >
            <Typography
              className={clsx(classes.typography)}
              variant='h4'
              component='h4'
              color='textPrimary'
              gutterBottom
            >
              {nftInformation.name}
            </Typography>
            <Typography
              className={clsx(classes.typography)}
              variant='h6'
              component='h6'
              color='textPrimary'
            >
              {nftInformation.artist}
            </Typography>
            <Typography
              className={clsx(classes.typography)}
              variant='subtitle1'
              component='subtitle1'
              color='textSecondary'
              noWrap
              gutterBottom={props.showCompletionStatus}
            >
              #{nftInformation.number} from Series {nftInformation.seriesId}
            </Typography>
            {props.showCompletionStatus && (
              <Typography
                className={clsx(classes.typography)}
                variant='subtitle2'
                component='subtitle2'
                color='textSecondary'
                noWrap
              >
                {nftInformation.completionStatus}% filled
              </Typography>
            )}
          </div>
          {smallScreen ? null : (
            <Grid
              container
              direction='row'
              justify='flex-end'
              alignItems='flex-end'
              alignContent='flex-end'
              spacing={0}
              style={{ flex: 1, height: '100%', marginBottom: '16px' }}
            >
              {nftInformation.colorIndex.map(rgb565Colour =>
                constructColourIndex(rgb565Colour)
              )}
            </Grid>
          )}
        </React.Fragment>
      )}
    />
  ) : null
}
