import config from './../../config.js'
import Base_layers_class from './../../core/base-layers.js'
import Selection_class from './../../tools/selection.js'
import Helper_class from './../../libs/helpers.js'
class Edit_selection_class {
  constructor () {
    this.Base_layers = new Base_layers_class()
    this.Selection = new Selection_class(this.Base_layers.ctx)
    this.Helper = new Helper_class()
  }

  select_all () {
    if (config.layer.type != 'image') {
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message:
          'Layer must be image, convert it to raster to apply this tool.',
        error: true,
        showRasterizeOption: true
      })
      return
    }

    this.Selection.select_all()
  }

  delete () {
    window.State.save()
    this.Selection.delete_selection()
  }
}

export default Edit_selection_class
