import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider'

const getWeb3 = async requestAccountAccess => {
  const provider = await detectEthereumProvider()

  // Modern dapp browsers...
  if (provider === window.ethereum) {
    const web3 = new Web3(window.ethereum)
    if (requestAccountAccess) {
      try {
        // Request account access if needed
        await window.ethereum.request({ method: 'eth_requestAccounts' })
        // Acccounts now exposed
      } catch (error) {
        throw Error(error)
      }
    }

    return { web3, forceNetwork: false }
  }
  // Legacy dapp browsers...
  else if (window.web3) {
    // Use Mist/MetaMask's provider.
    const web3 = window.web3
    return { web3, forceNetwork: false }
  } else if (
    location.hostname === 'localhost' ||
    location.hostname === '127.0.0.1'
  ) {
    // We're using localhost; use dev console port by default...
    const provider = new Web3.providers.HttpProvider('http://127.0.0.1:8545')
    const web3 = new Web3(provider)
    return { web3, forceNetwork: false }
  } else {
    // We're on the web server without a web3 wallet in the browser - use alchemy
    const url =
      'wss://eth-mainnet.ws.alchemyapi.io/v2/gcQg7HfeVdRNa--z3c9LqMIkAeWF1gjG'
    const provider = new Web3.providers.WebsocketProvider(url)

    const web3 = new Web3(provider)
    return { web3, forceNetwork: true, network: 1 }
  }
}

export default getWeb3
