import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PaintStatsInfoCard from './paintStatsInfoCard'
import GetPaintTokenRemainingSupplyUsecase from '../../modules/blockchain/usecase/get_paint_token_remaining_supply_usecase'
import GetPaintTokenInitialSupplyUsecase from '../../modules/blockchain/usecase/get_paint_token_initial_supply_usecase'
import GetTotalMurAllArtistsCountUsecase from '../../modules/blockchain/usecase/get_total_murall_artists_count_usecase'
import Get_total_MurAll_state_count_usecase from '../../modules/blockchain/usecase/get_total_murall_state_count_usecase'
import GetPaintTokenCirculatingSupplyUsecase from '../../modules/blockchain/usecase/get_paint_token_circulating_supply_usecase'
import { useState, useEffect } from 'react'
import config from '../../config'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  buttonLarge: {
    fontSize: '63px'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function StatsLandingPage (props) {
  const getPaintTokenRemainingSupplyUsecase = new GetPaintTokenRemainingSupplyUsecase()
  const getPaintTokenInitialSupplyUsecase = new GetPaintTokenInitialSupplyUsecase()
  const getTotalMurAllStateCountUseCase = new Get_total_MurAll_state_count_usecase()
  const getTotalMurAllArtistsCountUsecase = new GetTotalMurAllArtistsCountUsecase()
  const getPaintTokenCirculatingSupplyUsecase = new GetPaintTokenCirculatingSupplyUsecase()

  const classes = useStyles()
  const [paintTotalSupply, setPaintTotalSupply] = useState(0)
  const [paintOriginalSupply, setPaintOriginalSupply] = useState(0)
  const [paintBurned, setPaintBurned] = useState(0)
  const [totalDrawings, setTotalDrawings] = useState(0)
  const [totalArtists, setTotalArtists] = useState(0)
  const [circulatingSupply, setCirculatingSupply] = useState(0)
  const [paintTotalSupplyPercentage, setPaintTotalSupplyPercentage] = useState(
    0
  )

  useEffect(() => {
    function onLoad () {
      updatePaintTotalSupply()
    }

    onLoad()
    // Remove event listener on cleanup
    return () => {}
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    function onPaintedEventReceived (e) {
      updatePaintTotalSupply()
    }

    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('painted', onPaintedEventReceived.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'painted',
          onPaintedEventReceived.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  const updatePaintTotalSupply = async () => {
    const totalArtists = await getTotalMurAllArtistsCountUsecase.execute()
    setTotalArtists(totalArtists)
    const totalDrawings = await getTotalMurAllStateCountUseCase.execute()
    setTotalDrawings(totalDrawings)
    const remainingSupplyResult = await getPaintTokenRemainingSupplyUsecase.execute()
    const originalSupply = getPaintTokenInitialSupplyUsecase.execute()
    const circulatingSupply = await getPaintTokenCirculatingSupplyUsecase.execute()

    const burnedTokens = config.web3.utils
    .toBN(originalSupply)
    .sub(config.web3.utils.toBN(remainingSupplyResult.remainingSupply))

    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    setCirculatingSupply(formatter.format(circulatingSupply))

    setPaintTotalSupply(
      formatter.format(
        config.web3.utils.fromWei(
          remainingSupplyResult.remainingSupply,
          'ether'
        )
      )
    )
    setPaintOriginalSupply(
      formatter.format(config.web3.utils.fromWei(originalSupply, 'ether'))
    )

    setPaintTotalSupplyPercentage(remainingSupplyResult.percentage)
    setPaintBurned(
      formatter.format(config.web3.utils.fromWei(burnedTokens, 'ether'))
    )
  }

  return (
    <div className={clsx(classes.absoluteFill, classes.root)}>
      <PaintStatsInfoCard
        title={'PAINT Statistics'}
        image={'/images/PAINT_ETH_trade.png'}
        totalSupply={paintTotalSupply}
        totalSupplyPercent={paintTotalSupplyPercentage}
        paintBurned={paintBurned}
        totalDrawings={totalDrawings}
        totalArtists={totalArtists}
        originalSupply={paintOriginalSupply}
        circulatingSupply={circulatingSupply}
      />
    </div>
  )
}
