'use strict'

import Get_nfts_for_user_account_usecase from '../../modules/blockchain/usecase/get_nfts_for_user_account_usecase.js'
import Get_wallet_connected_state_usecase from '../../modules/blockchain/usecase/get_wallet_connected_state_usecase.js'
import Get_is_current_network_supported_usecase from '../../modules/blockchain/usecase/get_is_current_network_supported_usecase.js'
import Helper_class from '../../libs/helpers.js'
import _ from 'underscore'
import LoadingSpinner from '../../uicomponents/loading_spinner.js'
import DrawerDetailsView from '../../uicomponents/drawer_detail_view.js'
import LoadingDialog from '../../uicomponents/loading_dialog.js'
import NftViewerCanvas from './nft_viewer_canvas.js'
import NftDrawerListItem from './nft_drawer_list_item.js'
import { makeStyles } from '@material-ui/core/styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Collapse from '@material-ui/core/Collapse'
import EmptyNftsMessageView from '../../uicomponents/empty_nfts_message_view.js'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view.js'
import ConnectWalletMessageView from '../../uicomponents/connect_wallet_message_view.js'
import NftInformationDialog from '../../uicomponents/nft_information_dialog.js'
import React, { useRef, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'notistack'

const DEFAULT_FADE_DURATION_MILLIS = 350
const ANIMATION_EASE_DEFAULT = 'power2.inOut'

const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  list: {
    flexGrow: 1,
    overflowX: 'hidden'
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  listHeading: {
    margin: 0
  },
  panelDetails: {
    flexDirection: 'column',
    overflow: 'auto'
  },
  onTop: {
    zIndex: 999
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  body: {
    fontSize: 16
  },
  artistTypography: {
    fontSize: 10
  },
  dialogMedia: {
    backgroundColor: theme.palette.primary.dark,
    imageRendering: 'pixelated'
  },
  dialogMediaCropped: {
    width: '100%' /* Full width */,
    height: '100%' /* Full height */,
    margin: 'auto',
    overflow: 'auto'
  }
}))

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

export default function NftDisplay (props) {
  const getNftsUseCase = new Get_nfts_for_user_account_usecase()
  const getWalletConnectedStateUseCase = new Get_wallet_connected_state_usecase()
  const getIsCurrentNetworkSupportedUseCase = new Get_is_current_network_supported_usecase()
  const drawerContainer = useRef(null)
  const allNftsDrawerHeader = useRef(null)
  const displayNftsDrawerHeader = useRef(null)

  const [isFetching, setIsFetching] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  const [isUpdatingState, setIsUpdatingState] = useState(false)
  const [currentSelectedState, setCurrentSelectedState] = useState(0)
  const [nfts, setNfts] = useState(new Map())
  const [displayTokenOrder, setDisplayTokenOrder] = useState([])
  const [allTokens, setAllTokens] = useState([])
  const [expandedHeight, setExpandedHeight] = useState(0)
  const [displayNftListExpanded, setDisplayNftListExpanded] = useState(true)
  const [allNftListExpanded, setAllNftListExpanded] = useState(true)
  const [nftDialogOpen, setNftDialogOpen] = useState(false)
  const [selectedNftInfo, setSelectedNftInfo] = useState(0)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const classes = useStyles()
  const Helper = new Helper_class()
  useEffect(() => {
    function handleResize () {
      updateExpandedHeight()
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    checkWalletConnectionThenFetch()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    function onAccountsChanged () {
      checkWalletConnectionThenFetch()
    }
    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('accountsChanged', onAccountsChanged.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'accountsChanged',
          onAccountsChanged.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    function onNetworkChanged (e) {
      setNetworkError(!e.detail.isSupported)
      if (e.detail.isSupported) {
        checkWalletConnectionThenFetch()
      }
    }
    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('networkChanged', onNetworkChanged.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'networkChanged',
          onNetworkChanged.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    updateExpandedHeight()
  }, [nfts, displayNftListExpanded, allNftListExpanded])

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result
    // dropped outside the list
    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId) {
      //if (destination.index === source.index) return;
      const items = reorder(
        source.droppableId === 'displayList' ? displayTokenOrder : allTokens,
        source.index,
        destination.index
      )

      if (source.droppableId === 'displayList') {
        setDisplayTokenOrder(items)
      } else {
        setAllTokens(items)
      }
    } else {
      const result = move(
        source.droppableId === 'displayList' ? displayTokenOrder : allTokens,
        destination.droppableId === 'displayList'
          ? displayTokenOrder
          : allTokens,
        source,
        destination
      )

      setDisplayTokenOrder(result.displayList)
      setAllTokens(result.allItemsList)
    }
  }

  const handleAllNftsExpandClick = () => {
    setAllNftListExpanded(!allNftListExpanded)
  }

  const handleDisplayNftsExpandClick = () => {
    setDisplayNftListExpanded(!displayNftListExpanded)
  }

  const updateExpandedHeight = () => {
    if (
      !_.isNull(drawerContainer.current) &&
      !_.isNull(allNftsDrawerHeader.current) &&
      !_.isNull(displayNftsDrawerHeader.current)
    ) {
      const height =
        drawerContainer.current.offsetHeight -
        allNftsDrawerHeader.current.offsetHeight -
        displayNftsDrawerHeader.current.offsetHeight

      setExpandedHeight(
        displayNftListExpanded && allNftListExpanded ? height / 2 : height
      )
    }
  }

  const constructNftInfoDialog = () => {
    const nftInformation =
      selectedNftInfo != null ? nfts[selectedNftInfo] : null

    return nftInformation != null ? (
      <NftInformationDialog
        open={nftDialogOpen}
        onClose={handleNftDialogClose}
        nftInformation={nftInformation}
        hideButtons
        viewCropped
      />
    ) : null
  }

  const constructColourIndex = rgb565Colour => {
    const rgbColour = '#' + Helper.rgb565HexToRgbHex('0x' + rgb565Colour)
    return (
      <Grid key={rgb565Colour} item align='center'>
        <Typography
          className={clsx(classes.typography)}
          variant='body2'
          component='p'
          color='textSecondary'
          style={{
            color: rgbColour
          }}
        >
          &#11044;
        </Typography>
      </Grid>
    )
  }

  const constructCollapsableDrawerContent = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div ref={drawerContainer} style={{ width: '100%', height: '100%' }}>
          <List
            className={clsx(
              classes.transparent,
              classes.list,
              classes.noPadding
            )}
          >
            <ListItem
              button
              className={clsx(classes.transparent, classes.listHeading)}
              onClick={handleAllNftsExpandClick}
              ref={allNftsDrawerHeader}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    component='body1'
                    variant='body1'
                    noWrap
                    className={clsx(classes.typographyLight)}
                    color='textPrimary'
                  >
                    ALL NFTS:
                  </Typography>
                }
              />
              {allNftListExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              className={clsx(classes.panelDetails)}
              in={allNftListExpanded}
              timeout='auto'
              unmountOnExit
              style={{}}
            >
              <Droppable droppableId={'allItemsList'}>
                {(provided, snapshot) => (
                  <List
                    className={clsx(
                      classes.transparent,
                      classes.list,
                      classes.noPadding
                    )}
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver
                        ? 'radial-gradient(circle at top right, #E91BFAcc 30%, #7E0AE5)'
                        : 'linear-gradient(to right, #7E0AE54D, #26262B00)',
                      backgroundBlendMode: 'multiply',
                      height: expandedHeight + 'px'
                    }}
                  >
                    {allTokens.map((tokenId, index) =>
                      constructNftListItem(tokenId, index)
                    )}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Collapse>
            <ListItem
              button
              className={clsx(classes.transparent, classes.listHeading)}
              onClick={handleDisplayNftsExpandClick}
              ref={displayNftsDrawerHeader}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    component='body1'
                    variant='body1'
                    noWrap
                    className={clsx(classes.typographyLight)}
                    color='textPrimary'
                  >
                    DISPLAY NFTS:
                  </Typography>
                }
              />
              {displayNftListExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              className={clsx(classes.panelDetails)}
              in={displayNftListExpanded}
              timeout='auto'
              unmountOnExit
              style={{}}
            >
              <Droppable droppableId={'displayList'}>
                {(provided, snapshot) => (
                  <List
                    style={{
                      background: snapshot.isDraggingOver
                        ? 'radial-gradient(circle at top right, #E91BFAcc 30%, #7E0AE5)'
                        : 'linear-gradient(to right, #7E0AE54D, #26262B00)',
                      height: expandedHeight + 'px'
                    }}
                    className={clsx(
                      classes.transparent,
                      classes.list,
                      classes.noPadding
                    )}
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {displayTokenOrder.map((tokenId, index) =>
                      constructNftListItem(tokenId, index)
                    )}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Collapse>
          </List>
        </div>
      </DragDropContext>
    )
  }

  const constructNftListItem = (tokenId, index) => {
    const nftInformation = nfts[tokenId]
    return (
      <NftDrawerListItem
        nftInformation={nftInformation}
        index={index}
        key={tokenId}
        onListItemClick={handleListItemClick}
        onNftInfoClicked={handleNftInfoClicked}
        selected={currentSelectedState === nftInformation.tokenId}
      />
    )
  }

  const handleNftInfoClicked = nftInformation => {
    setSelectedNftInfo(nftInformation.tokenId)
    setNftDialogOpen(true)
  }

  const handleNftDialogClose = () => {
    setSelectedNftInfo(null)
    setNftDialogOpen(false)
  }

  const handleListItemClick = (event, selectedTokenId) => {
    setCurrentSelectedState(selectedTokenId)
  }

  const checkWalletConnectionThenFetch = async () => {
    const supportedResult = await getIsCurrentNetworkSupportedUseCase.execute()
    if (!supportedResult.isSupported) {
      setIsFetching(false)
      setIsUpdatingState(false)
      setNetworkError(true)
      return
    }
    setIsFetching(true)
    setNetworkError(false)
    const connected = getWalletConnectedStateUseCase.execute()
    if (connected) {
      setIsConnected(true)
      fetchAllTokensForCurrentAccount()
    } else {
      setIsConnected(connected)
      setIsFetching(false)
      setNetworkError(!supportedResult.isSupported)
    }
  }

  const fetchAllTokensForCurrentAccount = async () => {
    setIsFetching(true)
    setNetworkError(false)
    setNfts([])
    setDisplayTokenOrder([])
    setAllTokens([])

    try {
      const myNfts = await getNftsUseCase.execute()
      const tokenIds = _.map(myNfts, function (nft, tokenId) {
        return tokenId
      })

      if (_.isEmpty(myNfts)) {
        setIsFetching(false)
        setIsUpdatingState(false)
        setNfts(new Map())
        setDisplayTokenOrder([])
        setAllTokens([])
        enqueueSnackbar(
          'You do not own any MurAll NFTs - try drawing something or go to the marketplace!',
          {
            variant: 'error'
          }
        )
      } else {
        setIsFetching(false)
        setCurrentSelectedState(tokenIds[0])
        setNfts(myNfts)
        setDisplayTokenOrder([])
        setAllTokens(tokenIds)
      }
    } catch (error) {
      setNetworkError(true)
    }
  }

  return isFetching ? (
    <LoadingSpinner />
  ) : networkError ? (
    <NetworkErrorMessageView />
  ) : !isConnected ? (
    <ConnectWalletMessageView />
  ) : _.isEmpty(nfts) ? (
    <EmptyNftsMessageView />
  ) : (
    <div className={clsx(classes.absoluteFill)} style={{ overflow: 'hidden' }}>
      <DrawerDetailsView
        {...props}
        drawerOpenByDefault={true}
        drawerContent={() => constructCollapsableDrawerContent()}
        mainContent={() => (
          <NftViewerCanvas
            enableOffset
            states={nfts}
            tokenOrder={displayTokenOrder}
            currentSelectedState={
              displayNftListExpanded ? currentSelectedState : null
            }
            animationEase={ANIMATION_EASE_DEFAULT}
            animationDuration={DEFAULT_FADE_DURATION_MILLIS / 1000.0}
            style={{ width: '100%', height: '100%', overflow: 'auto' }}
          />
        )}
      />
      <LoadingDialog open={isUpdatingState} />
      {constructNftInfoDialog()}
    </div>
  )
}
