import React from 'react'

//css
import './css/reset.css'
import './css/layout.css'
import './css/menu.css'
import './css/print.css'
import './css/slider.css'

//js
import config from './js/config.js'
import getWeb3 from './js/libs/getWeb3.js'
import MurAllContract from './contracts/MurAll.json'
import MurAllNFTContract from './contracts/MurAllNFT.json'
import MurAllBlockList from './contracts/MurAllBlockList.json'
import PaintTokenContract from './contracts/PaintToken.json'
import MurAllMarketplace from './contracts/MurAllMarketplace.json'
import NftMetadata from './contracts/NftMetadata.json'
import MerkleDistributor from './contracts/MerkleDistributor.json'
import NavButtons from './js/pages/main/top_navigation.js'
import Helper_class from './js/libs/helpers.js'
import Get_is_network_supported_usecase from './js/modules/blockchain/usecase/get_is_network_supported_usecase.js'
import Notify_painted_event_usecase from './js/modules/blockchain/usecase/notify_painted_event_usecase.js'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import _ from 'underscore'

import ClearIcon from '@material-ui/icons/Clear'

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#7E0AE5',
      dark: '#1A1A1A'
    },
    secondary: {
      main: '#E91BFA',
      dark: '#26262B'
    }
  }
})
var Helper = new Helper_class()
var getIsNetworkSupportedUsecase = new Get_is_network_supported_usecase()
var notifyPaintedEventUsecase = new Notify_painted_event_usecase()

const initContracts = async (web3, networkId) => {
  //fetch web3
  try {
    // grab MurAll contract information
    const deployedNetwork = MurAllContract.networks[networkId]
    const instance = new web3.eth.Contract(
      MurAllContract.abi,
      deployedNetwork && deployedNetwork.address
    )
    config.smartContract = instance

    instance.events
      .Painted({})
      .on('data', async function (event) {
        notifyPaintedEventUsecase.execute(event)
      })
      .on('error', async function (error) {
        console.error(error)
      })
    config.murallContractAddress = deployedNetwork.address
    // grab NFT contract information
    const deployedNftNetwork = MurAllNFTContract.networks[networkId]
    const nftInstance = new web3.eth.Contract(
      MurAllNFTContract.abi,
      deployedNftNetwork && deployedNftNetwork.address
    )
    config.nftContract = nftInstance
    config.nftContractAddress = deployedNftNetwork.address

    // grab paint token contract address
    config.paintTokenAddress = PaintTokenContract.networks[networkId].address

    // grab block list contract information
    const deployedBlockListNetwork = MurAllBlockList.networks[networkId]
    const blockListInstance = new web3.eth.Contract(
      MurAllBlockList.abi,
      deployedBlockListNetwork && deployedBlockListNetwork.address
    )
    config.blockListContract = blockListInstance

    // grab marketplace contract information
    const deployedMarketplaceNetwork = MurAllMarketplace.networks[networkId]
    const marketplaceInstance = new web3.eth.Contract(
      MurAllMarketplace.abi,
      deployedMarketplaceNetwork && deployedMarketplaceNetwork.address
    )
    config.marketplaceContract = marketplaceInstance
    config.marketplaceContractAddress = deployedMarketplaceNetwork.address

    // grab nft metadata contract information
    const deployedNftMetadataNetwork = NftMetadata.networks[networkId]
    const nftMetadataContractInstance = new web3.eth.Contract(
      NftMetadata.abi,
      deployedNftMetadataNetwork && deployedNftMetadataNetwork.address
    )
    config.nftMetadataContract = nftMetadataContractInstance

    // grab token distribution contract information
    const deployedTokenDistributionNetwork =
      MerkleDistributor.networks[networkId]
    const tokenDistributionInstance = new web3.eth.Contract(
      MerkleDistributor.abi,
      deployedTokenDistributionNetwork &&
        deployedTokenDistributionNetwork.address
    )
    config.tokenDistributionContract = tokenDistributionInstance
    config.tokenDistributionContractAddress =
      deployedTokenDistributionNetwork.address
  } catch (error) {
    // Catch any errors for any of the above operations.
    alert(
      `Failed to load web3, accounts, or contract. Check console for details.`
    )
    console.error(error)
  }
}

window.addEventListener(
  'load',
  async () => {
    const web3Result = await getWeb3()
    const web3 = web3Result.web3
    config.web3 = web3
    const networkId = web3Result.forceNetwork
      ? web3Result.network
      : await web3.eth.net.getId()
    if (web3Result.forceNetwork) {
      config.forcedNetwork = networkId
    }
    const supportedResult = getIsNetworkSupportedUsecase.execute(networkId)
    if (supportedResult.isSupported) {
      await initContracts(web3, networkId)
    } else {
      alert(supportedResult.error)
    }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function () {
        web3.eth.getAccounts(function (error, accounts) {
          config.account = accounts[0]
          Helper.dispatchCustomEvent(
            'top_buttons_container',
            'accountsChanged',
            {
              account: accounts[0]
            }
          )
        })
      })

      window.ethereum.on('networkChanged', async function (newNetworkId) {
        const newSupportedResult = getIsNetworkSupportedUsecase.execute(
          parseInt(newNetworkId)
        )
        if (newSupportedResult.isSupported) {
          await initContracts(web3, newNetworkId)
        } else {
          Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
            message: newSupportedResult.error,
            error: true
          })
        }

        Helper.dispatchCustomEvent('top_buttons_container', 'networkChanged', {
          network: newNetworkId,
          isSupported: newSupportedResult.isSupported
        })
      })
    }

    //register as global for quick or external access
    window.AppConfig = config

    const notistackRef = React.createRef()
    const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key)
    }

    const element = document.getElementById('loading_screen')
    element.remove()
    const navButtons = ReactDOM.render(
      <MuiThemeProvider theme={darkTheme}>
        <SnackbarProvider
          maxSnack={3}
          preventDuplicate
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          ref={notistackRef}
          action={key => (
            <IconButton onClick={onClickDismiss(key)} aria-label='dismiss'>
              <ClearIcon />
            </IconButton>
          )}
        >
          <Router>
            <NavButtons />
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>,
      document.getElementById('top_buttons_container')
    )
  },
  false
)
