import React from 'react'

import useDetectSmallScreen from '../../../../uicomponents/useSmallScreenDetection'
import TextField from './text-field'
import {
  singleTokenValue as sanitiseSingleValue,
  tokenRangeStartValue as sanitiseRangeStartValue,
  tokenRangeEndValue as sanitiseRangeEndValue
} from '../../../../libs/sanitisers'

import useStyles from './styles'

const Wrapper = ({ isMobile, className, children }) => {
  if (isMobile) return children
  return <div className={className}>{children}</div>
}

const RangeEnd = ({ show, rangeEnd, onChange, onBlur, max, className }) => {
  if (!show) return null

  const min = 1
  return (
    <TextField
      value={rangeEnd}
      onChange={onChange}
      onBlur={onBlur}
      label='Range end'
      helpText={`${min} to ${max}`}
      min={min}
      max={max}
      className={className}
    />
  )
}

const Fields = ({
  showRange,
  totalTokens,
  rangeStart,
  rangeEnd,
  setRangeStart,
  setRangeEnd
}) => {
  const isMobile = useDetectSmallScreen()
  const cls = useStyles(isMobile)()

  const setNewTokenRange = (start, end) => {
    if (end < start) {
      end = start + 1
    }

    if (start > end) {
      start = end - 1
    }
    setRangeStart(start)
    setRangeEnd(end)
  }

  const onBlurStartField = e => {
    const value = Number(e.target.value)
    if (!showRange)
      return setRangeStart(sanitiseSingleValue(value, totalTokens))
    return setNewTokenRange(
      sanitiseRangeStartValue(value, totalTokens),
      rangeEnd
    )
  }

  const onBlurEndField = e => {
    const value = Number(e.target.value)
    return setNewTokenRange(
      rangeStart,
      sanitiseRangeEndValue(value, totalTokens)
    )
  }

  const startInputMax =
    showRange && totalTokens > 0 ? totalTokens - 1 : totalTokens
  return (
    <Wrapper isMobile={isMobile} className={cls.gridRow}>
      <TextField
        value={rangeStart}
        onChange={e => setRangeStart(Number(e.target.value))}
        onBlur={onBlurStartField}
        label={showRange ? 'Range start' : 'Token Id'}
        helpText={`0 to ${startInputMax}`}
        min={0}
        max={startInputMax}
        className={cls.rangeStart}
      />
      <RangeEnd
        show={showRange}
        onBlur={onBlurEndField}
        rangeEnd={rangeEnd}
        onChange={e => setRangeEnd(Number(e.target.value))}
        max={totalTokens}
        className={cls.rangeEnd}
      />
    </Wrapper>
  )
}

export default Fields
