'use strict'

import _ from 'underscore'
import ImageViewerCanvas from '../../uicomponents/image_viewer_canvas'

import gsap from 'gsap'

class HistoryViewerCanvas extends ImageViewerCanvas {
  constructor (props) {
    super(props)
  }

  componentDidUpdate (prevProps) {
    super.componentDidUpdate(prevProps)

    if (
      prevProps.states === this.props.states &&
      this.images.size > 0 &&
      prevProps.currentSelectedState !== this.props.currentSelectedState
    ) {
      const timeline = gsap.timeline()
      if (this.props.currentSelectedState < prevProps.currentSelectedState) {
        // we've gone backwards, fade out all layers down from the previous states token id to the new states token id
        for (
          var i = prevProps.currentSelectedState;
          i > this.props.currentSelectedState;
          i--
        ) {
          this.animateLayerOut(i.toString(), timeline)
        }
      } else {
        // we've gone forwards, fade in all layers up from the previous states token id to the new states token id
        for (
          var i = prevProps.currentSelectedState + 1;
          i <= this.props.currentSelectedState;
          i++
        ) {
          this.animateLayerIn(i.toString(), timeline)
        }
      }
    }
    if (this.props.shouldLocate) {
      const timeline = gsap.timeline()
      this.animateLayerIn(
        this.props.currentSelectedState.toString(),
        timeline,
        this.props.onLocateFinished
      )
    }
  }
}

export default HistoryViewerCanvas
