import Helper_class from '../../../libs/helpers.js'
import Set_pixels_on_murall_usecase from './set_pixels_on_murall_usecase.js'

class SetPixelsOnMurallAndNotifyUsecase {
  constructor () {
    this.setPixelsOnMurallUsecase = new Set_pixels_on_murall_usecase()
    this.Helper = new Helper_class()
  }

  async execute (
    colourIndexData,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData,
    name,
    number,
    seriesId,
    hasAlpha
  ) {
    this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
      message:
        'Drawing ' + name + ', #' + number + ' of series ' + seriesId + '',
      loading: true,
      info: true
    })
    try {
      const event = await this.setPixelsOnMurallUsecase.execute(
        colourIndexData,
        blockchainPixelData,
        blockchainPixelGroupData,
        blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData,
        name,
        number,
        seriesId,
        hasAlpha
      )
      const tokenId = event.returnValues.tokenId

      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        dismissLoading: true
      })
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        message:
          'Minted new token #' +
          tokenId +
          ' ' +
          name +
          ', #' +
          number +
          ' of series ' +
          seriesId +
          ' - Fill it with data from inside View My NFTs',
        success: true
      })
      this.Helper.dispatchCustomEvent(
        'top_buttons_container',
        'updatePaintBalance',
        {}
      )
    } catch (error) {
      console.error(error)
      this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
        dismissLoading: true
      })
      if (error.message.indexOf('User denied') != -1) {
        this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'You rejected the transaction!',
          error: true
        })
      } else {
        this.Helper.dispatchCustomEvent('top_buttons_container', 'notify', {
          message: 'Sorry, the transaction failed: ' + error.name,
          error: true
        })
      }
    }
  }
}

export default SetPixelsOnMurallAndNotifyUsecase
