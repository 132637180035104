import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function ConnectWalletMessageView (props) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.emptyViewContainer, classes.absoluteFill)}>
      <Typography
        className={clsx(classes.typographyLight)}
        variant='h4'
        component='h4'
        color='textPrimary'
      >
        Ethereum Wallet Disconnected
      </Typography>
      <Typography
        className={clsx(classes.typographyLight)}
        variant='h6'
        component='h6'
        color='textSecondary'
      >
        Please connect your Ethereum wallet to view this page
      </Typography>
    </div>
  )
}
