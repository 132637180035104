import { makeStyles } from '@material-ui/core/styles'

const styles = {
  desktop: () => ({
    gridRow: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      flex: 1,
      paddingBottom: '16px'
    },
    rangeStart: {
      flex: 1
    },
    rangeEnd: {
      flex: 1,
      marginLeft: '24px'
    }
  }),
  mobile: () => ({
    rangeStart: {
      flex: 1,
      marginBottom: '24px'
    },
    rangeEnd: {
      flex: 1,
      marginBottom: '24px'
    }
  })
}

export default isMobile => {
  return makeStyles(isMobile ? styles.mobile : styles.desktop)
}
