import config from './../../../config.js'

class GetPaintTokenCirculatingSupplyUsecase {
  async execute () {
    try {
      const result = await fetch(
        config.aws.paint.statistics.circulatingSupplyApi
      )
      const circulatingSupply = await result.json()
      return circulatingSupply
    } catch (error) {
      console.error(error)
      return 0
    }
  }
}

export default GetPaintTokenCirculatingSupplyUsecase
