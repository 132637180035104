import config from '../../../config.js'
import Helper_class from '../../../libs/helpers.js'
import axios from 'axios'

/**
 * Source for MurAll marketplace data
 *
 * @author TheKeiron
 */
class TokenDistributionDataSource_class {
  constructor () {
    this.Helper = new Helper_class()
  }

  isClaimAtIndexClaimed (index) {
    return config.tokenDistributionContract.methods.isClaimed(index).call()
  }

  async claimAmountAtIndexForAddressWithProof (
    index,
    account,
    amount,
    merkleProof
  ) {
    var _this = this
    return new Promise(function (resolve, reject) {
      config.tokenDistributionContract.methods
        .claim(index, account, amount, merkleProof)
        .send({ from: config.account })
        .on('receipt', function (receipt) {
          if (_this.Helper.isTransactionSuccess(receipt)) {
            resolve(true)
          } else {
            reject(false)
          }
        })
        .on('error', function (error) {
          console.error(error)
          reject(false)
        })
    })
  }

  getRemainingClaimTime () {
    return config.tokenDistributionContract.methods.remainingClaimTime().call()
  }

  async getClaimDetailsForAddress (address) {
    return new Promise(function (resolve, reject) {
      const filePath = '/claims/merkle/' + address + '.json'
      axios
        .get(filePath)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          reject({ index: -1, amount: 0, proof: [] })
        })
    })
  }
}

export default TokenDistributionDataSource_class
