import React, { useState } from 'react'

import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'

import useDetectSmallScreen from '../../../uicomponents/useSmallScreenDetection'

import ModeRadioButtons from './radio-buttons'
import RangeFields from './range-fields'
import TermsAndConditions from './terms'
import DisplayToggle from './display-toggle'
import useStyles from './styles'

const HistoryRangePicker = ({
  open,
  rangeStart,
  setRangeStart,
  rangeEnd,
  setRangeEnd,
  totalStates,
  onLoadHistoryClick,
  onExpandToggle
}) => {
  const isSmallScreen = useDetectSmallScreen()
  const classes = useStyles(isSmallScreen)()

  const [isSingle, setIsSingle] = useState(true)

  const handleChange = e => {
    const isSinglePicked = e.target.value === 'single'
    setIsSingle(isSinglePicked)
    setRangeStart(0)
    if (!isSinglePicked) {
      setRangeEnd(totalStates > 0 ? 1 : 0)
    }
  }
  const handleLoadClicked = () => onLoadHistoryClick(isSingle)

  return (
    <div className={classes.root}>
      <Collapse in={open} mountOnEnter unmountOnExit>
        <Paper className={classes.rangePickerContainer}>
          <div className={classes.gridColumnContainer}>
            <ModeRadioButtons
              singleView={isSingle}
              onChange={handleChange}
              rangeDisabled={totalStates <= 1}
            />
            <RangeFields
              showRange={!isSingle}
              totalTokens={totalStates > 0 ? totalStates - 1 : 0}
              rangeStart={rangeStart}
              setRangeStart={setRangeStart}
              rangeEnd={rangeEnd}
              setRangeEnd={setRangeEnd}
            />
            <TermsAndConditions />
            <Button
              size='medium'
              color='secondary'
              onClick={handleLoadClicked}
              className={classes.submitBtn}
            >
              Load history
            </Button>
          </div>
        </Paper>
      </Collapse>
      <DisplayToggle open={open} onClick={onExpandToggle} />
    </div>
  )
}

export default HistoryRangePicker
