import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  typographyLight: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 300
  }
}))

const TermsAndConditions = () => {
  const classes = useStyles()
  return (
    <Typography
      className={classes.typographyLight}
      variant='body1'
      component='p'
      align='justify'
    >
      <span>
        By using the History Viewer you agree to the{' '}
        <Link href='/terms' color='secondary'>
          Terms and Conditions
        </Link>
        .
      </span>
    </Typography>
  )
}

export default TermsAndConditions
