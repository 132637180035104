import Marketplace_data_source_class from '../datasource/marketplace_data_source.js'

class Purchase_sale_item_usecase {
  constructor () {
    this.MarketplaceDataSource = new Marketplace_data_source_class()
  }

  async execute (saleItemId, priceInWei, feeInWei) {
    return this.MarketplaceDataSource.purchaseSaleItem(
      saleItemId,
      priceInWei,
      feeInWei
    )
  }
}

export default Purchase_sale_item_usecase
