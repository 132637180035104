import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles(() => ({
  largeIcon: {
    height: 38,
    width: 38
  },
  controlsExpandIcon: {
    padding: '36px',
    alignSelf: 'center'
  }
}))

const Expand = ({ open, className }) => {
  if (open) return <ExpandLess className={className} />
  return <ExpandMore className={className} />
}

const DisplayToggle = ({ open, onClick }) => {
  const classes = useStyles()
  return (
    <IconButton
      aria-label='show/hide history picker'
      onClick={onClick}
      className={clsx(classes.controlsExpandIcon, classes.largeIcon)}
    >
      {<Expand open={open} className={classes.largeIcon} />}
    </IconButton>
  )
}

export default DisplayToggle
