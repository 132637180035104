import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import GetTokenClaimForAddressUsecase from '../../modules/blockchain/usecase/get_token_claim_for_address_usecase.js'
import Typography from '@material-ui/core/Typography'
import StyledDialog from '../../uicomponents/styled_dialog.js'
import clsx from 'clsx'
import _ from 'underscore'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '24px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  emptyViewContainer: {
    display: 'inline-block'
  },

  outerGlow: {
    boxShadow: '0px 0px 20px 3px ' + theme.palette.primary.main
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },

  paintLogo: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  paintInfoBg: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      ')',
    backgroundBlendMode: `normal`
  },
  paintInfoBgDisabled: {
    background:
      'radial-gradient(circle at top right,' +
      theme.palette.secondary.main +
      ' 30%, ' +
      theme.palette.primary.main +
      '), linear-gradient(to right, #54545f, #54545f)',
    backgroundBlendMode: `overlay, normal`
  }
}))

const DEFAULT_HELPER_TEXT = 'Click CHECK to check the entered address'

export default function ClaimTokensDialog (props) {
  const classes = useStyles()
  const getTokenClaimForAddressUsecase = new GetTokenClaimForAddressUsecase()

  const [paintTokenClaim, setPaintTokenClaim] = useState(null)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState(DEFAULT_HELPER_TEXT)

  const onCheckClicked = () => {
    checkAddressForClaim()
  }

  useEffect(() => {
    if (!_.isEmpty(props.claimAddress)) {
      checkAddressForClaim()
    }
  }, [props.open])

  const onAddressChanged = event => {
    var address = event.target.value
    if (address.length > 42) {
      address = address.slice(0, 42)
    }
    setErrorText(DEFAULT_HELPER_TEXT)
    setError(false)
    props.onAddressChanged(address)
  }
  const onClaimClicked = event => {
    props.onClaimClicked(paintTokenClaim)
  }

  const checkAddressForClaim = async () => {
    try {
      const claimData = await getTokenClaimForAddressUsecase.execute(
        props.claimAddress
      )

      setPaintTokenClaim(claimData)
      setErrorText('Claim available for ' + claimData.address + '!')
      setError(false)
    } catch (err) {
      setError(true)
      console.error(err)

      setPaintTokenClaim(null)
      setErrorText(err)
    }
  }

  return (
    <StyledDialog
      {...props}
      maxWidth={'sm'}
      withCloseButton={true}
      dialogTitle={'Claim PAINT tokens'}
      dialogContent={() => (
        <div className={clsx(classes.flexColumn)}>
          <div
            className={clsx(
              classes.flexRow,
              {
                [classes.paintInfoBg]: paintTokenClaim != null
              },
              {
                [classes.outerGlow]: paintTokenClaim != null
              },
              {
                [classes.paintInfoBgDisabled]: paintTokenClaim == null
              }
            )}
            style={{
              marginBottom: '36px',
              marginTop: '12px',
              padding: '24px',
              borderRadius: 10
            }}
          >
            <img
              src='images/paint_logo.png'
              alt='Paint logo'
              className={classes.paintLogo}
              style={{
                marginRight: '12px'
              }}
            />
            <div className={clsx(classes.flexColumn)}>
              <Typography
                component='h6'
                variant='h6'
                noWrap
                className={clsx(classes.typographyLight)}
                color='textPrimary'
                style={{
                  marginRight: '12px'
                }}
              >
                Available to claim:
              </Typography>
              <Typography
                component='h5'
                variant='h5'
                noWrap
                className={clsx(classes.typographyLight)}
                color='textPrimary'
              >
                {paintTokenClaim != null
                  ? `${paintTokenClaim.displayAmount} PAINT`
                  : `0 PAINT`}
              </Typography>
            </div>
          </div>
          <Typography
            component='body1'
            variant='body1'
            noWrap
            className={clsx(classes.typographyLight)}
            color='textPrimary'
          >
            Enter address to check for PAINT token claim:
          </Typography>
          <div className={clsx(classes.flexRow)}>
            <TextField
              style={{ flex: 1, alignSelf: 'center' }}
              label={'Address'}
              error={error}
              value={props.claimAddress}
              variant='outlined'
              helperText={errorText}
              onChange={onAddressChanged}
              margin='normal'
              InputProps={{
                endAdornment: (
                  <Button
                    size='medium'
                    color='secondary'
                    onClick={onCheckClicked}
                    variant='contained'
                  >
                    Check
                  </Button>
                )
              }}
            />
          </div>

          <Button
            size='large'
            color='secondary'
            onClick={onClaimClicked}
            disabled={paintTokenClaim == null}
            variant={paintTokenClaim != null ? 'contained' : 'outlined'}
            style={{ marginBottom: '12px', marginTop: '24px' }}
          >
            {paintTokenClaim != null
              ? `Claim ${paintTokenClaim.displayAmount} PAINT`
              : `Nothing to Claim`}
          </Button>
        </div>
      )}
      hideButtons
    />
  )
}
