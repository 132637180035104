'use strict'

import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import AccountButtonMobile from './accountButtonMobile.js'
import ConnectWalletButtonMobile from './connectWalletButtonMobile.js'
import { Link } from 'react-router-dom'
import StyledTooltip from '../../uicomponents/styled_tooltip.js'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'underscore'
import PaintButton from './paintButton.js'

const TOOLTIP_ENTER_DELAY_MILLIS = 400
const Z_INDEX_ALWAYS_ON_TOP = 999 // still below material UI stuf, which starts at z index 1000

const useStyles = makeStyles(theme => ({
  topNavButtons: {
    color: theme.palette.secondary.main,
    flex: '1 0 auto',
    alignSelf: 'center'
  },
  topNavContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  topRow: {
    marginRight: '8px',
    marginLeft: '16px',
    marginBottom: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  bottomRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  murAllLogo: {
    height: '100%',
    backgroundImage: 'url(images/murall_top_logo_mobile.svg)',
    backgroundSize: 'contain',
    backgroundClip: 'padding-box',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat'
  },
  murAllLogoLink: {
    alignSelf: 'stretch',
    flexGrow: 1,
    zIndex: Z_INDEX_ALWAYS_ON_TOP
  }
}))

export default function NavButtonsMobile (props) {
  const classes = useStyles()

  return (
    <div className={classes.topNavContainer}>
      <div className={classes.topRow}>
        <Link to='/home' className={classes.murAllLogoLink}>
          <div className={classes.murAllLogo} />
        </Link>

        {_.isNull(props.account) || _.isUndefined(props.account) ? (
          <ConnectWalletButtonMobile
            onConnectWalletClicked={props.onConnectWalletClicked}
            style={{
              alignSelf: 'end'
            }}
          />
        ) : (
          <AccountButtonMobile
            onClick={props.onAccountButtonClicked}
            address={props.account}
            style={{
              marginRight: '12px'
            }}
          />
        )}
        {!(_.isNull(props.account) || _.isUndefined(props.account)) && (
          <PaintButton
            onClick={props.onPaintTokenButtonClicked}
            paintBalance={props.paintBalance}
            style={{
              minWidth: '100px',
              marginRight: '8px'
            }}
          />
        )}
      </div>

      <div className={classes.bottomRow}>
        <ButtonGroup
          className={classes.topNavButtons}
          id='navButtons'
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            alignContent: 'center'
          }}
          size='small'
          color='primary'
          variant='text'
          aria-label='large outlined primary button group'
        >
          <StyledTooltip
            disableFocusListener
            title='Watch MurAll Live'
            arrow
            enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
          >
            <Button
              className={classes.topNavButtons}
              component={Link}
              to={'/live'}
            >
              Live
            </Button>
          </StyledTooltip>
          <StyledTooltip
            disableFocusListener
            title='View MurAlls history'
            arrow
            enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
          >
            <Button
              className={classes.topNavButtons}
              component={Link}
              to={'/history'}
            >
              History
            </Button>
          </StyledTooltip>

          <StyledTooltip
            disableFocusListener
            title='Draw on MurAll'
            arrow
            enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
          >
            <Button
              className={classes.topNavButtons}
              component={Link}
              to={'/draw'}
            >
              Draw
            </Button>
          </StyledTooltip>
          <StyledTooltip
            disableFocusListener
            title='Trade Nfts and PAINT'
            arrow
            enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
          >
            <Button
              className={classes.topNavButtons}
              component={Link}
              to={'/trade'}
            >
              Trade
            </Button>
          </StyledTooltip>
          <StyledTooltip
            disableFocusListener
            title='Statistics on MurAll'
            arrow
            enterDelay={TOOLTIP_ENTER_DELAY_MILLIS}
          >
            <Button
              className={classes.topNavButtons}
              component={Link}
              to={'/stats'}
            >
              stats
            </Button>
          </StyledTooltip>
        </ButtonGroup>
      </div>
    </div>
  )
}
