import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const VALUES = {
  SINGLE: 'single',
  RANGE: 'range'
}

const TypeChooser = ({ singleView, onChange, rangeDisabled }) => {
  const value = singleView ? VALUES.SINGLE : VALUES.RANGE
  return (
    <FormControl component='fieldset' style={{ marginBottom: '24px' }}>
      <FormLabel component='legend'>Select history:</FormLabel>
      <RadioGroup
        row
        aria-label='type'
        name='picker-type'
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          value={VALUES.SINGLE}
          control={<Radio />}
          label='at a point'
        />
        <FormControlLabel
          disabled={rangeDisabled}
          value={VALUES.RANGE}
          control={<Radio />}
          label='between a range'
        />
      </RadioGroup>
    </FormControl>
  )
}

export default TypeChooser
