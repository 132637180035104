import Erc20_data_source_class from '../datasource/erc20_data_source.js'
import GetPaintTokenInitialSupplyUsecase from './get_paint_token_initial_supply_usecase.js'
import config from './../../../config.js'
import { BigNumber } from 'bignumber.js'

class GetPaintTokenRemainingSupplyUsecase {
  constructor () {
    this.Erc20DataSource = new Erc20_data_source_class()
    this.getPaintTokenInitialSupplyUsecase = new GetPaintTokenInitialSupplyUsecase()
  }

  async execute () {
    const totalSupply = await this.Erc20DataSource.getTotalSupply(
      config.paintTokenAddress
    )
    const stuckTokens = await this.Erc20DataSource.getBalanceForAddress(
      config.paintTokenAddress,
      config.paintTokenAddress
    )

    const realRemainingSupply = config.web3.utils
      .toBN(totalSupply)
      .sub(config.web3.utils.toBN(stuckTokens))

    BigNumber.set({ DECIMAL_PLACES: 4, ROUNDING_MODE: 3 })

    const totalSupplyBigNum = new BigNumber(
      config.web3.utils.fromWei(realRemainingSupply, 'ether')
    )

    const initialSupplyBigNum = new BigNumber(
      config.web3.utils.fromWei(
        this.getPaintTokenInitialSupplyUsecase.execute(),
        'ether'
      )
    )
    const totalSupplyPercentage = totalSupplyBigNum
      .dividedBy(initialSupplyBigNum)
      .times(100.0)

    console.log(totalSupplyBigNum)
    console.log(totalSupplyPercentage)
    return {
      remainingSupply: realRemainingSupply,
      percentage: totalSupplyPercentage
    }
  }
}

export default GetPaintTokenRemainingSupplyUsecase
