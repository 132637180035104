import { gsap, SteppedEase } from 'gsap'
import React, { useRef, useEffect } from 'react'
import _ from 'underscore'

const DEFAULT_FADE_DURATION_SECONDS = 18
const DEFAULT_EASE = 'sine.inOut'
const SCALE_MAX = 1.5
const SCALE_MID = 1.25
const SCALE_MIN = 1

const X_MAX = 20
const X_MID = 10
const X_MIN = 0

const Y_MAX = 14
const Y_MID = 7
const Y_MIN = 0

export default function KenBurnsImage (props) {
  const loaderContainer = useRef(null)

  const timeline = gsap.timeline({ repeat: -1, yoyo: true })

  useEffect(() => {
    // Call handler right away so state gets updated with initial window size
    startAnimation()

    return () => {
      timeline.clear()
    }
  }, []) // Empty array ensures that effect is only run on mount

  const startAnimation = () => {
    if (!_.isNull(loaderContainer.current)) {
      timeline
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -X_MAX,
          yPercent: -Y_MAX,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: X_MID,
          yPercent: Y_MID,
          scale: SCALE_MID,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -X_MAX,
          yPercent: Y_MAX,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -X_MAX,
          yPercent: -Y_MAX,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: X_MAX,
          yPercent: -Y_MAX,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: X_MAX,
          yPercent: Y_MAX,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: X_MAX,
          yPercent: Y_MIN,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -X_MAX,
          yPercent: Y_MIN,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: -X_MID,
          yPercent: -Y_MID,
          scale: SCALE_MID,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: X_MAX,
          yPercent: -Y_MAX,
          scale: SCALE_MAX,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
        .to(loaderContainer.current, {
          duration: DEFAULT_FADE_DURATION_SECONDS,
          xPercent: X_MIN,
          yPercent: Y_MIN,
          scale: SCALE_MIN,
          ease: DEFAULT_EASE,
          repeatRefresh: true
        })
    }
  }
  return <img {...props} src={props.image} ref={loaderContainer} />
}
