import Erc20_data_source_class from '../datasource/erc20_data_source.js'

class Allow_erc20_transfer_from_address_for_user_account_usecase {
  constructor () {
    this.Erc20DataSource = new Erc20_data_source_class()
  }

  async execute (erc20TokenContractAddress, spenderAddress) {
    return this.Erc20DataSource.approveTokenTransferMax(
      erc20TokenContractAddress,
      spenderAddress
    )
  }
}

export default Allow_erc20_transfer_from_address_for_user_account_usecase
