import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'

class Get_nft_balance_for_user_account_usecase {
  constructor () {
    this.NftDataSource = new Blockchain_nft_data_source_class()
    this.Helper = new Helper_class()
  }

  async execute () {
    return this.NftDataSource.getNftBalance()
  }
}

export default Get_nft_balance_for_user_account_usecase
