import LegalDataSource_class from '../datasource/legal_data_source.js'

class SetUserHasAgreedToLegalDisclaimerUsecase {
  constructor () {
    this.legalDataSource = new LegalDataSource_class()
  }

  execute (agreed, store) {
    this.legalDataSource.setUserAgreedToLegalDisclaimer(agreed, store)
  }
}
export default SetUserHasAgreedToLegalDisclaimerUsecase
