'use strict'

import React from 'react'
import Get_nft_transaction_data_usecase from '../../modules/blockchain/usecase/get_nft_transaction_data_usecase.js'
import Get_wallet_connected_state_usecase from '../../modules/blockchain/usecase/get_wallet_connected_state_usecase.js'
import Get_is_current_network_supported_usecase from '../../modules/blockchain/usecase/get_is_current_network_supported_usecase.js'
import Get_nft_balance_for_user_account_usecase from '../../modules/blockchain/usecase/get_nft_balance_for_user_account_usecase.js'
import Get_nft_data_for_owned_tokens_at_index_range_usecase from '../../modules/blockchain/usecase/get_nft_data_for_owned_tokens_at_index_range_usecase.js'
import Fill_minted_nft_usecase from '../../modules/blockchain/usecase/fill_minted_token_usecase.js'
import Marketplace_data_source_class from './../../modules/blockchain/datasource/marketplace_data_source.js'
import config from './../../config.js'
import Helper_class from './../../libs/helpers.js'
import _ from 'underscore'
import NftCard from './nftCard.js'
import LoadingSpinner from './../../uicomponents/loading_spinner.js'
import LoadingDialog from './../../uicomponents/loading_dialog.js'
import StyledDialog from './../../uicomponents/styled_dialog.js'
import EmptyNftsMessageView from './../../uicomponents/empty_nfts_message_view.js'
import NetworkErrorMessageView from './../../uicomponents/network_error_message_view.js'
import ConnectWalletMessageView from './../../uicomponents/connect_wallet_message_view.js'
import Grid from '@material-ui/core/Grid'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { withSnackbar } from 'notistack'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = theme => ({
  root: {
    padding: '24px',
    flexGrow: 1
  },
  glow: {
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3'
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  fixedContainer: {
    display: 'flex',
    padding: '12px',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0
  },
  pagination: {
    padding: '3px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '48px',
    alignSelf: 'center',
    borderRadius: 28,
    background: `#26262B`
  }
})

const ITEMS_PER_PAGE = 9.0

class ViewNfts extends React.Component {
  constructor (props) {
    super(props)
    this.Helper = new Helper_class()
    this.MarketplaceDataSource = new Marketplace_data_source_class()
    this.getNftTransactionDataUseCase = new Get_nft_transaction_data_usecase()
    this.getWalletConnectedStateUseCase = new Get_wallet_connected_state_usecase()
    this.getIsCurrentNetworkSupportedUseCase = new Get_is_current_network_supported_usecase()
    this.getNftBalanceForUserAccountUsecase = new Get_nft_balance_for_user_account_usecase()
    this.getNftDataInIndexRangeUsecase = new Get_nft_data_for_owned_tokens_at_index_range_usecase()
    this.fillNftUseCase = new Fill_minted_nft_usecase()

    this.state = {
      isConnected: false,
      networkError: false,
      isFetching: false,
      isFetchingTokenData: false,
      isTransacting: false,
      transferApprovalDialogOpen: false,
      sellPriceInputDialogOpen: false,
      selectedTokenId: null,
      salePrice: null,
      nftBalance: 0,
      totalPageCount: 0,
      page: 1,
      nfts: []
    }
  }

  handleFillClick (param) {
    this.fillMintedTokenForId(param.tokenId)
  }

  handleApproveClicked (param) {
    this.setState({ transferApprovalDialogOpen: false })
    this.approveTokenTransfer()
  }

  async handleSellClick (param) {
    window.open(
      'https://opensea.io/assets/0x5bb3d01f32687f92f8636799687e55cba0627704/' +
        param.tokenId,
      '_blank'
    )
  }

  handleWithdrawClick (param) {
    this.setState({ isFetchingTokenData: true })
    this.withdrawTokenSaleForId(param)
  }

  handleSellPriceChange (event) {
    const price = event.target.value === '' ? 0 : Number(event.target.value)

    this.setState({
      salePrice: price
    })
  }

  handleApprovalDialogClose () {
    this.setState({ transferApprovalDialogOpen: false })
  }

  handleSellPriceInputDialogClose () {
    this.setState({ sellPriceInputDialogOpen: false })
  }

  handleApprovalNeeded () {
    this.setState({
      isFetchingTokenData: false,
      transferApprovalDialogOpen: true
    })
  }

  handleSellPriceInput (tokenId) {
    this.setState({
      isFetchingTokenData: false,
      sellPriceInputDialogOpen: true,
      selectedTokenId: tokenId
    })
  }
  handleSellWithPriceClicked () {
    this.sellTokenForId(this.state.selectedTokenId, this.state.salePrice)
  }

  componentDidMount () {
    this.checkWalletConnectionThenFetch()

    const container = document.querySelector('#top_buttons_container')
    container.addEventListener(
      'accountsChanged',
      this.onAccountsChanged.bind(this),
      false
    )
    container.addEventListener(
      'networkChanged',
      this.onNetworkChanged.bind(this),
      false
    )
  }

  componentWillUnmount () {
    const container = document.querySelector('#top_buttons_container')
    container.removeEventListener(
      'accountsChanged',
      this.onAccountsChanged,
      false
    )
    container.removeEventListener(
      'networkChanged',
      this.onNetworkChanged,
      false
    )
  }

  onAccountsChanged (e) {
    this.checkWalletConnectionThenFetch()
  }

  onNetworkChanged (e) {
    this.setState({ networkError: !e.detail.isSupported })
    if (e.detail.isSupported) {
      this.checkWalletConnectionThenFetch()
    }
  }

  onPageChanged (event, value) {
    this.setState({
      page: value
    })
    this.fetchTokensForPage(value)
  }

  render () {
    const { classes } = this.props
    if (this.state.isFetching) {
      return <LoadingSpinner />
    } else if (this.state.networkError) {
      return <NetworkErrorMessageView />
    } else if (!this.state.isConnected) {
      return <ConnectWalletMessageView />
    } else {
      var _this = this
      return _.isEmpty(this.state.nfts) && !this.state.isFetchingTokenData ? (
        <EmptyNftsMessageView />
      ) : (
        <div className={clsx(classes.absoluteFill, classes.root)}>
          <Grid
            container
            spacing={4}
            direction='row'
            justify='space-around'
            alignItems='center'
            style={{ paddingBottom: '48px' }}
          >
            {Object.keys(this.state.nfts).map(key => {
              return (
                <Grid
                  key={this.state.nfts[key].tokenId}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={3}
                  align='center'
                >
                  {_this.constructNftView(this.state.nfts[key])}
                </Grid>
              )
            })}
          </Grid>
          {this.state.isFetchingTokenData && <LoadingSpinner />}
          <LoadingDialog open={this.state.isTransacting} />
          {this.constructTokenTransferApprovalDialog()}
          {this.constructSellPriceInputDialog()}

          {!this.state.isFetchingTokenData && (
            <div className={clsx(classes.fixedContainer)}>
              {this.state.totalPageCount > 0 && (
                <Pagination
                  className={clsx(classes.pagination, classes.glow)}
                  count={this.state.totalPageCount}
                  page={this.state.page}
                  color='secondary'
                  onChange={this.onPageChanged.bind(this)}
                  size='large'
                />
              )}
            </div>
          )}
        </div>
      )
    }
  }

  constructNftView (currentNft) {
    return (
      <NftCard
        key={currentNft.tokenId}
        nftInformation={currentNft}
        onSellButtonClick={this.handleSellClick.bind(this)}
        onFillClick={this.handleFillClick.bind(this)}
        onWithdrawButtonClick={this.handleWithdrawClick.bind(this)}
      ></NftCard>
    )
  }

  constructTokenTransferApprovalDialog () {
    const { classes } = this.props
    return (
      <StyledDialog
        open={this.state.transferApprovalDialogOpen}
        onClose={this.handleApprovalDialogClose.bind(this)}
        dialogTitle={'Token transfer approval required'}
        dialogContent={() => (
          <DialogContentText id='alert-dialog-description'>
            In order to list your tokens on the marketplace, you must allow the
            transfer of your tokens to the marketplace. Proceed?
          </DialogContentText>
        )}
        positiveButtonTitle={'Approve'}
        onPositiveButtonClick={this.handleApproveClicked.bind(this)}
        negativeButtonTitle={'Cancel'}
        onNegativeButtonClick={this.handleApprovalDialogClose.bind(this)}
      />
    )
  }

  constructSellPriceInputDialog () {
    const { classes } = this.props
    return (
      <StyledDialog
        open={this.state.sellPriceInputDialogOpen}
        onClose={this.handleSellPriceInputDialogClose.bind(this)}
        dialogTitle={'Sell price'}
        dialogContent={() => (
          <div>
            <DialogContentText id='alert-dialog-description'>
              How much (in ETH) do you want to list your artwork for?
            </DialogContentText>
            <TextField
              autoFocus
              label='Price'
              variant='outlined'
              helperText={'In ETH'}
              onChange={this.handleSellPriceChange.bind(this)}
              inputProps={{
                type: 'number'
              }}
              fullWidth
            />
          </div>
        )}
        positiveButtonTitle={'Sell'}
        onPositiveButtonClick={this.handleSellWithPriceClicked.bind(this)}
        negativeButtonTitle={'Cancel'}
        onNegativeButtonClick={this.handleSellPriceInputDialogClose.bind(this)}
      />
    )
  }

  async checkWalletConnectionThenFetch () {
    const supportedResult = await this.getIsCurrentNetworkSupportedUseCase.execute()
    if (!supportedResult.isSupported) {
      this.setState({
        isFetching: false,
        isFetchingTokenData: false,
        networkError: true
      })
      return
    }
    this.setState({ isFetching: true })

    const connected = this.getWalletConnectedStateUseCase.execute()
    if (connected) {
      this.setState({ isConnected: true, networkError: false })
      this.initialiseNftInformation()
    } else {
      this.setState({
        isFetching: false,
        isConnected: connected,
        networkError: !supportedResult.isSupported
      })
    }
  }

  async initialiseNftInformation () {
    const nftBalance = await this.getNftBalanceForUserAccountUsecase.execute()
    const totalPageCount = Math.ceil(nftBalance / ITEMS_PER_PAGE)

    this.setState({
      nftBalance: nftBalance,
      totalPageCount: totalPageCount,
      isFetching: false,
      isFetchingTokenData: true
    })
    if (nftBalance > 0) {
      this.fetchTokensForPage(this.state.page)
    } else {
      this.setState({
        isFetching: false,
        isFetchingTokenData: false,
        nfts: []
      })
      this.props.enqueueSnackbar(
        'You do not own any MurAll NFTs - try drawing something or go to the marketplace!',
        {
          variant: 'error'
        }
      )
    }
  }

  async fetchTokensForPage (page) {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    let endIndex = startIndex + ITEMS_PER_PAGE - 1
    if (endIndex >= this.state.nftBalance) {
      endIndex = this.state.nftBalance - 1
    }

    this.setState({ isFetchingTokenData: true, nfts: [] })
    const myNfts = await this.getNftDataInIndexRangeUsecase.execute(
      startIndex,
      endIndex
    )

    if (_.isEmpty(myNfts)) {
      this.setState({ isFetchingTokenData: false, nfts: [] })
      this.props.enqueueSnackbar(
        'You do not own any MurAll NFTs - try drawing something or go to the marketplace!',
        {
          variant: 'error'
        }
      )
    } else {
      this.setState({ isFetchingTokenData: false, nfts: myNfts })
    }
  }

  async sellTokenForId (id, priceInEth) {
    this.setState({ isTransacting: true })

    try {
      await this.MarketplaceDataSource.listItemForSale(
        id,
        config.nftContractAddress,
        priceInEth
      )
      this.setState({ isTransacting: false })
      this.props.enqueueSnackbar(
        'Token id ' + id + ' is on sale for ' + priceInEth + '!',
        {
          variant: 'success'
        }
      )
    } catch (error) {
      this.setState({
        isTransacting: false,
        sellPriceInputDialogOpen: false
      })

      if (error.message.indexOf('User denied') != -1) {
        this.props.enqueueSnackbar(
          'You rejected the transaction on Metamask!',
          {
            variant: 'error'
          }
        )
      } else {
        this.props.enqueueSnackbar(
          'Failure: Token id ' + id + ' is not on sale',
          {
            variant: 'error'
          }
        )
      }
    }
  }

  async withdrawTokenSaleForId (id) {
    this.setState({ isTransacting: true })

    try {
      await this.MarketplaceDataSource.withdrawSalesItemForId(id)
      this.setState({ isTransacting: false })
      this.props.enqueueSnackbar('Sale withdrawn!', {
        variant: 'success'
      })
    } catch (error) {
      this.setState({
        isTransacting: false,
        sellPriceInputDialogOpen: false
      })

      if (error.message.indexOf('User denied') != -1) {
        this.props.enqueueSnackbar(
          'You rejected the transaction on Metamask!',
          {
            variant: 'error'
          }
        )
      } else {
        this.props.enqueueSnackbar('Failure: withdraw failed', {
          variant: 'error'
        })
      }
    }
  }

  async approveTokenTransfer () {
    this.setState({ isTransacting: true })
    try {
      await this.MarketplaceDataSource.approveTokenTransfer(
        config.nftContractAddress
      )

      this.props.enqueueSnackbar(
        'Transfer approved - tokens from MurAll can now be listed!',
        {
          variant: 'success'
        }
      )
    } catch (error) {
      if (error.message.indexOf('User denied') != -1) {
        this.props.enqueueSnackbar(
          'You rejected the transaction on Metamask!',
          {
            variant: 'error'
          }
        )
      } else {
        this.props.enqueueSnackbar('Failure: transfer approval failed', {
          variant: 'error'
        })
      }
    }
    this.setState({ isTransacting: false })
  }

  async fillMintedTokenForId (id) {
    this.setState({ isTransacting: true })

    try {
      const tokenInfo = await this.getNftTransactionDataUseCase.execute(id)
      await this.fillMintedToken(
        tokenInfo.tokenId,
        tokenInfo.colorIndex,
        tokenInfo.pixelData,
        tokenInfo.pixelGroups,
        tokenInfo.pixelGroupIndexes,
        tokenInfo.transparentPixelGroups,
        tokenInfo.transparentPixelGroupIndexes
      )
    } catch (error) {
      this.props.enqueueSnackbar(
        'Sorry, there was a problem finding the token data',
        {
          variant: 'error'
        }
      )
    }
    this.setState({ isTransacting: false })
  }

  async fillMintedToken (
    id,
    colorIndex,
    blockchainPixelData,
    blockchainPixelGroupData,
    blockchainPixelGroupIndexData,
    blockchainTransparentPixelGroupData,
    blockchainTransparentPixelGroupIndexData
  ) {
    try {
      const isFilled = await this.fillNftUseCase.execute(
        id,
        colorIndex,
        blockchainPixelData,
        blockchainPixelGroupData,
        blockchainPixelGroupIndexData,
        blockchainTransparentPixelGroupData,
        blockchainTransparentPixelGroupIndexData
      )
      if (isFilled) {
        this.props.enqueueSnackbar('Filled data for token id ' + id, {
          variant: 'success'
        })
        this.fetchTokensForPage(this.state.page)
      } else {
        await this.fillMintedToken(
          id,
          colorIndex,
          blockchainPixelData,
          blockchainPixelGroupData,
          blockchainPixelGroupIndexData,
          blockchainTransparentPixelGroupData,
          blockchainTransparentPixelGroupIndexData
        )
      }
    } catch (error) {
      if (error.message.indexOf('User denied') != -1) {
        this.props.enqueueSnackbar(
          'You rejected the transaction on Metamask!',
          {
            variant: 'error'
          }
        )
      } else {
        this.props.enqueueSnackbar('Data fill failed for token id ' + id, {
          variant: 'error'
        })
      }
      console.error(error)
      this.setState({
        isFetchingTokenData: false,
        isFetching: false,
        isTransacting: false
      })
    }
  }
}

export default withSnackbar(
  withStyles(useStyles, { withTheme: true })(ViewNfts)
)
