import LegalDataSource_class from '../datasource/legal_data_source.js'

class GetHasAgreedToLegalDisclaimerUsecase {
  constructor () {
    this.legalDataSource = new LegalDataSource_class()
  }

  execute () {
    return this.legalDataSource.getHasUserAgreedToLegalDisclaimer()
  }
}
export default GetHasAgreedToLegalDisclaimerUsecase
