import Marketplace_data_source_class from '../datasource/marketplace_data_source.js'

class Withdraw_seller_balance_for_user_account_usecase {
  constructor () {
    this.MarketplaceDataSource = new Marketplace_data_source_class()
  }

  async execute () {
    return this.MarketplaceDataSource.withdrawFullSellerBalanceForAccount()
  }
}

export default Withdraw_seller_balance_for_user_account_usecase
