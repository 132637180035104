import Blockchain_MurAll_state_data_source_class from '../datasource/murall_state_s3_data_source.js'
import _ from 'underscore'

class Get_total_MurAll_state_count_usecase {
  constructor () {
    this.MurAllStateDataSource = new Blockchain_MurAll_state_data_source_class()
  }

  async execute () {
    const totalChanges = await this.MurAllStateDataSource.getTotalStateChanges()
    return totalChanges >= 0 ? totalChanges : 0
  }
}
export default Get_total_MurAll_state_count_usecase
