import config from '../../../config'
import Helper_class from '../../../libs/helpers'
import Blockchain_Nft_data_mapper_class from '../nft_data_mapper'
import { omit, isUndefined } from 'underscore'
import axios from 'axios'

const async = require('async')
/**
 * S3 bucket source for MurAll state data
 *
 * @author TheKeiron
 */
class Blockchain_MurAll_state_s3_data_source_class {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
  }

  getTotalStateChanges () {
    return config.nftContract.methods.totalSupply().call()
  }

  async getPresignedUrl (type, tokenId) {
    const result = await axios.get(config.aws.s3.presignedApi, {
      params: omit({ type, token: tokenId }, isUndefined)
    })
    return result.data
  }

  async getImg (url) {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.src = url
    try {
      await img.decode()
      return img
    } catch (error) {
      console.error('Failed to fetch state: ', error)
      return new Image()
    }
  }

  async fetchStateData () {
    const url = await this.getPresignedUrl('state')
    return this.getImg(url)
  }

  async getLastBlock () {
    const url = await this.getPresignedUrl('marker')
    const response = await fetch(url)
    if (!response.ok) return 0
    const result = await response.text()
    return result && parseInt(result)
  }

  async fetchStateDataForTokenId (tokenId) {
    const url = await this.getPresignedUrl('history', tokenId)
    return this.getImg(url)
  }

  async getCurrentMurAllStateData () {
    const [stateData, lastBlock] = await Promise.all([
      this.fetchStateData(),
      this.getLastBlock()
    ])

    if (lastBlock) {
      const newEvents = await config.smartContract.getPastEvents('Painted', {
        fromBlock: lastBlock
      })
      if (newEvents && newEvents.length)
        return this.putImgToCanvas(stateData, newEvents)
    }
    return this.putImgToCanvas(stateData)
  }

  async getMurAllStateDataAtId (tokenId) {
    try {
      const stateData = await this.fetchStateDataForTokenId(tokenId)
      return this.putImgToCanvas(stateData)
    } catch (error) {
      return this.Helper.createOffscreenCanvas(config.WIDTH, config.HEIGHT)
    }
  }

  async addNewEvents (canvas, events) {
    const ctx = canvas.getContext('2d')
    await async.eachSeries(events, async (event, callback) => {
      const img = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)
      const tokenInfo = event.returnValues
      const metadata = this.NftDataMapper.processOtherMetadata(
        tokenInfo.metadata[1]
      )
      const canvasBuffer = await this.NftDataMapper.drawNftDataToCanvasBuffer(
        img.data.buffer,
        tokenInfo.colorIndex,
        tokenInfo.pixelData,
        tokenInfo.pixelGroups,
        tokenInfo.pixelGroupIndexes,
        tokenInfo.transparentPixelGroups,
        tokenInfo.transparentPixelGroupIndexes,
        metadata.hasAlpha
      )

      const arr = new Uint8ClampedArray(canvasBuffer)
      let processedImageData
      try {
        processedImageData = new ImageData(arr, config.WIDTH, config.HEIGHT)
      } catch (e) {
        processedImageData = ctx.createImageData(config.WIDTH, config.HEIGHT)
        processedImageData.data.set(arr)
      }

      ctx.putImageData(processedImageData, 0, 0)
      callback()
    })
    return canvas
  }

  async putImgToCanvas (img, events) {
    const offScreenCanvas = this.Helper.createOffscreenCanvas(
      config.WIDTH,
      config.HEIGHT
    )
    const ctx = offScreenCanvas.getContext('2d')
    ctx.drawImage(img, 0, 0)
    if (events) return this.addNewEvents(offScreenCanvas, events)
    return offScreenCanvas
  }
}

export default Blockchain_MurAll_state_s3_data_source_class
