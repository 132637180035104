'use strict'

import _ from 'underscore'
import gsap from 'gsap'
import ImageViewerCanvas from '../../uicomponents/image_viewer_canvas.js'

class LiveStateViewerCanvas extends ImageViewerCanvas {
  constructor (props) {
    super(props)
  }

  async componentDidUpdate (prevProps) {
    await super.componentDidUpdate(prevProps)

    if (
      prevProps.states !== this.props.states &&
      this.props.baseStateId !== this.props.currentSelectedState
    ) {
      // A new state has arrived! Animate it in
      const timeline = gsap.timeline()
      this.animateLayerIn(
        this.props.currentSelectedState.toString(),
        timeline,
        this.props.onAnimationEnd
      )
    }
  }
}

export default LiveStateViewerCanvas
