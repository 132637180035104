import Erc20_data_source_class from '../datasource/erc20_data_source.js'

class GetErc20TotalSupplyUsecase {
  constructor () {
    this.Erc20DataSource = new Erc20_data_source_class()
  }

  async execute (erc20TokenContractAddress) {
    return this.Erc20DataSource.getTotalSupply(erc20TokenContractAddress)
  }
}

export default GetErc20TotalSupplyUsecase
