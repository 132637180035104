import React from 'react'
import Helper_class from '../../libs/helpers.js'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3'
  },
  media: {
    backgroundColor: theme.palette.primary.dark,
    height: 0,
    paddingTop: '40%'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  colouredLineBorder: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 10
  }
}))

export default function TradeInfoCard (props) {
  const classes = useStyles()
  const Helper = new Helper_class()

  return (
    <Card className={classes.root} elevation={10}>
      <CardMedia
        className={clsx(classes.media)}
        image={props.image}
        title={props.title}
        alt={props.title}
      />
      <CardContent>{props.cardContent()}</CardContent>

      <CardActions className={clsx(classes.cardActions)}>
        <div
          className={clsx(classes.flexColumn, classes.colouredLineBorder)}
          style={{
            margin: '24px',
            padding: '24px'
          }}
        >
          <div className={clsx(classes.flexColumn)}>
            <Typography
              component='h5'
              variant='h5'
              align='center'
              className={clsx(classes.typographyLight)}
              color='textPrimary'
              style={{
                margin: '12px'
              }}
            >
              Available markets:
            </Typography>
          </div>
          {props.availableMarketButtons()}
        </div>
      </CardActions>
    </Card>
  )
}
