import React from 'react'
import { TextField } from '@material-ui/core'

const Field = ({
  value,
  label,
  helpText,
  onChange,
  onBlur,
  min,
  max,
  className
}) => {
  return (
    <TextField
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      value={value.toString()}
      defaultValue={min}
      variant='outlined'
      helperText={helpText}
      inputProps={{
        step: 1,
        min,
        max,
        type: 'number',
        'aria-labelledby': 'input-slider'
      }}
    />
  )
}

export default Field
