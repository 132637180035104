import React from 'react'
import ErrorMessageView from './error_message_view.js'

export default function EmptyNftsMessageView () {
  return (
    <ErrorMessageView
      title={'You do not own any MurAll NFTs!'}
      description={'Try drawing something or go to the marketplace'}
    />
  )
}
