import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitle: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  dialogTitleFlex: {
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '8px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  typography: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Roboto',
    fontWeight: 100
  }
}))

export default function StyledDialog (props) {
  const classes = useStyles()

  return (
    <Dialog
      {...props}
      fullWidth={true}
      maxWidth={props.maxWidth ? props.maxWidth : 'md'}
      PaperProps={{
        style: { borderRadius: 10 }
      }}
    >
      {props.withCloseButton ? (
        <DialogTitle
          disableTypography={true}
          className={classes.dialogTitleFlex}
        >
          <h2 className={classes.typography}>{props.dialogTitle}</h2>
          <IconButton onClick={props.onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <DialogTitle disableTypography={true} className={classes.dialogTitle}>
          <Typography
            component='h5'
            variant='h5'
            noWrap
            className={clsx(classes.typography)}
            color='textPrimary'
          >
            {props.dialogTitle}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent className={classes.dialogContent}>
        {props.dialogContent()}
      </DialogContent>
      {props.hideButtons ? null : (
        <DialogActions className={classes.dialogActions}>
          <Button
            size='small'
            color='secondary'
            onClick={props.onPositiveButtonClick}
          >
            {props.positiveButtonTitle}
          </Button>
          {props.onNegativeButtonClick === undefined ? null : (
            <Button
              size='small'
              color='secondary'
              onClick={props.onNegativeButtonClick}
            >
              {props.negativeButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
