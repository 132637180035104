import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  media: {
    height: 0,
    paddingTop: '25%',
    width: 0,
    paddingRight: '25%'
  },
  grayscale: {
    filter: 'grayscale()'
  },
  typography: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    width: '100%',
    justifyContent: 'center',
    "& p": {
      marginLeft: 10
    }
  },
  body: {
    fontSize: 16
  },
  cardActions: {
    backgroundColor: theme.palette.secondary.dark
  },
  flexColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  typographyBold: {
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  outerGlow: {
    boxShadow: '0px 0px 20px 3px #7e0ae5'
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },

  paintLogo: {
    width: theme.spacing(45),
    height: theme.spacing(45)
  },
  paintInfoBg: {
    background: `radial-gradient(circle at top right,
            #E91BFA 30%,
            #7E0AE5)`,
    backgroundBlendMode: `normal`
  }
}))

const createTextRow = (classes, key, value, marginBottomVal = '12px') => (
  <div
    className={clsx(classes.textRow)}
    style={{
      marginBottom: marginBottomVal
    }}
  >
    <Typography
      className={clsx(classes.typographyBold)}
      align='left'
      variant='h6'
      component='p'
    >
      {key}:
    </Typography>
    <Typography
      className={clsx(classes.typographyLight)}
      variant='h6'
      align='right'
      component='p'
    >
      {value}
    </Typography>
  </div>
)

export default function PaintStatsInfoCard (props) {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, classes.flexColumn)}
      style={{
        paddingTop: '24px'
      }}
    >
      <div
        className={clsx(classes.flexColumn)}
        style={{
          padding: '24px'
        }}
      >
        {createTextRow(classes, 'Original Paint Supply', `${props.originalSupply} PAINT`)}
        {createTextRow(classes, 'Remaining Paint Supply', `${props.totalSupply} PAINT (${props.totalSupplyPercent}%)`)}
        {createTextRow(classes, 'Circulating Paint Supply', `${props.circulatingSupply} PAINT`)}
        {createTextRow(classes, 'Paint Burned', `${props.paintBurned} PAINT`)}
        {createTextRow(classes, 'Number of Drawings on MurAll', props.totalDrawings)}
        {createTextRow(classes, 'Number of Artists', props.totalArtists)}

        <div
          style={{
            paddingTop: '12px',
            display: 'flex',
            flexDirection: 'row',
            flex: 'auto',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Typography
            className={clsx(classes.typographyLight)}
            align='center'
            variant='h6'
            component='p'
          >
            More stats on MurAll available on{' '}
            <Link
              component={'a'}
              target='_blank'
              href={'https://duneanalytics.com/pillieshwar/murall'}
              color='secondary'
            >
              Dune Analytics
            </Link>
          </Typography>
        </div>
      </div>

      <img
        style={{
          width: '100%',
          alignSelf: 'flex-end'
        }}
        src={'images/Painters4Life.png'}
      />
    </div>
  )
}
