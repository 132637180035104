import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'

class Get_nft_data_for_owned_token_at_index_account_usecase {
  constructor () {
    this.NftDataSource = new Blockchain_nft_data_source_class()
    this.Helper = new Helper_class()
  }

  async execute (index) {
    return this.NftDataSource.fetchNftDataForAccountForOwnedTokenAtIndex(index)
  }
}

export default Get_nft_data_for_owned_token_at_index_account_usecase
