import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { useState } from 'react'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection.js'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '24px'
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },

  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  selectedBackground: {
    backgroundColor: theme.palette.primary.main
  },
  heading: {
    marginTop: '48px',
    marginBottom: '48px'
  },
  accordian: {
    flex: 1,
    width: '100%',
    transition: 'all 300ms'
  },
  accordianDetails: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px'
  },
  emptyViewContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bigScreenPadding: {
    padding: '48px'
  },
  smallScreenPadding: {
    padding: '0px'
  }
}))

export default function FaqPage (props) {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  const smallScreen = useSmallScreenDetection()

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const createAccordianForSection = (question, answer, id) => {
    return (
      <Accordion
        className={clsx(
          classes.accordian,
          { [classes.transparent]: expanded !== id },
          { [classes.selectedBackground]: expanded === id }
        )}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h6'
            component='h6'
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            className={clsx(classes.typographyLight, classes.accordianDetails)}
            color='textSecondary'
            align='justify'
          >
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    )
  }

  const createAccordianForSectionCustom = (question, answer, id) => {
    return (
      <Accordion
        className={clsx(
          classes.accordian,
          { [classes.transparent]: expanded !== id },
          { [classes.selectedBackground]: expanded === id }
        )}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            className={clsx(classes.typographyLight)}
            variant='h6'
            component='h6'
          >
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{answer()}</AccordionDetails>
      </Accordion>
    )
  }

  const createTitleForSection = title => {
    return (
      <Typography
        className={clsx(classes.typographyLight, classes.heading)}
        variant='h4'
        component='h4'
        color='textPrimary'
      >
        {title}
      </Typography>
    )
  }

  return (
    <div
      className={clsx(
        classes.emptyViewContainer,
        classes.root,
        { [classes.bigScreenPadding]: !smallScreen },
        { [classes.smallScreenPadding]: smallScreen }
      )}
    >
      {/* MurAll section ****************************/}
      {createTitleForSection('MurAll')}
      <br></br>
      {createAccordianForSection(
        `What is MurAll? - CHAAAAAANGE`,
        `MurAll is an on-chain digital collaborative mural/canvas/wall that anyone anywhere can draw on, without restrictions on what you can draw. The entire ethos of the MurAll project is that it fits with the blockchain narrative of a neutral place that doesn’t filter, censor or stop any transaction. A place for true freedom of speech through art. 
        The only thing required to draw on MurAll is the PAINT token - a fixed supply ERC-20 token analogous with real life paint; you can't take paint off a wall and reuse it once it's on there, and similarly any PAINT used in drawing is burned as part of the transaction preventing it from being reused. Your contribution to MurAll will be on the blockchain in its history for as long as the blockchain lives (i.e. you will always be able to see your contribution there in the history, even if someone draws over your pixels). When you draw, you also get a MURALL Non-Fungible Token (NFT) of your contribution, a token containing your drawing, which you own and are free to do with as you please - hold, display, trade, collect, the choice is yours. 
        There is only enough supply of PAINT to allow MurAll to be drawn over in it's entirety a maximum of 21 thousand times, meaning MurAll has a finite lifespan; once the PAINT runs out you won’t be able to draw on MurAll again.`,
        'id1'
      )}
      {createAccordianForSection(
        `What are the dimensions of MurAll?`,
        `2048 pixels by 1024 pixels (2097152 pixels in total).`,
        'id2'
      )}
      {createAccordianForSection(
        `How many times in total can the whole MurAll be drawn over?`,
        `No more than 21 thousand times in its entirety.`,
        'id3'
      )}
      {createAccordianForSection(
        `Where is the data hosted?`,
        `All pixel data is stored on the Ethereum blockchain in the event log storage. Most current solutions use things like IPFS however they are not anywhere as decentralised as the blockchain itself, and also separate the ownership of the data and the data itself; we really wanted a solution that was without the compromise of this separation, so we have done our best to create a solution that has all data on chain, the state of MurAll itself is on the Etheruem blockchain, and the NFT’s have their drawing data inside them, all on chain too.`,
        'id4'
      )}
      {createAccordianForSection(
        `Is there any restriction on what you can draw?`,
        `No; any restriction would go against the entire ethos of the MurAll project. MurAll itself is a protocol that anyone can interact with, so people are free to create their own clients with filters and restrictions, but the protocol itself does not have any.`,
        'id5'
      )}
      {createAccordianForSection(
        `What colour space does MurAll use?`,
        `MurAll uses RGB565, a 16 bit colour space which gives the best look while allowing for more pixels per transaction, as well as taking up less room on the blockchain.`,
        'id6'
      )}
      {createAccordianForSection(
        `How many colours can each drawing have?`,
        `In order to fit as much information as possible into each transaction without sacrificing colour too much, each drawn image can have up to 256 distinct colours from the RGB565 spectrum. There is no set 256 colour spectrum, each artist can choose any 256 colours to use for each drawing`,
        'id7'
      )}
      {createAccordianForSection(
        `Do you own the pixels you draw over?`,
        `No, the pixels on MurAll have no ownership; anyone can draw anything, anywhere, on MurAll, the only restriction is that it requires PAINT to draw. However, you do own the MURALL NFT produced by drawing on MurAll.`,
        'id8'
      )}
      {createAccordianForSection(
        `Can you draw over someone else’s drawing?`,
        `Yes. Unlike some other collaborative drawing projects, you do not own the pixels you draw over on MurAll, however no one can draw on your MURALL NFT which contains your original drawing on MurAll.`,
        'id9'
      )}
      {createAccordianForSection(
        `Can you draw the same thing that someone else has drawn?`,
        `Yes. As mentioned, the entire point of MurAll is that there is no restriction, you can draw whatever you like, which includes the same thing that others have drawn. However, the tokenomics behind the PAINT token (mostly it’s finite supply and burn upon use) and the fact you can only cover MurAll in its entirety a maximum of 21 thousand times means people will think twice about redrawing other people’s work; wouldn’t you rather draw something unique considering you may not get the opportunity to draw on MurAll again?`,
        'id10'
      )}
      {createAccordianForSection(
        `What do you get for drawing on MurAll?`,
        `When you draw onto MurAll, not only is your drawing written into the blockchain history forever, you also get a MURALL NFT with a hash of the pixel data, which is entirely yours to do with as you please; hodl, display, sell, trade, load into other platforms, the choice is yours. You can fill the MURALL NFT with the same drawing data as your original drawing on MurAll so your NFT contains the same data as your contribution; all pixel data on the ethereum blockchain.`,
        'id11'
      )}
      {createAccordianForSection(
        `Is there a limit on how big a drawing can be on MurAll?`,
        `You are only limited by the transaction gas limits, how much PAINT you have, and the dimensions of MurAll (2048 x 1024 pixels). We did not want to enforce arbitrary size limits past the dimensions of MurAll as we want users to be able to push it to the limits, plus we want MurAll to be fluid enough to handle the changes to the Ethereum blockchain over time. Even if the current Ethereum gas limits prevent a drawing in one transaction, you will still be able to draw in multiple transactions, and the MURALL NFTs can be displayed together even though they are pieces. `,
        'id12'
      )}
      {createAccordianForSectionCustom(
        `What are the terms and conditions of using this website to interact with MurAll?`,
        () => (
          <Typography
            className={clsx(classes.typographyLight, classes.accordianDetails)}
            color='textSecondary'
            align='justify'
          >
            You can find the terms and conditions{' '}
            <Link href='/terms' color='secondary'>
              here
            </Link>
            .
          </Typography>
        ),
        'id13'
      )}
      {createAccordianForSectionCustom(
        `What are the contract addresses for the smart contracts used by MurAll?`,
        () => (
          <div>
            <Typography
              className={clsx(classes.typographyLight, classes.accordianDetails)}
              color='textSecondary'
              align='justify'
            >
              The addresses are as follows:
              <br></br>
              <br></br>
              MurAll:{' '}
              <Link
                href='https://etherscan.io/address/0x6442bdfd16352726aa25ad6b3cbab3865c05ed15'
                color='secondary'
              >
                0x6442bdfd16352726aa25ad6b3cbab3865c05ed15
              </Link>
              <br></br>
              <br></br>
              MurAll NFT (ERC-721):{' '}
              <Link
                href='https://etherscan.io/address/0x5bb3d01f32687f92f8636799687e55cba0627704'
                color='secondary'
              >
                0x5bb3d01f32687f92f8636799687e55cba0627704
              </Link>
              <br></br>
              <br></br>
              Paint Token (ERC-20):{' '}
              <Link
                href='https://etherscan.io/token/0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042'
                color='secondary'
              >
                0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042
              </Link>
              <br></br>
              <br></br>
              Paint airdrop claim contract:{' '}
              <Link
                href='https://etherscan.io/address/0xf45ba58c415ed6c77cfc157f42f300f9fd980985'
                color='secondary'
              >
                0xf45ba58c415ed6c77cfc157f42f300f9fd980985
              </Link>
              <br></br>
              <br></br>
              MurAll data validator:{' '}
              <Link
                href='https://etherscan.io/address/0x94A20eefdb61eF160f052D8ec9F8Fb328907ba29'
                color='secondary'
              >
                0x94A20eefdb61eF160f052D8ec9F8Fb328907ba29
              </Link>
              <br></br>
              <br></br>
              MurAll NFT data storage (contract storage for filled tokens):{' '}
              <Link
                href='https://etherscan.io/address/0xe2514e229a657F37DA9384679452C34466FA9539'
                color='secondary'
              >
                0xe2514e229a657F37DA9384679452C34466FA9539
              </Link>
              <br></br>
              <br></br>
              MurAll NFT Metadata decoder:{' '}
              <Link
                href='https://etherscan.io/address/0x0041C8050A2c895d532103f1F7A234794deF1D8E'
                color='secondary'
              >
                0x0041C8050A2c895d532103f1F7A234794deF1D8E
              </Link>
              <br></br>
              <br></br>
            </Typography>
            <Typography
              className={clsx(classes.typographyLight, classes.accordianDetails)}
              color='textSecondary'
              align='justify'
            >
              Polygon/Matic (Layer 2) addresses:
              <br></br>
              <br></br>
              Paint Token (ERC-20):{' '}
              <Link
                href='https://explorer-mainnet.maticvigil.com/tokens/0x7c28F627eA3aEc8B882b51eb1935f66e5b875714'
                color='secondary'
              >
                0x7c28F627eA3aEc8B882b51eb1935f66e5b875714
              </Link>
            </Typography>
          </div>
        ),
        'id27'
      )}
      <br></br>
      {/* PAINT Token section ****************************/}
      {createTitleForSection('PAINT Token')}
      <br></br>
      {createAccordianForSection(
        `What is PAINT?`,
        `PAINT is a fixed-supply ERC-20 token, required to draw on MurAll. Just like real life paint, you can only use as much as you have to draw on the canvas, and just like real life paint, when it’s used it can’t be used again (the PAINT token is burned as part of the draw transaction, reducing the total supply of PAINT).`,
        'id14'
      )}
      {createAccordianForSection(
        `What is the total supply of PAINT?`,
        `There is a fixed supply of 22,020,096,000 PAINT tokens.
At 0.5 PAINT per pixel, it costs 1,048,576 PAINT to draw over all 2,097,152 pixels once.
1,048,576 PAINT x 21,000 draws = 22,020,096,000 PAINT.`,
        'id15'
      )}
      {createAccordianForSection(
        `How much PAINT does it cost per pixel to draw on MurAll?`,
        `A fixed cost of 0.5 PAINT (excluding the network gas fee for the transaction).`,
        'id16'
      )}
      {createAccordianForSection(
        `Can PAINT be reused?`,
        `No. Just like in real life you can’t take your paint off of the wall and reuse it to paint again, once you use PAINT to draw on MurAll, it can’t be taken away and reused. 
Under the hood in the MurAll smart contract, the PAINT used in the drawing transaction is burned, and so the overall supply of PAINT is reduced as a result.
`,
        'id17'
      )}
      {createAccordianForSection(
        `Will there ever be more PAINT produced?`,
        `No. There is a fixed supply.`,
        'id18'
      )}
      {createAccordianForSection(
        `Why is there a fixed supply of PAINT? Why is the PAINT burned per transaction?`,
        `This is a mechanism to improve the overall quality of the art drawn on the canvas over time.
Burning the tokens in each transaction reduces the total supply, which increases the rarity (and in turn, the value) of the remaining tokens. This means at the beginning of the life of MurAll when PAINT is plentiful in supply, drawing to the canvas will be incredibly cheap, which no doubt will result in lower quality artwork, where anyone and everyone will draw anything and everything on the canvas because it is so cheap. However, over time as the tokens supply reduces (and thus the rarity increases) this will result in the remaining tokens increasing in value, causing token holders to be more reluctant to use their tokens to draw meaningless things, leaving only those who truly want to make a statement or who truly want to make good quality art with the desire to use the tokens to draw on the canvas.
`,
        'id19'
      )}
      {createAccordianForSection(
        `Is PAINT mineable?`,
        `No, it is not possible to mine PAINT.`,
        'id'
      )}
      {createAccordianForSection(
        `How can I get some PAINT?`,
        `Verified artists on KnownOrigin/SuperRare/Rarible/AsyncArt (snapshot taken 15/11/2020), and ERC721 NFT hodlers  (snapshot for addresses taken 18/12/2020) are eligible to claim PAINT; 
                you can check your eligibility to claim on the address checker by clicking through from the PAINT summary. PAINT can also be purchased on the open market.`,
        'id20'
      )}

      {createAccordianForSectionCustom(
        `Who qualifies for the PAINT airdrop?`,
        () => (
          <Typography
            className={clsx(classes.typographyLight, classes.accordianDetails)}
            color='textSecondary'
            align='justify'
          >
            To ensure that MurAll hit the ground running, we targeted wallets of
            users we believed to be crypto artists or active in the NFT
            community. Therefore we searched for verified artists and wallets
            with both incoming and outgoing ERC-721 token transactions. We
            believed that this would ensure we cater to an active art community
            and people who are familiar/competent with ERC-721 tokens and thus
            will appreciate our project more!
            <br></br>
            <br></br>
            You are able to claim PAINT if:
            <br></br>
            <ol>
              <li>
                You are a verified blockchain artist (addresses collated from
                Known Origin/Rarible/SuperRare/Async Art) - Snapshot taken
                15/11/2020
              </li>
              <li>
                You are a trading NFT holder. To be an active trading NFT
                holder, there are 3 criteria:
                <ul>
                  <li>Your NFTs are fully ERC-721 compatible</li>
                  <li>
                    You have more incoming than outgoing transactions with said
                    NFTs
                  </li>
                  <li>
                    These criteria were fulfilled at the time of taking the
                    snapshot (18/12/2020)
                  </li>
                </ul>
              </li>
            </ol>
            Following these requirements gave us 51 754 addresses that are
            eligible for the airdrop and ensured that the claimable amount is
            meaningful and claimants could actually use it to draw on the MurAll
            canvas, which is what we are trying to achieve.
          </Typography>
        ),
        'id29'
      )}
      {createAccordianForSection(
        `Is there a deadline for claiming airdropped PAINT?`,
        `The PAINT airdrop claim contract has a timelock of 365 days from it's inception (22nd of January 2021), which allows us to reclaim any unclaimed PAINT after 365 days in order for us to redistribute in other ways, so eligible claimants have until 22nd of January 2022 to claim.`,
        'id28'
      )}
      <br></br>
      {/* MURALL NFTs section ****************************/}
      {createTitleForSection('MURALL NFTs')}
      <br></br>
      {createAccordianForSection(
        `What is a MURALL NFT?`,
        `MURALL is a “write-once” ERC721 Non-Fungible Token (NFT) that is produced as a part of drawing onto MurAll, which you own. It contains all metadata (name, series id and number), plus a hash of the pixel data hosted on-chain in event log storage. Artists can "fill" the NFT with this pixel data if they choose (meaning copy the data to contract storage where the NFT is hosted).`,
        'id21'
      )}
      {createAccordianForSection(
        `Why does MURALL only initially have the hash of the pixel data?`,
        `The hash is referencing the on-chain pixel data in the event log storage; an immutable on-chain storage which every node on the Ethereum network holds (and thus is as distributed, stable and secure as contract storage), and is retrievable by any client communicating with the nodes (such as your wallet on the browser). Most importantly is vastly cheaper to store data on than contract storage, with the caveat that event log storage cannot be read by contract storage, and cannot be modified once written. 
        By putting the pixel data onto event log storage rather than contract storage, this allows us to maximise the amount of pixels you can draw to MurAll. The artist has a choice to "fill" the NFT with the pixel data from event log storage, which would copy the data to contract storage (which we check against the hash to ensure the data is the same) meaning the data would then be hosted in both the event log storage and contract storage, or leave it "unfilled" if they are happy with the pixel data being on chain in event log storage.`,
        'id22'
      )}
      {createAccordianForSection(
        `Do you have to use PAINT to fill the minted MURALL?`,
        `No, PAINT is only used to draw on MurAll, which produces the NFT of your contribution.  You only have to pay the network gas fees for the transaction to fill the NFT data.`,
        'id23'
      )}
      {createAccordianForSection(
        `What if it takes more than one transaction to fill the data?`,
        `The NFTs are coded in a “write once” setup, meaning it will fill the NFT as much as possible with the gas you provide, and if it runs out of gas it will stop where it is and make note of where you got to, meaning subsequent transactions will fill the remaining data continuing from where it got to last.`,
        'id24'
      )}
      {createAccordianForSection(
        `Do you have to fill the minted MURALL?`,
        `No, that’s up to the token owner, but the incentive is there considering given the finite supply of PAINT means a finite amount of tokens will ever be minted by MurAll, so the NFT is instantly rare no matter its content.`,
        'id25'
      )}
      {createAccordianForSection(
        `What can I do with my MURALL?`,
        `The MurAll client will have a place where you can view the MURALLs you own, where you can use them to display as they are or combine them together as you see fit to create whole new pieces of art for your personal viewing. You can also trade your MURALL, or buy other peoples MURALL to add to your collection. You will also be able to import it into other platforms if they implement our open source mappers.`,
        'id26'
      )}
    </div>
  )
}
