import config from '../../../config.js'
import Get_is_network_supported_usecase from './get_is_network_supported_usecase.js'

class Get_is_current_network_supported_usecase {
  constructor () {
    this.getIsNetworkSupportedUsecase = new Get_is_network_supported_usecase()
  }

  async execute () {
    const currentNetworkId =
      config.forcedNetwork != null
        ? config.forcedNetwork
        : await config.web3.eth.net.getId()
    return this.getIsNetworkSupportedUsecase.execute(currentNetworkId)
  }
}
export default Get_is_current_network_supported_usecase
