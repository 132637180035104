import Blockchain_Nft_data_mapper_class from '../nft_data_mapper.js'

import config from '../../../config.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'

class Add_event_to_MurAll_state_usecase {
  constructor () {
    this.Helper = new Helper_class()
    this.NftDataMapper = new Blockchain_Nft_data_mapper_class()
    this.MurAllStateDisplayInformation = class {
      constructor (
        tokenId,
        fullBase64PngString,
        croppedBase64PngString,
        positionInfo,
        name,
        number,
        seriesId,
        hasAlpha,
        colorIndex,
        artist
      ) {
        this.tokenId = tokenId
        this.fullBase64PngString = fullBase64PngString
        this.croppedBase64PngString = croppedBase64PngString
        this.positionInformation = positionInfo
        this.name = name
        this.number = number
        this.seriesId = seriesId
        this.hasAlpha = hasAlpha
        this.colorIndex = colorIndex
        this.artist = artist
      }
    }
  }

  async execute (currentState, eventArtwork) {
    const offScreenCanvas = this.Helper.createOffscreenCanvas(
      config.WIDTH,
      config.HEIGHT
    )
    const ctx = offScreenCanvas.getContext('2d')

    const currentStateImage = await this.Helper.loadImage(
      currentState.fullBase64PngString
    )
    ctx.drawImage(currentStateImage, 0, 0)
    const img = ctx.getImageData(0, 0, config.WIDTH, config.HEIGHT)
    const canvasBuffer = await this.NftDataMapper.drawNftDataToCanvasBuffer(
      img.data.buffer,
      eventArtwork.colorIndex,
      eventArtwork.pixelData,
      eventArtwork.pixelGroups,
      eventArtwork.pixelGroupIndexes,
      eventArtwork.transparentPixelGroups,
      eventArtwork.transparentPixelGroupIndexes,
      eventArtwork.hasAlpha,
      true
    )

    const arr = new Uint8ClampedArray(canvasBuffer)
    let processedImageData
    try {
      processedImageData = new ImageData(arr, config.WIDTH, config.HEIGHT)
    } catch (e) {
      processedImageData = ctx.createImageData(config.WIDTH, config.HEIGHT)
      processedImageData.data.set(arr)
    }

    ctx.putImageData(processedImageData, 0, 0)
    const fullBase64PngString = offScreenCanvas.toDataURL()
    const positionInfo = this.Helper.cropImageFromCanvasContext(ctx, true)

    return new this.MurAllStateDisplayInformation(
      eventArtwork.tokenId,
      fullBase64PngString,
      eventArtwork.croppedBase64PngString,
      positionInfo,
      eventArtwork.name,
      eventArtwork.number,
      eventArtwork.seriesId,
      eventArtwork.hasAlpha,
      eventArtwork.colorIndex,
      eventArtwork.artist
    )
  }
}
export default Add_event_to_MurAll_state_usecase
