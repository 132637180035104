import config from '../../../config.js'
import Helper_class from '../../../libs/helpers.js'
import _ from 'underscore'

class Get_is_network_supported_usecase {
  constructor () {
    this.Helper = new Helper_class()
  }

  execute (networkId) {
    return _.contains(config.supportedNetworkIds, networkId)
      ? { isSupported: true, error: null }
      : {
          isSupported: false,
          error: 'Selected network unsupported - please select Ethereum Mainnet'
        }
  }
}
export default Get_is_network_supported_usecase
