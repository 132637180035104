import config from '../../../config.js'

const INITIAL_SUPPLY = '18060697031000000000000000000'

class GetPaintTokenClaimContractInitialSupplyUsecase {
  execute () {
    return config.web3.utils.toBN(INITIAL_SUPPLY)
  }
}

export default GetPaintTokenClaimContractInitialSupplyUsecase
