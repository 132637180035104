import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar, SnackbarContent } from 'notistack'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  card: {
    width: '100%',
    transition: 'background-color 0.5s ease'
  },
  success: {
    backgroundColor: '#43A047'
  },
  error: {
    backgroundColor: '#D32F2F'
  },
  info: {
    backgroundColor: '#2196F3'
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  actionRoot: {
    padding: '8px 8px 8px 16px'
  },
  icons: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    justifyContent: 'flex-end'
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  message: {
    padding: '8px 8px 8px 16px',
    alignSelf: 'flex-start',
    flex: 1
  },
  closeButton: {
    padding: '8px 8px'
  }
}))

const IndeterminateLoadingSnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles()

  const { closeSnackbar } = useSnackbar()

  const handleDismiss = () => {
    closeSnackbar(props.id)
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card
        className={clsx(
          classes.card,
          {
            [classes.error]: props.error
          },
          {
            [classes.success]: props.success
          },
          {
            [classes.info]: props.info
          }
        )}
      >
        <CardActions classes={{ root: classes.actionRoot }}>
          <div className={classes.wrapper}>
            <CircularProgress size={24} style={{ color: 'white' }} />

            <Typography variant='subtitle2' className={classes.message}>
              {props.message}
            </Typography>
            <div className={classes.icons} style={{}}>
              {props.showClose && (
                <IconButton
                  className={classes.closeButton}
                  onClick={handleDismiss}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  )
})

export default IndeterminateLoadingSnackMessage
