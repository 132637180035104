import _ from 'underscore'
import TokenDistributionDataSource_class from '../datasource/token_distribution_data_source.js'
import GetTokenClaimForAddressUsecase from './get_token_claim_for_address_usecase.js'

class ClaimPaintForAddressUsecase {
  constructor () {
    this.TokenDistributionDataSource = new TokenDistributionDataSource_class()
    this.getTokenClaimForAddressUsecase = new GetTokenClaimForAddressUsecase()
    this.claimDetails = class {
      constructor (index, amount, proof) {
        this.index = index
        this.amount = amount
        this.proof = proof
      }
    }
  }

  async execute (address) {
    var _this = this
    return new Promise(async function (resolve, reject) {
      const claimDetails = await this.getTokenClaimForAddressUsecase.execute(
        address
      )

      _this.TokenDistributionDataSource.claimAmountAtIndexForAddressWithProof(
        claimDetails.index,
        address,
        claimDetails.amount,
        claimDetails.proof
      )
        .then(() => {
          resolve()
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  }
}
export default ClaimPaintForAddressUsecase
