'use strict'
import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import {
  tokenRangeStartValue as sanitiseRangeStartValue,
  tokenRangeEndValue as sanitiseRangeEndValue,
  singleTokenValue as sanitiseSingleTokenValue
} from '../../libs/sanitisers'
import Get_is_current_network_supported_usecase from '../../modules/blockchain/usecase/get_is_current_network_supported_usecase'
import Get_MurAll_state_at_token_id_usecase from '../../modules/blockchain/usecase/get_murall_state_at_token_id_usecase'
import Get_MurAll_state_changes_for_token_ids_usecase from '../../modules/blockchain/usecase/get_murall_state_changes_for_token_ids_usecase'
import Get_total_MurAll_state_count_usecase from '../../modules/blockchain/usecase/get_total_murall_state_count_usecase'
import GetHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/get_has_agreed_to_legal_disclaimer_usecase'
import SetUserHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/set_user_agreed_to_legal_disclaimer_usecase'
import LoadingSpinner from '../../uicomponents/loading_spinner'
import LoadingDialog from '../../uicomponents/loading_dialog'
import NftInformationDialog from '../../uicomponents/nft_information_dialog'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view'
import HistoryRangePicker from './range-picker'
import HistoryNavigationControlsDesktop from './history_navigation_controls_desktop'
import HistoryNavigationControlsMobile from './history_navigation_controls_mobile'
import HistoryViewerCanvas from './history_viewer_canvas'
import useSmallScreenDetection from '../../uicomponents/useSmallScreenDetection'
import Helper_class from '../../libs/helpers'
import LegalDisclaimerDialog from '../legal/legal_disclaimer_dialog'

const DEFAULT_FADE_DURATION_MILLIS = 350
const DEFAULT_FADE_DURATION_PLAYING_MILLIS = 1000
const DEFAULT_DURATION_BETWEEN_STATES_MILLIS = 500
const ANIMATION_EASE_DEAFAULT = 'power2.inOut'
const ANIMATION_EASE_PLAYING = 'power2.inOut'

const useStyles = makeStyles(theme => ({
  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}))

export default function ViewHistory (props) {
  const Helper = new Helper_class()

  const classes = useStyles()

  const [isFetching, setIsFetching] = useState(false)
  const [networkError, setNetworkError] = useState(false)

  const [isUpdatingState, setIsUpdatingState] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [totalStates, setTotalStates] = useState(0)
  const [rangeStart, setRangeStart] = useState(0)
  const [rangeEnd, setRangeEnd] = useState(0)
  const [fadeDuration, setFadeDuration] = useState(DEFAULT_FADE_DURATION_MILLIS)
  const [fadeDurationPlaying, setFadeDurationPlaying] = useState(
    DEFAULT_FADE_DURATION_PLAYING_MILLIS
  )
  const [durationBetweenStates, setDurationBetweenStates] = useState(
    DEFAULT_DURATION_BETWEEN_STATES_MILLIS
  )
  const [currentSelectedState, setCurrentSelectedState] = useState(0)
  const [historyRangeInputExpanded, setHistoryRangeInputExpanded] = useState(
    _.isUndefined(Helper.get_url_parameters(props.location.search).atTokenId)
  )
  const [navigationControlsExpanded, setNavigationControlsExpanded] = useState(
    false
  )
  const [nftInfoOpen, setNftInfoOpen] = useState(false)
  const [shouldLocate, setShouldLocate] = useState(false)
  const [singleTokenMode, setSingleTokenMode] = useState(false)
  const [timer, setTimer] = useState(null)
  const [states, setStates] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const smallScreen = useSmallScreenDetection()

  const getIsCurrentNetworkSupportedUseCase = new Get_is_current_network_supported_usecase()
  const getMurAllStateChangesForTokenIdsUseCase = new Get_MurAll_state_changes_for_token_ids_usecase()
  const getMurAllStateAtTokenIdUseCase = new Get_MurAll_state_at_token_id_usecase()
  const getTotalMurAllStateCountUseCase = new Get_total_MurAll_state_count_usecase()
  const getHasAgreedToLegalDisclaimerUsecase = new GetHasAgreedToLegalDisclaimerUsecase()
  const setUserHasAgreedToLegalDisclaimerUsecase = new SetUserHasAgreedToLegalDisclaimerUsecase()

  const [agreedToDisclaimer, setAgreedToDisclaimer] = useState(
    getHasAgreedToLegalDisclaimerUsecase.execute()
  )

  useEffect(() => {
    async function onParamsUpdated (queryParams) {
      updateFromParams(queryParams)
    }
    const queryParams = Helper.get_url_parameters(props.location.search)

    if (!_.isUndefined(queryParams.atTokenId) && agreedToDisclaimer) {
      onParamsUpdated(queryParams)
    }
  }, [props.location.search]) // Empty array ensures that effect is only run on mount

  const updateFromParams = async queryParams => {
    setHistoryRangeInputExpanded(false)
    await fetchTotalStateChanges()
    const statesLength = await getCurrentHookValue(setTotalStates)

    let tokenId

    if (_.isUndefined(queryParams.toTokenId)) {
      tokenId = sanitiseSingleTokenValue(
        parseInt(queryParams.atTokenId),
        statesLength - 1
      )
      showStateAtId(tokenId)
    } else {
      tokenId = sanitiseRangeStartValue(
        parseInt(queryParams.atTokenId),
        statesLength < 1 ? 0 : statesLength - 1
      )

      setNewTokenRange(
        tokenId,
        sanitiseRangeEndValue(
          parseInt(queryParams.toTokenId),
          statesLength < 1 ? 0 : statesLength - 1
        )
      )
      const rangeStart = await getCurrentHookValue(setRangeStart)
      const rangeEnd = await getCurrentHookValue(setRangeEnd)
      fetchStateChangesForRange(rangeStart, rangeEnd, statesLength)
    }
  }
  useEffect(() => {
    function onNetworkChanged (e) {
      setNetworkError(!e.detail.isSupported)
      if (e.detail.isSupported && agreedToDisclaimer) {
        fetchTotalStateChanges()
      }
    }
    if (agreedToDisclaimer) {
      fetchTotalStateChanges()
    }
    // Add event listener for account changed event
    document
      .querySelector('#top_buttons_container')
      .addEventListener('networkChanged', onNetworkChanged.bind(this), false)

    // Remove event listener on cleanup
    return () =>
      document
        .querySelector('#top_buttons_container')
        .removeEventListener(
          'networkChanged',
          onNetworkChanged.bind(this),
          false
        )
  }, []) // Empty array ensures that effect is only run on mount

  const handleSliderChange = (event, newValue) => {
    // for some reason the slider fires onChange when you click and hold the slider but move slightly without changing the slider value
    // (i.e. onChange is called with the same unchanged value) so we should see if the value is different before updating the state
    if (currentSelectedState != newValue) {
      setCurrentSelectedState(newValue)
    }
  }

  const setNewTokenRange = (newStartValue, newEndValue) => {
    if (newEndValue <= newStartValue) {
      newEndValue = newStartValue + 1
    }

    if (newStartValue >= newEndValue) {
      newStartValue = newEndValue - 1
    }
    setRangeStart(newStartValue)
    setRangeEnd(newEndValue)
  }

  const handleLoadClicked = singleTokenMode => {
    setHistoryRangeInputExpanded(false)

    const data = singleTokenMode
      ? {
          atTokenId: rangeStart
        }
      : {
          atTokenId: rangeStart,
          toTokenId: rangeEnd
        }

    Helper.overrideUrlQueries(props, data)
  }

  const handleFastRewindClicked = event => {
    goToPreviousState()
  }

  const handleFastForwardClicked = event => {
    goToNextState()
  }

  const handleSkipPreviousClicked = event => {
    setCurrentSelectedState(rangeStart)
  }

  const handleSkipNextClicked = event => {
    setCurrentSelectedState(rangeEnd)
  }

  const togglePlayPause = event => {
    const playbackState = !isPlaying
    setIsPlaying(playbackState)

    if (playbackState) {
      startPlayback()
    } else {
      pausePlayback()
    }
  }

  const handleFadeDurationChange = event => {
    const newValue = event.target.value === '' ? 0 : Number(event.target.value)
    setFadeDurationPlaying(newValue)
  }

  const handleDurationBetweenStatesChange = event => {
    const newValue = event.target.value === '' ? 0 : Number(event.target.value)
    setDurationBetweenStates(newValue)
  }

  const handleExpandRangePickerClicked = () => {
    setHistoryRangeInputExpanded(!historyRangeInputExpanded)
  }

  const handleExpandNavigationControlsClicked = () => {
    setNavigationControlsExpanded(!navigationControlsExpanded)
  }

  const handleInfoIconClicked = () => {
    setNftInfoOpen(true)
  }
  const handleLocateIconClicked = () => {
    setShouldLocate(true)
  }

  const constructHistoryNavigationControls = () => {
    const currentSelection = states[currentSelectedState.toString()]
    return smallScreen ? (
      <HistoryNavigationControlsMobile
        open={navigationControlsExpanded}
        currentSelectedState={currentSelectedState}
        onSliderChange={handleSliderChange.bind(this)}
        onSkipPreviousClicked={handleSkipPreviousClicked.bind(this)}
        onSkipNextClicked={handleSkipNextClicked.bind(this)}
        onFastRewindClicked={handleFastRewindClicked.bind(this)}
        onFastForwardClicked={handleFastForwardClicked.bind(this)}
        onPlayPauseClicked={togglePlayPause.bind(this)}
        isPlaying={isPlaying}
        rangeStart={rangeStart}
        rangeEnd={rangeEnd}
        onFadeDurationChanged={handleFadeDurationChange.bind(this)}
        fadeDurationPlaying={fadeDurationPlaying}
        durationBetweenStates={durationBetweenStates}
        onDurationBetweenStatesChanged={handleDurationBetweenStatesChange.bind(
          this
        )}
        currentSelection={currentSelection}
        onInfoButtonClicked={handleInfoIconClicked.bind(this)}
        onLocateButtonClicked={handleLocateIconClicked.bind(this)}
        onExpandNavigationControlsIconClicked={handleExpandNavigationControlsClicked.bind(
          this
        )}
        singleTokenMode={singleTokenMode}
      />
    ) : (
      <HistoryNavigationControlsDesktop
        open={navigationControlsExpanded}
        currentSelectedState={currentSelectedState}
        onSliderChange={handleSliderChange.bind(this)}
        onSkipPreviousClicked={handleSkipPreviousClicked.bind(this)}
        onSkipNextClicked={handleSkipNextClicked.bind(this)}
        onFastRewindClicked={handleFastRewindClicked.bind(this)}
        onFastForwardClicked={handleFastForwardClicked.bind(this)}
        onPlayPauseClicked={togglePlayPause.bind(this)}
        isPlaying={isPlaying}
        rangeStart={rangeStart}
        rangeEnd={rangeEnd}
        onFadeDurationChanged={handleFadeDurationChange.bind(this)}
        fadeDurationPlaying={fadeDurationPlaying}
        durationBetweenStates={durationBetweenStates}
        onDurationBetweenStatesChanged={handleDurationBetweenStatesChange.bind(
          this
        )}
        currentSelection={currentSelection}
        onInfoButtonClicked={handleInfoIconClicked.bind(this)}
        onLocateButtonClicked={handleLocateIconClicked.bind(this)}
        onExpandNavigationControlsIconClicked={handleExpandNavigationControlsClicked.bind(
          this
        )}
        singleTokenMode={singleTokenMode}
      />
    )
  }

  const goToNextState = () => {
    var newState = currentSelectedState + 1

    if (newState > rangeEnd) {
      newState = rangeEnd
    }

    setCurrentSelectedState(newState)
  }

  const goToPreviousState = () => {
    var newState = currentSelectedState - 1
    if (newState < rangeStart) {
      newState = rangeStart
    }
    setCurrentSelectedState(newState)
  }
  const startPlayback = () => {
    goToNextState()

    const newTimer = setInterval(advancePlayback, durationBetweenStates)
    setTimer(newTimer)
  }

  const advancePlayback = async () => {
    const selectedState = await getCurrentHookValue(setCurrentSelectedState)
    var newState = selectedState + 1

    if (newState > rangeEnd) {
      newState = rangeEnd
    }

    setCurrentSelectedState(newState)

    if (newState == rangeEnd) {
      const currentTimer = await getCurrentHookValue(setTimer)
      clearInterval(currentTimer)
      setIsPlaying(false)
    }
  }

  /* Workaround for functional component not being able to get current hook value in interval */
  async function getCurrentHookValue (setHookFunction) {
    return new Promise(resolve => {
      setHookFunction(prev => {
        resolve(prev)
        return prev
      })
    })
  }

  const pausePlayback = () => {
    clearInterval(timer)
    setIsPlaying(false)
  }

  const fetchTotalStateChanges = async () => {
    const supportedResult = await getIsCurrentNetworkSupportedUseCase.execute()
    if (!supportedResult.isSupported) {
      setIsFetching(false)
      setIsUpdatingState(false)
      setNetworkError(true)
      setHistoryRangeInputExpanded(false)

      return
    }

    setIsFetching(true)
    setIsUpdatingState(false)
    setTotalStates(0)
    setStates([])
    setNetworkError(false)
    setHistoryRangeInputExpanded(false)

    try {
      const totalStates = await getTotalMurAllStateCountUseCase.execute()
      setIsFetching(false)
      setTotalStates(parseInt(totalStates))
      setRangeEnd(parseInt(totalStates - 1))
      setCurrentSelectedState(Math.floor(totalStates / 2))
      setHistoryRangeInputExpanded(
        _.isUndefined(
          Helper.get_url_parameters(props.location.search).atTokenId
        )
      )
      setStates([])
    } catch (error) {
      setIsFetching(false)
      setIsUpdatingState(false)
      setNetworkError(true)
      console.error(error)
      enqueueSnackbar('Network error - please switch to Ethereum Mainnet', {
        variant: 'error'
      })
    }
  }

  const fetchStateChangesForRange = async (
    rangeStart,
    rangeEnd,
    totalStates
  ) => {
    setIsUpdatingState(true)
    setStates([])
    setNavigationControlsExpanded(false)
    const tokenIds = [...Array(parseInt(totalStates)).keys()].slice(
      rangeStart,
      rangeEnd + 1
    )
    try {
      const states = await getMurAllStateChangesForTokenIdsUseCase.execute(
        tokenIds
      )
      const startState = await getMurAllStateAtTokenIdUseCase.execute(
        rangeStart > 0 ? rangeStart - 1 : 0,
        true
      )
      states.unshift(startState)

      const displayStates = _.indexBy(states, 'tokenId')

      setIsUpdatingState(false)
      setCurrentSelectedState(rangeStart)
      setStates(displayStates)
      setSingleTokenMode(false)
      setNavigationControlsExpanded(true)
    } catch (error) {
      setIsFetching(false)
      setIsUpdatingState(false)
      setHistoryRangeInputExpanded(true)
      console.error(error)
      const errorMessage =
        totalStates == 0
          ? 'Error: no states to display'
          : 'Error: please try again later'
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    }
  }

  const showStateAtId = async tokenId => {
    setIsUpdatingState(true)
    setStates([])
    setNavigationControlsExpanded(false)
    try {
      const states = await getMurAllStateChangesForTokenIdsUseCase.execute([
        tokenId
      ])
      const startState = await getMurAllStateAtTokenIdUseCase.execute(
        tokenId > 0 ? tokenId - 1 : 0,
        true
      )
      states.unshift(startState)

      const displayStates = _.indexBy(states, 'tokenId')

      setIsUpdatingState(false)
      setCurrentSelectedState(tokenId)
      setStates(displayStates)
      setSingleTokenMode(true)
      setNavigationControlsExpanded(true)
    } catch (error) {
      setIsFetching(false)
      setIsUpdatingState(false)
      setHistoryRangeInputExpanded(true)
      console.error(error)
      enqueueSnackbar('Error: please try again later', {
        variant: 'error'
      })
    }
  }
  const agreedToTerms = rememberChoice => {
    setUserHasAgreedToLegalDisclaimerUsecase.execute(true, rememberChoice)
    setAgreedToDisclaimer(true)

    const queryParams = Helper.get_url_parameters(props.location.search)

    if (!_.isUndefined(queryParams.atTokenId)) {
      updateFromParams(queryParams)
    } else {
      fetchTotalStateChanges()
    }
  }

  if (!agreedToDisclaimer) {
    return <LegalDisclaimerDialog onAgreeClicked={agreedToTerms} />
  } else if (isFetching) {
    return <LoadingSpinner />
  } else if (networkError) {
    return <NetworkErrorMessageView />
  } else {
    return (
      <div
        className={clsx(classes.absoluteFill)}
        style={{ padding: '24px', overflow: 'hidden' }}
      >
        <HistoryViewerCanvas
          states={states}
          currentSelectedState={currentSelectedState}
          animationEase={
            isPlaying ? ANIMATION_EASE_PLAYING : ANIMATION_EASE_DEAFAULT
          }
          animationDuration={
            isPlaying ? fadeDurationPlaying / 1000.0 : fadeDuration / 1000.0
          }
          isVisibleOnInitialRender={(tokenId, selectedState) => {
            return tokenId <= selectedState
          }}
          shouldLocate={shouldLocate}
          onLocateFinished={() => {
            setShouldLocate(false)
          }}
          style={{ width: '100%', height: '100%', overflow: 'auto' }}
        />
        <HistoryRangePicker
          open={historyRangeInputExpanded}
          totalStates={totalStates}
          rangeStart={rangeStart}
          setRangeStart={setRangeStart}
          rangeEnd={rangeEnd}
          setRangeEnd={setRangeEnd}
          onLoadHistoryClick={handleLoadClicked.bind(this)}
          onExpandToggle={handleExpandRangePickerClicked.bind(this)}
        />
        {Object.keys(states).length > 0
          ? constructHistoryNavigationControls()
          : null}
        <NftInformationDialog
          open={nftInfoOpen}
          onClose={() => {
            setNftInfoOpen(false)
          }}
          viewCropped
          hideButtons
          nftInformation={states[currentSelectedState]}
        />

        <LoadingDialog open={isUpdatingState} />
      </div>
    )
  }
}
