import Blockchain_nft_data_source_class from '../datasource/nft_data_source.js'
import Marketplace_data_source_class from '../datasource/marketplace_data_source.js'
import _ from 'underscore'
const async = require('async')

class Get_nfts_for_account_usecase {
  constructor () {
    this.NftDataSource = new Blockchain_nft_data_source_class()
    this.MarketplaceDataSource = new Marketplace_data_source_class()

    this.NftInformation = class {
      constructor (
        tokenId,
        croppedBase64PngString,
        fullBase64PngString,
        complete,
        saleInformation
      ) {
        this.tokenId = tokenId
        this.croppedBase64PngString = croppedBase64PngString
        this.fullBase64PngString = fullBase64PngString
        this.complete = complete
        this.saleInformation = saleInformation
      }
    }
  }

  async execute (sellerAddress) {
    var _this = this

    const salesItems = await this.MarketplaceDataSource.fetchAllSalesItemsForSellerAddress(
      sellerAddress
    )
    const nftsOnSale = await async.map(salesItems, async function (
      saleItem,
      callback
    ) {
      const nftInfo = await _this.NftDataSource.fetchTokenInfoForTokenId(
        saleItem.tokenId
      )

      callback(
        null,
        new _this.NftInformation(
          saleItem.tokenId,
          nftInfo.croppedBase64PngString,
          nftInfo.fullBase64PngString,
          nftInfo.complete,
          saleItem
        )
      )
    })

    return nftsOnSale
  }
}

export default Get_nfts_for_account_usecase
