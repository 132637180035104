import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import _ from 'underscore'

import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import FastForwardIcon from '@material-ui/icons/FastForward'
import PauseIcon from '@material-ui/icons/Pause'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    left: '0px',
    bottom: '0px',
    right: '0px',
    width: '100%',
    zIndex: '99'
  },
  playControlsContainer: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + 'B3',
    width: 'calc(100% - 48px)',
    marginTop: 0,
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '24px',
    zIndex: '99'
  },
  slider: {
    color: theme.palette.secondary.main
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  expandOpen: {
    transform: 'rotate(270deg)'
  },
  gridColumnContainer: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  gridRowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  backgroundImage: {
    margin: 0,
    imageRendering: 'pixelated',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundClip: 'padding-box',
    backgroundPosition: 'center center',
    backgroundBlendMode: `normal, normal`
  },
  largeIcon: {
    height: 38,
    width: 38
  },
  controlsExpandIcon: {
    padding: '24px',
    marginBottom: '8px',
    alignSelf: 'center'
  }
}))

export default function HistoryNavigationControlsDesktop (props) {
  const classes = useStyles()
  const [settingsExpanded, setSettingsExpanded] = React.useState(false)

  const handleSettingsExpandClick = event => {
    setSettingsExpanded(!settingsExpanded)
  }

  const constructPlayControls = () => {
    return (
      <div
        className={classes.gridRowContainer}
        style={{
          justifyContent: 'space-between'
        }}
      >
        <React.Fragment>
          <IconButton
            aria-label='previous'
            onClick={props.onSkipPreviousClicked}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton aria-label='previous' onClick={props.onFastRewindClicked}>
            <FastRewindIcon />
          </IconButton>
          <IconButton
            aria-label='play/pause'
            onClick={props.onPlayPauseClicked}
          >
            {props.isPlaying ? (
              <PauseIcon className={clsx(classes.largeIcon)} />
            ) : (
              <PlayArrowIcon className={clsx(classes.largeIcon)} />
            )}
          </IconButton>
          <IconButton aria-label='next' onClick={props.onFastForwardClicked}>
            <FastForwardIcon />
          </IconButton>
          <IconButton aria-label='next' onClick={props.onSkipNextClicked}>
            <SkipNextIcon />
          </IconButton>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: settingsExpanded
            })}
            onClick={handleSettingsExpandClick}
            aria-expanded={settingsExpanded}
            aria-label='settings'
          >
            <SettingsIcon />
          </IconButton>
        </React.Fragment>
      </div>
    )
  }

  const constructInfoView = () => {
    return (
      <Grid
        item
        xs={props.singleTokenMode ? 12 : 5}
        style={{
          padding: 0,
          marginTop: 0,
          marginBottom: 0,
          marginRight: props.singleTokenMode ? '8px' : '-16px'
        }}
      >
        <div
          className={classes.gridColumnContainer}
          style={{
            padding: 0,
            margin: 0,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            backgroundClip: 'padding-box',
            overflow: 'hidden',
            height: '100%'
          }}
        >
          {_.isUndefined(props.currentSelection) ? null : (
            <div
              className={clsx(
                classes.backgroundImage,
                classes.gridRowContainer
              )}
              style={{
                paddingRight: '24px',
                paddingTop: '24px',
                paddingBottom: '24px',
                margin: 0,
                height: '100%',
                backgroundImage: `radial-gradient(circle at top right,
                                                                    #26262B85,
                                                                    #26262B 75%),
                                                                    url(${props.currentSelection.croppedBase64PngString})`
              }}
            >
              <div
                className={classes.gridColumnContainer}
                style={{
                  height: '100%'
                }}
              >
                <Typography
                  component='h4'
                  variant='h4'
                  noWrap
                  className={clsx(classes.typography)}
                  color='textPrimary'
                >
                  {props.currentSelection.name}
                </Typography>

                <Typography
                  component='span'
                  variant='h6'
                  className={clsx(classes.typography)}
                  color='textPrimary'
                >
                  {props.currentSelection.number} of Series{' '}
                  {props.currentSelection.seriesId}
                </Typography>
                <br />
                <Typography
                  component='span'
                  variant='subtitle2'
                  noWrap
                  className={clsx(classes.typography)}
                  color='textSecondary'
                >
                  {props.currentSelection.artist}
                </Typography>
              </div>
              <div
                className={clsx(classes.gridRowContainer)}
                style={{
                  flex: 1,
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  right: 0,
                  marginRight: '36px'
                }}
              >
                <IconButton
                  aria-label='next'
                  onClick={props.onInfoButtonClicked}
                >
                  <InfoIcon />
                </IconButton>

                <IconButton
                  aria-label='next'
                  onClick={props.onLocateButtonClicked}
                >
                  <MyLocationIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </Grid>
    )
  }
  const constructSettings = () => {
    return (
      <div
        className={classes.gridRowContainer}
        style={{
          justifyContent: 'space-evenly',
          marginBottom: '12px'
        }}
      >
        <TextField
          style={{ flex: 1, marginRight: '24px' }}
          onChange={props.onFadeDurationChanged}
          id='standard-helperText'
          label='Transition duration'
          value={props.fadeDurationPlaying}
          helperText={'in milliseconds'}
          inputProps={{
            step: 50,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
        <TextField
          style={{ flex: 1 }}
          onChange={props.onDurationBetweenStatesChanged}
          id='standard-helperText'
          label='Delay between states'
          value={props.durationBetweenStates}
          helperText={'in milliseconds'}
          inputProps={{
            step: 50,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label='show/hide play controls'
        onClick={props.onExpandNavigationControlsIconClicked}
        className={clsx(classes.controlsExpandIcon, classes.largeIcon)}
      >
        {props.open ? (
          <ExpandMore className={clsx(classes.largeIcon)} />
        ) : (
          <ExpandLess className={clsx(classes.largeIcon)} />
        )}
      </IconButton>
      <Collapse in={props.open} mountOnEnter unmountOnExit>
        <Paper className={classes.playControlsContainer}>
          <Grid
            container
            spacing={2}
            direction='row'
            justify='space-between'
            alignItems='stretch'
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '24px',
              paddingRight: props.singleTokenMode ? 0 : '24px',
              marginTop: 0,
              marginBottom: 0
            }}
          >
            {props.singleTokenMode ? null : (
              <Grid item xs={props.singleTokenMode ? 12 : 7}>
                <div className={classes.gridColumnContainer}>
                  <Slider
                    className={classes.slider}
                    defaultValue={0}
                    value={props.currentSelectedState}
                    onChange={props.onSliderChange}
                    step={1}
                    min={props.rangeStart}
                    max={props.rangeEnd}
                  />

                  {constructPlayControls()}
                  <Collapse
                    in={settingsExpanded}
                    timeout='auto'
                    unmountOnExit
                    style={{ width: '100%' }}
                  >
                    {constructSettings()}
                  </Collapse>
                </div>
              </Grid>
            )}
            {constructInfoView()}
          </Grid>
        </Paper>
      </Collapse>
    </div>
  )
}
