'use strict'

import LiveStateViewerCanvas from './live_state_viewer_canvas.js'
import LegalDisclaimerDialog from '../legal/legal_disclaimer_dialog.js'
import LoadingSpinner from '../../uicomponents/loading_spinner.js'
import { withStyles } from '@material-ui/core/styles'
import NetworkErrorMessageView from '../../uicomponents/network_error_message_view.js'
import ErrorMessageView from '../../uicomponents/error_message_view.js'
import Get_is_current_network_supported_usecase from '../../modules/blockchain/usecase/get_is_current_network_supported_usecase.js'
import Add_event_to_MurAll_state_usecase from '../../modules/blockchain/usecase/add_event_to_murall_state_usecase.js'
import Get_MurAll_current_state_usecase from '../../modules/blockchain/usecase/get_murall_current_state_usecase.js'
import GetHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/get_has_agreed_to_legal_disclaimer_usecase.js'
import SetUserHasAgreedToLegalDisclaimerUsecase from '../../modules/blockchain/usecase/set_user_agreed_to_legal_disclaimer_usecase.js'
import Helper_class from '../../libs/helpers.js'
import _ from 'underscore'
import React, { useRef, useState, useEffect } from 'react'
import { withSnackbar } from 'notistack'

const DEFAULT_FADE_DURATION_MILLIS = 350
const ANIMATION_EASE_DEFAULT = 'power2.inOut'

const useStyles = theme => ({
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },

  absoluteFill: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },

  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100
  },
  typographyLight: {
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  body: {
    fontSize: 16
  },
  artistTypography: {
    fontSize: 10
  }
})

class LiveView extends React.Component {
  constructor (props) {
    super(props)
    this.Helper = new Helper_class()
    this.getIsCurrentNetworkSupportedUseCase = new Get_is_current_network_supported_usecase()
    this.addEventToMurAllStateUseCase = new Add_event_to_MurAll_state_usecase()
    this.getMurAllCurrentStateUseCase = new Get_MurAll_current_state_usecase()
    this.GetHasAgreedToLegalDisclaimerUsecase = new GetHasAgreedToLegalDisclaimerUsecase()
    this.SetUserHasAgreedToLegalDisclaimerUsecase = new SetUserHasAgreedToLegalDisclaimerUsecase()

    this.state = {
      isFetching: false,
      networkError: false,
      agreedToDisclaimer: this.GetHasAgreedToLegalDisclaimerUsecase.execute(),
      currentSelectedState: 0,
      baseStateId: 0,
      error: null,
      states: []
    }
  }

  componentDidMount () {
    if (this.state.agreedToDisclaimer) {
      this.checkConnectionThenFetch()
    }
    const container = document.querySelector('#top_buttons_container')
    container.addEventListener(
      'networkChanged',
      this.onNetworkChanged.bind(this),
      false
    )
    container.addEventListener('painted', this.onPaintedEvent.bind(this), false)
  }

  componentWillUnmount () {
    const container = document.querySelector('#top_buttons_container')
    container.removeEventListener(
      'networkChanged',
      this.onNetworkChanged,
      false
    )
    container.removeEventListener('painted', this.onPaintedEvent, false)
  }

  onNetworkChanged (e) {
    this.setState({ networkError: !e.detail.isSupported })
    if (e.detail.isSupported && this.state.agreedToDisclaimer) {
      this.checkConnectionThenFetch()
    }
  }
  onPaintedEvent (e) {
    if (!this.state.networkError) {
      const artwork = e.detail.artwork

      var baseState = this.state.states[this.state.baseStateId]
      var newStateMap = _.indexBy([baseState, artwork], 'tokenId')

      this.setState({
        states: newStateMap,
        currentSelectedState: artwork.tokenId,
        isFetching: false
      })
    }
  }

  async flattenStates () {
    const baseState = this.state.states[this.state.baseStateId]
    const newState = this.state.states[this.state.currentSelectedState]

    const newBaseState = await this.addEventToMurAllStateUseCase.execute(
      baseState,
      newState
    )

    var newStateMap = _.indexBy([newBaseState], 'tokenId')

    this.setState({
      states: newStateMap,
      currentSelectedState: newBaseState.tokenId,
      baseStateId: newBaseState.tokenId,
      isFetching: false,
      networkError: false
    })
  }

  async checkConnectionThenFetch () {
    const supportedResult = await this.getIsCurrentNetworkSupportedUseCase.execute()
    if (!supportedResult.isSupported) {
      this.setState({ isFetching: false, networkError: true })
      return
    }
    this.setState({ isFetching: true, networkError: false })

    // get current state
    try {
      const currentState = await this.getMurAllCurrentStateUseCase.execute(true)

      var currentStateMap = _.indexBy([currentState], 'tokenId')

      this.setState({
        states: currentStateMap,
        currentSelectedState: currentState.tokenId,
        baseStateId: currentState.tokenId,
        isFetching: false,
        networkError: false
      })
    } catch (error) {
      console.log(error)

      this.setState({
        states: [],
        currentSelectedState: 0,
        baseStateId: 0,
        isFetching: false,
        networkError: false,
        error: {
          title: 'Live view unavailable',
          description: 'Try again later'
        }
      })
    }
  }

  agreedToTerms (rememberChoice) {
    this.SetUserHasAgreedToLegalDisclaimerUsecase.execute(true, rememberChoice)
    this.setState({ agreedToDisclaimer: true })
    this.checkConnectionThenFetch()
  }

  render () {
    const { classes } = this.props
    return !this.state.agreedToDisclaimer ? (
      <LegalDisclaimerDialog onAgreeClicked={this.agreedToTerms.bind(this)} />
    ) : this.state.isFetching ? (
      <LoadingSpinner />
    ) : this.state.networkError ? (
      <NetworkErrorMessageView />
    ) : !_.isNull(this.state.error) ? (
      <ErrorMessageView
        title={this.state.error.title}
        description={this.state.error.description}
      />
    ) : (
      <LiveStateViewerCanvas
        style={{ width: '100%', height: '100%', overflow: 'auto' }}
        states={this.state.states}
        baseStateId={this.state.baseStateId}
        currentSelectedState={this.state.currentSelectedState}
        animationEase={ANIMATION_EASE_DEFAULT}
        animationDuration={DEFAULT_FADE_DURATION_MILLIS / 1000.0}
        isVisibleOnInitialRender={(tokenId, selectedState) => {
          return tokenId == parseInt(this.state.baseStateId)
        }}
        onAnimationEnd={this.flattenStates.bind(this)}
      />
    )
  }
}

export default withSnackbar(
  withStyles(useStyles, { withTheme: true })(LiveView)
)
