import { makeStyles } from '@material-ui/core/styles'

const styles = {
  desktop: theme => ({
    root: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      left: '0px',
      top: '0px',
      right: '0px',
      zIndex: '99'
    },
    gridColumnContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      width: '100%',
      top: 0,
      left: 0
    },
    rangePickerContainer: {
      marginTop: '24px',
      marginLeft: '24px',
      marginRight: '24px',
      marginBottom: 0,
      padding: '24px',
      borderRadius: 10,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: '0px 0px 10px 4px ' + theme.palette.secondary.main + 'B3',
      width: 'calc(100% - 48px)',
      zIndex: '99'
    },
    submitBtn: {
      marginTop: '16px'
    }
  }),
  mobile: theme => ({
    root: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      left: '0px',
      top: '0px',
      right: '0px',
      zIndex: '99'
    },
    gridColumnContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      width: '100%'
    },
    rangePickerContainer: {
      padding: '24px',
      borderRadius: 0,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: '0px 0px 10px 4px ' + theme.palette.secondary.main + 'B3',
      width: '100%',
      zIndex: '99'
    },
    submitBtn: {
      marginTop: '16px'
    }
  })
}

export default isMobile => {
  return makeStyles(isMobile ? styles.mobile : styles.desktop)
}
