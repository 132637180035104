import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const drawerWidth = 320

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  appBar: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowX: 'hidden'
  },
  drawerPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: drawerWidth,
    top: 'auto',
    position: 'absolute'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
    width: '100%'
  },
  contentAppBarShift: {
    width: `calc(100% + ${drawerWidth}px)`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: 'auto'
  }
}))

export default function DrawerDetailsView (props) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(props.drawerOpenByDefault)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div
        className={clsx(classes.root, {
          [classes.appBarShift]: open,
          [classes.appBar]: !open
        })}
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <AppBar
          className={clsx(classes.transparent)}
          position='relative'
          style={{ width: '100%' }}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              onClick={handleDrawerClose}
              aria-label='close drawer'
              edge='start'
              className={clsx(classes.menuButton, !open && classes.hide)}
            >
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentAppBarShift]: open
          })}
        >
          {props.mainContent()}
        </main>
      </div>
      <Box
        width='100%'
        height='100%'
        id='drawer-container'
        position='relative'
        component='div'
      >
        <Drawer
          className={clsx(classes.transparent, classes.drawer)}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
          BackdropProps={{ style: { position: 'absolute' } }}
          ModalProps={{
            container: document.getElementById('drawer-container'),
            style: { position: 'absolute' }
          }}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            {props.drawerContent()}
          </div>
        </Drawer>
      </Box>
    </React.Fragment>
  )
}
